import { HttpService } from './../services/http.service';
import { Md5 } from "ts-md5";
import { Router, NavigationExtras } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../services/storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.less']
})
export class ActivityComponent implements OnInit {

  city = '所有城市';
  cityList: any = [];   //获取到的城市列表
  type = 0;             //活动类型：1=线上活动；2=线下活动

  activityList: any = [];     //获取到的活动列表

  page = 1;                   //列表显示第几页
  page_num = 12;              //每页显示的数量
  total = [];            //总共几页
  page_prev = 1;
  page_next = 5;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
  ) { }

  ngOnInit() {
    this.getCityList();
    this.getActivityList();
  }


  /** 获取城市列表 */
  getCityList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getactivitycities');
    var api = 'activity/activity/getActivityCities';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,

    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.total = [];
        if (result.data != null) {
          this.cityList = result.data;
        }
      }
    })
  }
  /** 获取活动列表 */
  getActivityList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|activitylist');
    var api = 'activity/activity/activityList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'city': this.city,
      'type': this.type,
      'page': this.page,
      'page_num': this.page_num
    };
    if (this.city == '所有城市') {
      body.city = '';
    }
   // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.total = [];
        if (result.data != null) {
          this.activityList = result.data.data;
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      }
    });
  }

  selectCity(city) {
    this.city = city;
    this.page = 1;
    this.page_prev = 1;
    this.page_next = 5;
    this.getActivityList();
  }
  selectType(type){
    this.type = type;
    this.page = 1;
    this.page_prev = 1;
    this.page_next = 5;
    this.getActivityList();
  }


  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getActivityList();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getActivityList();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    console.log(this.page);
    this.getActivityList();
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0);
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = 'true';
      } else {
        this.total[i].checked = 'false';
      }
    }
    if (this.page == 1) {
      this.page_prev = this.page;
      this.page_next = this.page + 4;
    } else if (this.page == 2) {
      this.page_prev = this.page - 1;
      this.page_next = this.page + 3;
    } else if (this.page == this.total.length - 1) {
      this.page_prev = this.page - 3;
      this.page_next = this.page + 1;
    } else if (this.page == this.total.length) {
      this.page_prev = this.page - 4;
      this.page_next = this.page;
    } else {
      this.page_prev = this.page - 2;
      this.page_next = this.page + 2;
    }
  }
   /**向前5页 */
   prev(num) {
    if (num - 5 < 1) {
      this.page = 1;
    } else {
      this.page = num - 5;
    }
    this.updatepage();
    this.getActivityList();
  }
  /**向后5页 */
  next(num) {
    if (num + 5 > this.total.length) {
      this.page = this.total.length;
    } else {
      this.page = num + 5;
    }
    this.updatepage();
    this.getActivityList();
  }
}
