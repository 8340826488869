import { HttpService } from './../../services/http.service'; 
import { Md5 } from "ts-md5";
import { Router, NavigationExtras } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-blogs',
  templateUrl: './all-blogs.component.html',
  styleUrls: ['./all-blogs.component.less']
})
export class AllBlogsComponent implements OnInit {
  userinfo: any = {};     //本地保存的用户登录信息

  followBlogList: any = [];      //我关注的专栏列表
  blogList: any = [];      //全部标签列表

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
  ) {
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userinfo = this.local.get('userinfo');
    }
  }

  ngOnInit() {
    //获取我关注的专栏列表
    if (this.userinfo && this.userinfo.id) {
      this.getFollowBlogList();
    }
    //获取所有专栏列表
    this.getAllBlogs();
  }


  /**
   * 获取我关注的标签列表
   */
  getFollowBlogList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|myfollowcolumns');
    var api = 'user/user/myFollowColumns';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.followBlogList = result.data;
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**
   * 获取所有专栏列表
   */
  getAllBlogs() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getcolumns');
    var api = 'column/columns/getColumns';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.blogList = result.data;
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

 
  /**
   * 检查是否已经登录
   */
  checkUser() {
    if (!this.userinfo.id) {
      this.router.navigate(['/login'])
      return false;
    }
    return true;
  }

}
