import { Component, OnInit } from '@angular/core';
import { Md5 } from "ts-md5";
import { HttpService } from '../services/http.service';
import { StorageService } from '../services/storage.service';
import { NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';

@Component({
  selector: 'app-org-register',
  templateUrl: './org-register.component.html',
  styleUrls: ['./org-register.component.less']
})
export class OrgRegisterComponent implements OnInit {
  username = '';
  firstname = '';
  lastname = '';
  email = '';

  constructor(
    public http: HttpService,
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
  ) { 
    window.scrollTo(0,0);
    if(local.get('userinfo')==null){
      router.navigate(['/login']);
    }
  }

  ngOnInit() {
  }

  /**
   * 提交代注册信息
   */
  doRegister(){
    if(this.username == ''){
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: "请先输入Username！",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }else if(this.firstname == ''){
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: "请先输入First name！",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }else if(this.lastname == ''){
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: "请先输入Last name！",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }else if(this.email == ''){
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: "请先输入Email！",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }
    
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcooperate');
    var api = 'user/user/addCooperate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'username': this.username,
      'firstname': this.firstname,
      'lastname': this.lastname,
      'email': this.email
    };
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.modalService.success({
          nzTitle: "提交成功",
          nzContent: "PostgreSQL.org国际社区会向该邮箱地址发送设置登录密码的邮件，请注意查收。",
          nzOkText: "确定",
          nzOnOk: () => { 
            this.username = '';
            this.firstname = '';
            this.lastname = '';
            this.email = '';
          },
        })
      }
    });
  }

}
