import { Component, OnInit } from '@angular/core';
import { NzIconService } from 'ng-zorro-antd/icon';
import { StorageService } from '../../services/storage.service';
import { HttpService } from '../../services/http.service';
import { NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { Md5 } from "ts-md5";
import { Title } from '@angular/platform-browser';
import { param } from 'jquery';

@Component({
  selector: 'app-shoplist',
  templateUrl: './shoplist.component.html',
  styleUrls: ['./shoplist.component.less']
})
export class ShoplistComponent implements OnInit {
  userinfo:any = []; // 本地存储的用户信息
  convert_type = 1; // 兑换区间

  GoodsList: any = [];  //好物列表
  cart_num = 0;  // 购物车数量

  type;
  low_dou;
  high_dou;

  page = 1;                   //列表显示第几页
  page_num = 9;              //每页显示的数量
  total: any = [];            //总共几页

  page_prev = 1;
  page_next = 5;


  constructor(
    private iconService: NzIconService,
    private local: StorageService,
    private http: HttpService,
    private modalService: NzModalService,
    private router: Router,
    private titleService: Title,
    ) {
    this.iconService.fetchFromIconfont({
      scriptUrl: '//at.alicdn.com/t/font_2272955_7w83kj7dh1r.js',
    });
    if(local.get('userinfo')){
      this.userinfo = local.get('userinfo');
    }
    this.titleService.setTitle('积分商城-PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
  }
  
  ngOnInit() {
    this.getUserInfo();
    this.getShoppingCart();
    
  }

  ngOnDestroy(): void {
    this.titleService.setTitle('PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
  }

  /**
   * 获取pgdou数量
   */
  getUserInfo() {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      this.convertType(1,'','');
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
    var api = 'user/user/getNewInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'sessionid':this.userinfo.sessionid,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.userinfo = result.data;
        this.convertType(1,'','');
      }
    })
  }

  /**
   * 加入购物车goods/cart/addCart
   */
  // addToCart(id,color,size){
  //   event.stopPropagation();
  //   if(this.local.get('userinfo') == null || this.local.get('userinfo').id < 0){
  //     this.router.navigate(['/login']);
  //     return;
  //   }
  //   let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcart');
  //   var api = 'goods/cart/addCart';
  //   var body = {
  //     'timestamp': new Date().getTime().toString().substr(0, 10),
  //     'signature': signature,
  //     'user_id': this.userinfo.id,
  //     'goods_id': id,
  //     'size': size,
  //     'colour': color,
  //     'num': 1,
  //   };
  //   this.http.doPost(api, body, (result) => {
  //   //  console.log(result);
  //     if (result.code == "200") {
  //       // this.cart_num = this.cart_num + 1;
  //       this.getShoppingCart();
  //     } else {
  //       this.modalService.error({
  //         nzTitle: "系统提示",
  //         nzContent: result.message,
  //         nzOkText: "关闭",
  //         nzOnOk: () => { },
  //       });
  //     }
  //   });
  // }

  /**
   * 好物列表区间goods/goods/goodsList
   * @param type 	1：全部；2：我能兑换；3：PG豆区间
   * @param low_dou  最小PG豆（当type=3时需要传）
   * @param high_dou 	最大PG豆（当type=3时需要传）
   */
  convertType(type,low_dou,high_dou){
    this.type = type;
    this.low_dou = low_dou;
    this.high_dou = high_dou;

    if(type==2){
      if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
        this.router.navigate(['/login']);
        return;
      } 
    }
    if(type!=3){
      this.convert_type = type;
    } else {
      if(low_dou==1){
        this.convert_type = 0;
      }else{
        this.convert_type = low_dou;
      }
    }

    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|goodslist');
    var api = 'goods/goods/goodsList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'type': type,
      'user_id': this.userinfo.id,
      'low_dou': low_dou,
      'high_dou': high_dou,
      'page': this.page,
      'page_num': this.page_num,
    };
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.total = [];
          this.GoodsList = result.data;
          for(let i =0;i<this.GoodsList.length;i++){
            if(this.GoodsList[i].pgdou > this.userinfo.pgdou){
              this.GoodsList[i].isConvert = false;
            }else{
              this.GoodsList[i].isConvert = true;
            }     
          }
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }else{
          this.total = [];
          this.GoodsList = [];
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });

  }

  /**
   *我的购物车goods/cart/cartList
   */
  getShoppingCart(){
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|cartlist');
    var api = 'goods/cart/cartList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
    };
    this.http.doPost(api, body, (result) => {
    //  console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.cart_num = result.data.length;
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }

  /**
   * 购物车
   */
  goCart(){
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      this.router.navigate(['/login']);
      return;
    }
    this.router.navigate(['/cart'],{queryParams: {userId: this.userinfo.id}})
  }

  /**
   * 立即兑换
   */
  convert(index){
    event.stopPropagation();
    if(this.local.get('userinfo') == null || this.local.get('userinfo').id < 0){
      this.router.navigate(['/login']);
      return;
    }
    // if(this.GoodsList[index].colour){
    //     this.modalService.error({
    //       nzTitle: "系统提示",
    //       nzContent: '请先选择您要的好物信息',
    //       nzOkText: "关闭",
    //       nzOnOk: () => { },
    //     });
    //     return;
    // }
    // if(this.GoodsList[index].size){
    //     this.modalService.error({
    //       nzTitle: "系统提示",
    //       nzContent: '请先选择您要的好物信息',
    //       nzOkText: "关闭",
    //       nzOnOk: () => { },
    //     });
    //     return;
    //   }
    this.GoodsList[index].type = 1;
    this.GoodsList[index].num = 1;
    this.GoodsList[index].total = this.GoodsList[index].num * this.GoodsList[index].pgdou;
    this.router.navigate(['/or-confirm'],{queryParams: this.GoodsList[index]});
  }

  /*
   * 进入详情页面
   */
  goDetail(id){
    // this.router.navigate(['/g-detail'],{queryParams: {id: id}});
  }

  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    this.convertType(this.type,this.low_dou,this.high_dou);
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    this.convertType(this.type,this.low_dou,this.high_dou);
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    this.convertType(this.type,this.low_dou,this.high_dou);
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
    if (this.page == 1) {
      this.page_prev = this.page;
      this.page_next = this.page + 4;
    } else if (this.page == 2) {
      this.page_prev = this.page - 1;
      this.page_next = this.page + 3;
    } else if (this.page == this.total.length - 1) {
      this.page_prev = this.page - 3;
      this.page_next = this.page + 1;
    } else if (this.page == this.total.length) {
      this.page_prev = this.page - 4;
      this.page_next = this.page;
    } else {
      this.page_prev = this.page - 2;
      this.page_next = this.page + 2;
    }
  }

  /**向前5页 */
  prev(num) {
    if (num - 5 < 1) {
      this.page = 1;
    } else {
      this.page = num - 5;
    }
    this.updatepage();
    this.convertType(this.type,this.low_dou,this.high_dou);
  }
  /**向后5页 */
  next(num) {
    if (num + 5 > this.total.length) {
      this.page = this.total.length;
    } else {
      this.page = num + 5;
    }
    this.updatepage();
    this.convertType(this.type,this.low_dou,this.high_dou);
  }
}
