import { Component, OnInit } from '@angular/core';
import { Md5 } from "ts-md5";
import { NzModalService } from 'ng-zorro-antd';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { StorageService } from './../../services/storage.service';
import { HttpService } from './../../services/http.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.less']
})
export class RankingComponent implements OnInit {
  userid = '';

  rankingList:any = []; //综合排行
  rankingListWeek:any = []; //本周排行
  rankingListMonth:any = []; //月排行
  rankingListQuarter:any = []; //季度排行
  rankingListYear:any = []; //年排行

  year = 0; // 年
  month = 0; // 月
  quarter = 0; // 季度

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
    private titleService: Title,
  ) { 
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userid = this.local.get('userinfo').id;
    }
    this.titleService.setTitle('排行榜-PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');

    var now = new Date();
    this.year = now.getFullYear(); //得到年份
    this.month = now.getMonth() + 1;//得到月份
    if(this.month>=1&&this.month<=3){
      this.quarter = 1;
    }else if(this.month>=4&&this.month<=6){
      this.quarter = 2;
    }else if(this.month>=7&&this.month<=9){
      this.quarter = 3;
    }else{
      this.quarter = 4;
    }

    this.getRankingList(1);
    this.getRankingList(2);
    this.getRankingList(3);
    this.getRankingList(4);
    this.getRankingList(5);
  }

  ngOnInit() {
    
  }
  ngOnDestroy(): void {
    this.titleService.setTitle('PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
  }

  /**
   * 排行榜user/rank/rankList
   */
  getRankingList(type){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|ranklist');
    var api = 'user/rank/rankList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userid,
      'type': type,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result)
      if (result.code == "200") {
        switch (type) {
          case 1:
            this.rankingList = result.data;
            break;
          case 2:
            this.rankingListWeek = result.data;
            break;
          case 3:
            this.rankingListMonth = result.data;
            break;
          case 4:
            this.rankingListQuarter = result.data;
            break;
          case 5:
            this.rankingListYear = result.data;
            break;
          default:
            break;
        }
        
      }
    })
  }

  goUserHome(id){
    this.router.navigate(['/user/home'],{queryParams: {userId: id}});
  }


}
