
import { HttpService } from 'src/app/services/http.service'; import { Md5 } from "ts-md5";
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from 'src/app/services/storage.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-mybeans',
  templateUrl: './mybeans.component.html',
  styleUrls: ['./mybeans.component.less']
})
export class MybeansComponent implements OnInit {
  userinfo: any = {};
  page = 1;
  page_num = 10; //每页显示15条数据
  total = [];    //总共几页
  page_prev = 1;
  page_next = 5;
  beansList: any = [];
  operateList: any = ['发布问题', '回答问题', '回答被赞同', '回答被取消赞同', '问题被关注', '问题被取消关注', '问题被收藏', '问题被取消收藏', '问题被分享', '发布新文章', '文章被赞', '文章被取消赞',
  '文章被收藏', '文章被取消收藏', '文章被分享', '删除问题', '删除回答', '删除文章', '点赞', '取消点赞', '完善个人资料', '更新头像', '每日签到', '兑换好物', '反馈奖励','采纳答案','悬赏问答','赞赏作者',
  '文章被赞赏','回答被采纳','兑换活动资料','兑换手册'];

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
  ) {
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userinfo = this.local.get('userinfo');
      // console.log(this.userinfo);
    }
    // route.queryParams.subscribe((data) => {
    //   // this.jwId = data.id;
    // });
  }

  ngOnInit() {
    this.getPgBeans();
  }

  /**获取PG豆记录 */
  getPgBeans() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|pgdoulist');
    var api = '/user/pgdou/pgdouList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'page': this.page,
      'page_num': this.page_num,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
       this.total = [];
       if (result.data != null) {
          this.beansList = result.data;
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      }
    });
  }

/**跳转到详情页 */
  goDetail(status,type,id){
      // let navigationExtras: NavigationExtras = {
      //   queryParams: { 'id': id, },
      // };
      switch (type) {
        case 1:
          if (status == 0) {
            this.router.navigate(['/q/'+id]);
          } else {
            this.modalService.error({
              nzTitle: "系统提示",
              nzContent: '该问题已被删除',
              nzOkText: "关闭",
              nzOnOk: () => { },
            });
          }
          break;
        case 2:
          if (status == 0) {
            this.router.navigate(['/a/'+id]);
          } else {
            this.modalService.error({
              nzTitle: "系统提示",
              nzContent: '该文章已被删除',
              nzOkText: "关闭",
              nzOnOk: () => { },
            });
          }
          break;
        case 3:
          break;
        case 4:
          if (status == 0) {
            this.router.navigate(['/shop']);
          } else {
            this.modalService.error({
              nzTitle: "系统提示",
              nzContent: '该好物已被下架',
              nzOkText: "关闭",
              nzOnOk: () => { },
            });
          }
          break;
        default:
          break;
      }
  }

   /**跳转到页面 */
   getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getPgBeans();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getPgBeans();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getPgBeans();
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0);
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = 'true';
      } else {
        this.total[i].checked = 'false';
      }
    }
    if (this.page == 1) {
      this.page_prev = this.page;
      this.page_next = this.page + 4;
    } else if (this.page == 2) {
      this.page_prev = this.page - 1;
      this.page_next = this.page + 3;
    } else if (this.page == this.total.length - 1) {
      this.page_prev = this.page - 3;
      this.page_next = this.page + 1;
    } else if (this.page == this.total.length) {
      this.page_prev = this.page - 4;
      this.page_next = this.page;
    } else {
      this.page_prev = this.page - 2;
      this.page_next = this.page + 2;
    }
  }
   /**向前5页 */
   prev(num) {
    if (num - 5 < 1) {
      this.page = 1;
    } else {
      this.page = num - 5;
    }
    this.updatepage();
    this.getPgBeans();
  }
  /**向后5页 */
  next(num) {
    if (num + 5 > this.total.length) {
      this.page = this.total.length;
    } else {
      this.page = num + 5;
    }
    this.updatepage();
    this.getPgBeans();
  }

}
