import { UserComponent } from './../user.component';
import { HttpService } from './../../services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Md5 } from 'ts-md5';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mydata',
  templateUrl: './mydata.component.html',
  styleUrls: ['./mydata.component.less']
})
export class MydataComponent implements OnInit {
  userId = 0;
  isMe = false;
  userinfo: any = {};       //本地保存的用户登录信息

  user_login = '';   //0名字
  mobile = '';       //1手机号
  oldCode = '';      //老手机验证码
  code = '';         //新手机验证码
  user_email = '';   //2邮箱
  email_user_pass = ''; //更改邮箱密码验证
  emailcode = '';    //邮箱验证码
  old_user_pass = ''; //3旧密码
  user_pass = '';    //新密码
  user_repass = '';  //确认新密码
  user_moblie = true; //手机号是否可用
  shouquan_type = 1;
  shouquan_code = '';
  // address = '';      //4所在地
  // work = '';         //5工作
  // company = '';      //6公司
  // user_url = '';     //7个人网站
  // signatureword = '';    //8个人简介

  editUser_login = false;   //0编辑名字
  editMobile = false;       //1编辑手机号
  editUser_email = false;   //2编辑邮箱
  editUser_pass = false;    //3编辑密码
  editUser_data = false;    //4取消授权
  cancel_shouquan = false;
  // editAddress = false;      //4编辑所在地
  // editWork = false;         //5编辑工作
  // editCompany = false;      //6编辑公司
  // editUser_url = false;     //7编辑个人网站
  // editSignature = false;    //8编辑个人简介

  codeLoading = false;      //验证码等待中
  loadingTime = 60;         //等待时间

  changePhoneStep = 0;    //更换手机号步骤
  changePasswdStep = 0;  // 更改密码步骤
  changeEmailStep = 0;  // 更改邮箱步骤
  changePhoneByEmailStep = 0; // 通过邮箱更改手机号步骤
  isnew = 2; //是否为新邮箱
  time ;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private userPage: UserComponent,
    private route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((data) => {
      this.userId = data.userId;
    });
    this.getUserInfo(this.userId);
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      if (this.userId == this.local.get('userinfo').id) {
        this.isMe = true;
        // this.userinfo = this.local.get('userinfo');
      } else {
        this.isMe = false;
      }
    } else {
      this.isMe = false;
    }
  }

  ngOnInit() {
    // console.log(this.userinfo);
  }

  /**
   * 通过用户id获取用户信息user/user/getNewInfo
   */
  getUserInfo(id) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
    var api = 'user/user/getNewInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': id,
      'my_user_id': this.local.get('userinfo').id,
      'sessionid': this.local.get('userinfo').sessionid,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.userinfo = result.data;
        this.user_login = this.userinfo.user_login;
      }
     // console.log(this.userinfo);
    })
  }
  /**点击编辑触发事件 */
  editItem(key) {
    if (this.userId != this.local.get('userinfo').id) {
      this.isMe = false;
      return;
    }
    this.isMe = true;
    switch (key) {
      case 0:
        this.editUser_login = true;
        this.editCancel(1);
        this.editCancel(2);
        this.editCancel(3);
        // this.editMobile = false;
        // this.editUser_email = false;
        // this.editUser_pass = false;
        break;
      case 1:
        // this.editUser_login = false;
        this.editMobile = true;
        // this.editUser_email = false;
        // this.editUser_pass = false;
        this.editCancel(0);
        this.editCancel(2);
        this.editCancel(3);
        break;
      case 2:
        // this.editUser_login = false;
        // this.editMobile = false;
        this.editUser_email = true;
        // this.editUser_pass = false;
        this.editCancel(0);
        this.editCancel(1);
        this.editCancel(3);
        break;
      case 3:
        // this.editUser_login = false;
        // this.editMobile = false;
        // this.editUser_email = false;
        this.editUser_pass = true;
        this.editCancel(0);
        this.editCancel(1);
        this.editCancel(2);
        break;
      // case 4:
      //   this.editAddress = true;
      //   break;
      // case 5:
      //   this.editWork = true;
      //   break;
      // case 6:
      //   this.editCompany = true;
      //   break;
      // case 7:
      //   this.editUser_url = true;
      //   break;
      // case 8:
      //   this.editSignature = true;
      //   break;
      default:
        break;
    }
  }

  /**点击提交修改user/User/changeInfo */
  submitEdit(key) {
    // console.log("修改个人资料");
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|changeinfo');
    var api = 'user/User/changeInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'id': this.userinfo.id,
      'sex': this.userinfo.sex,
      'birthday': this.userinfo.birthday,
      'work': this.userinfo.work,
      'user_login': this.userinfo.user_login,
      'old_user_pass': this.userinfo.old_user_pass,
      'user_pass': this.userinfo.user_pass,
      'user_repass': this.userinfo.user_repass,
      'user_email': this.userinfo.user_email,
      'user_url': this.userinfo.user_url,
      'avatar': this.userinfo.avatar,
      'company': this.userinfo.company,
      'address': this.userinfo.address,
      'skills': this.userinfo.skills,
      'signatureword': this.userinfo.signatureword,
      'sessionid': this.userinfo.sessionid
    }
    switch (key) {
      case 0:
        if (this.user_login == null || this.user_login == '') {
          this.modalService.warning({
            nzTitle: "系统提示",
            nzContent: "用户名不得为空",
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
          return;
        }
        this.editUser_login = false;
        body.user_login = this.user_login;
        break;
      case 2:
        if (this.user_email == null || this.user_email == '') {
          this.modalService.warning({
            nzTitle: "系统提示",
            nzContent: "邮箱不得为空",
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
          return;
        }
        this.editUser_email = false;
        body.user_email = this.user_email;
        break;
      case 3:
        if (this.user_pass == null || this.user_pass == '' || this.user_repass == null || this.user_repass == '') {
          this.modalService.warning({
            nzTitle: "系统提示",
            nzContent: "密码不得为空",
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
          return;
        }
        this.editUser_pass = false;
        body.old_user_pass = this.old_user_pass;
        body.user_pass = this.user_pass;
        body.user_repass = this.user_repass;
        break;

      default:
        break;
    }
    // console.log("body", body);
    // return;
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        // this.userinfo = body;
        // this.userinfo = result.data;
        this.userinfo.user_login = body.user_login;
        this.userinfo.editUser_email = body.user_email;
        this.userinfo.editUser_pass = body.user_pass;

        this.local.set('userinfo', this.userinfo);
        this.userPage.userinfo = this.userinfo;
        // this.modalService.success({
        //   nzTitle: "系统提示",
        //   nzContent: "修改用户资料" + result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
        switch (key) {
          case 0:
            this.userinfo.user_login = this.user_login;
            this.modalService.success({
              nzTitle: "系统提示",
              nzContent: "修改用户名成功，PG资源网的用户名也已同步更新!",
              nzOkText: "关闭",
              nzOnOk: () => { },
            });
            break;
          case 2:
            this.changePasswdStep = 0;
            this.userinfo.user_email = this.user_email;
            this.modalService.success({
              nzTitle: "系统提示",
              nzContent: "修改邮箱成功，PG资源网的邮箱也已同步更新!",
              nzOkText: "关闭",
              nzOnOk: () => { },
            });
            break;
          case 3:
            this.changePasswdStep = 0;
            this.modalService.success({
              nzTitle: "系统提示",
              nzContent: "修改密码成功，PG资源网的密码也已同步更新!",
              nzOkText: "关闭",
              nzOnOk: () => { },
            });
            break;
          default:
            break;
        }
        this.local.set('userinfo', this.userinfo);
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
        this.changePasswdStep = 0;
        this.changeEmailStep = 0;
      }
    })
  }

  // 更改手机号下一步
  changePhoneNext() {
    if (this.user_moblie) {
      if (this.oldCode == null || this.oldCode == '') {
        this.modalService.warning({
          nzTitle: "系统提示",
          nzContent: "请填写收到的手机验证码",
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      } else {
        let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|forgetpasscodevali');
        var api = 'user/User/forgetpasscodevali';
        var body = {
          'timestamp': new Date().getTime().toString().substr(0, 10),
          'signature': signature,
          'mobile':this.userinfo.mobile,
          'code': this.oldCode,
          'sendto': this.userinfo.mobile,
        };
        console.log("body", body);
        // return;
        this.http.doPost(api, body, (result) => {
          // console.log(result);
          if (result.code == "200") {
            this.changePhoneStep = 1;
          } else {
            this.modalService.error({
              nzTitle: "系统提示",
              nzContent: result.message,
              nzOkText: "关闭",
              nzOnOk: () => { },
            });
          }
        });
      }
    }
    clearTimeout(this.time);
    this.loadingTime = 60;
    this.codeLoading = false;
  }

  // 邮箱更改手机号下一步,验证当前邮箱
  changePhoneByEmailNext() {
    if (this.emailcode == null || this.emailcode == '') {
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: "请填写您收到的邮箱验证码",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    } else {
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|forgetpasscodevali');
      var api = 'user/User/forgetpasscodevali';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'mobile': this.userinfo.mobile,
        'code': this.emailcode,
        'sendto': this.userinfo.user_email,
      };
      this.http.doPost(api, body, (result) => {
        if (result.code == "200") {
          this.user_moblie = true;
          this.changePhoneStep = 1;
        } else {
          this.modalService.error({
            nzTitle: "系统提示",
            nzContent: result.message,
            nzOkText: "关闭",
            nzOnOk: () => { },
          });
        }
      });
    }
    clearTimeout(this.time);
    this.loadingTime = 60;
    this.codeLoading = false;
  }


  // 更改资料下一步，验证当前密码
  changeNext(key){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|login');
    var api = 'user/User/login';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'mobile': this.userinfo.mobile,
      'user_pass': '',
    }
    switch (key) {
      case 1:
        if (this.email_user_pass == null || this.email_user_pass == '') {
          this.modalService.warning({
            nzTitle: "系统提示",
            nzContent: "请填写当前密码",
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
          return;
        }
        body.user_pass = this.email_user_pass;
        break;
      case 2:
        if (this.email_user_pass == null || this.email_user_pass == '') {
          this.modalService.warning({
            nzTitle: "系统提示",
            nzContent: "请填写当前密码",
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
          return;
        }
        body.user_pass = this.email_user_pass;
        break;
      case 3:
        if (this.old_user_pass == null || this.old_user_pass == '') {
          this.modalService.warning({
            nzTitle: "系统提示",
            nzContent: "请填写当前密码",
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
          return;
        }
        body.user_pass = this.old_user_pass;
      }

    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        switch (key) {
          case 1:
            this.changePhoneByEmailStep = 1;
            break;
          case 2:
            this.changeEmailStep = 1;
            break;
          case 3:
            this.changePasswdStep = 1;
            break;
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: '您输入的密码不正确！',
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  // 更换为邮箱更改手机号的页面
  email_phone() {
    this.user_moblie = false;
    this.changePhoneByEmailStep = 0;
    clearTimeout(this.time);
    this.loadingTime = 60;
    this.codeLoading = false;
  }
  /**
   * 更换绑定的手机号码
   */
  changePhone() {
    if (this.mobile == null || this.mobile == '') {
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: "请填写您的新手机号码",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    } else if (this.code == null || this.code == '') {
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: "请填写收到的手机验证码",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    } else {
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|changephone');
      var api = 'user/user/changePhone';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'old_phone': this.userinfo.mobile,
        'new_phone': this.mobile,
        'old_code': this.oldCode,
        'new_code': this.code,
        'sessionid': this.userinfo.sessionid,
      }
      this.http.doPost(api, body, (result) => {
        // console.log(result);
        if (result.code == "200") {
          this.modalService.error({
            nzTitle: "系统提示",
            nzContent: '修改手机号码成功，PG资源网的手机号也已同步更新!',
            nzOkText: "关闭",
            nzOnOk: () => { },
          });
          this.changePhoneStep = 0;

          this.userinfo.mobile = body.new_phone;
          this.local.set('userinfo', this.userinfo);
          this.userPage.userinfo = this.userinfo;
        } else {
          this.modalService.error({
            nzTitle: "系统提示",
            nzContent: result.message,
            nzOkText: "关闭",
            nzOnOk: () => { },
          });
        }
      });
    }
  }

  /**根据邮箱修改手机号 */
  changePhoneByEmail() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|changephonebyemail');
    var api = 'user/user/changePhoneByEmail';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'type': 2,
      'code': this.code,
      'mobile': this.mobile,
      'sendto': this.mobile,
    };
    // console.log("body", body);
    // return;
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.modalService.success({
          nzTitle: "系统提示",
          nzContent: '修改手机号成功,PG资源网的手机号也已同步更新!',
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
        this.changePhoneStep = 0;
        this.userinfo.mobile = body.mobile;
        this.local.set('userinfo', this.userinfo);
        this.userPage.userinfo = this.userinfo;
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }
  /**更改邮箱,判断邮箱验证码正确*/
  changeEmail() {
    if (this.user_email == null || this.user_email == '') {
      this.modalService.error({
        nzTitle: "系统提示",
        nzContent: '请先输入您的新邮箱！',
        nzOkText: "关闭",
        nzOnOk: () => { },
      });
    } else if (this.emailcode == null || this.emailcode == '') {
      this.modalService.error({
        nzTitle: "系统提示",
        nzContent: '请输入您收到的邮箱验证码！',
        nzOkText: "关闭",
        nzOnOk: () => { },
      });
    } else {
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|forgetpasscodevali');
      var api = 'user/User/forgetpasscodevali';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'mobile':this.userinfo.mobile,
        'code': this.emailcode,
        'sendto': this.user_email,
      };
      console.log("body", body);
      // return;
      this.http.doPost(api, body, (result) => {
        // console.log(result);
        if (result.code == "200") {
          this.submitEdit(2);
        } else {
          this.modalService.error({
            nzTitle: "系统提示",
            nzContent: result.message,
            nzOkText: "关闭",
            nzOnOk: () => { },
          });
        }
      });
    }
  }
  /**获取短信验证码 */
  getCode(mobile) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getcode');
    var api = 'user/User/getCode';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'regstate': 3,
      'mobile': mobile,
    };
    // console.log("body", body);
    // return;
    this.http.doPost(api, body, (result) => {
      console.log(result);
      if (result.code == "2011") {
        this.codeLoading = true;
        this.timesOut();
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }
  /**获取邮箱验证码 */
  getEmailCode(email) {
    if (email == this.user_email) {
      this.isnew = 1;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getemailcode');
    var api = 'user/user/getEmailCode';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'email': email,
      'isnew' : this.isnew,
    };
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.codeLoading = true;
        this.timesOut();
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }
  /**倒计时控制验证码按钮 */
  timesOut() {
    if (this.loadingTime == 0) {
      this.codeLoading = false;
      this.loadingTime = 60;
      return;
    }
    this.codeLoading = true;
    this.loadingTime--;
    this.time = setTimeout(() => {
      this.timesOut();
    }, 1000);
  }

  getShouquanCode(){
    if(this.shouquan_type == 1){
      this.getCode(this.userinfo.mobile);
    }else{
      this.getEmailCode(this.userinfo.user_email);
    }
  }

  shouquan_one(){
    if (this.shouquan_code == null || this.shouquan_code == '') {
      this.modalService.error({
        nzTitle: "系统提示",
        nzContent: '请输入您收到的验证码！',
        nzOkText: "关闭",
        nzOnOk: () => { },
      });
    } else {
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|forgetpasscodevali');
      var api = 'user/User/forgetpasscodevali';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'mobile':this.userinfo.mobile,
        'code': this.shouquan_code,
        'sendto': '',
      };
      if(this.shouquan_type == 1){
        body.sendto == this.userinfo.mobile;
      }else{
        body.sendto == this.userinfo.user_email;
      }
      // return;
      this.http.doPost(api, body, (result) => {
        // console.log(result);
        if (result.code == "200") {
          this.editUser_data = false;
          this.cancel_shouquan = true;
        } else {
          this.modalService.error({
            nzTitle: "系统提示",
            nzContent: result.message,
            nzOkText: "关闭",
            nzOnOk: () => { },
          });
        }
      });
    }
  }

  /**取消修改 */
  editCancel(key) {
    this.mobile = '';       //1手机号
    this.oldCode = '';      //老手机验证码
    this.code = '';         //新手机验证码
    this.user_email = '';   //2邮箱
    this.email_user_pass = ''; //更改邮箱密码验证
    this.emailcode = '';    //邮箱验证码
    this.old_user_pass = ''; //3旧密码
    this.user_pass = '';    //新密码
    this.user_repass = '';  //确认新密码
    this.user_moblie = true;
    clearTimeout(this.time);
    this.loadingTime = 60;
    this.codeLoading = false;
    switch (key) {
      case 0:
        this.editUser_login = false;
        break;
      case 1:
        this.editMobile = false;
        this.changePhoneStep = 0;
        break;
      case 2:
        this.editUser_email = false;
        this.changeEmailStep = 0;
        break;
      case 3:
        this.editUser_pass = false;
        this.changePasswdStep = 0;
        break;
      case 4:
        this.editUser_data = false;
        break;
      // case 5:
      //   this.editWork = false;
      //   break;
      // case 6:
      //   this.editCompany = false;
      //   break;
      // case 7:
      //   this.editUser_url = false;
      //   break;
      // case 8:
      //   this.editSignature = false;
      //   break;
      default:
        break;
    }
  }


  //取消授权
  shouquan(){
    this.editUser_data = true;
  }

  shouquan_two(type){
    if(type == 1){
      this.cancel_shouquan = false;
    }else{
      this.cancel_shouquan = false;
    }
  }


}
