import { HttpService } from './../../services/http.service'; import { Md5 } from "ts-md5";
import { Router, ActivatedRoute, RoutesRecognized, NavigationExtras } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-l-article',
  templateUrl: './l-article.component.html',
  styleUrls: ['./l-article.component.less']
})
export class LArticleComponent implements OnInit {

  userinfo: any = {};         //本地保存的登录用户信息

  labelId = 0;                //标签id
  labelDetail: any = {};      //获取到的标签详情
  tabNum = 0;                 //当前tab位置
  questionList: any = [];     //获取到的技术问答列表
  articleList: any = [];      //获取到的专栏文章列表

  aboutLabelList: any = [];   //获取到的相关标签列表

  page = 1;                   //列表显示第几页
  page_num = 20;              //每页显示的数量
  total: any = [];            //总共几页

  qList: any[] = [];//获取到的数据
  listNum = 1;      //当前显示的数据数量

  loadingText = '载入中';   //列表底部载入控件显示的文字

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
  ) {
    // route.queryParams.subscribe((data) => {
    //   this.labelId = data.id;
    //   if (data.tab == 1) {
    //     this.tabNum = data.tab;
    //     this.getArticleByLabel();
    //   }
    // });
    route.params.subscribe((data)=>{
      this.labelId = data.id;
    })
    this.userinfo = this.local.get('userinfo');
    

    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        // console.log("RoutesRecognized:", event.url);
        if (event.url.indexOf('labels-detail/') > -1) {
          // this.labelId = Number(event.url.substr(event.url.indexOf('labels-detail/') + 17));
          this.labelId = Number(event.url.split('/')[2]);
          this.getLabelDetail();
          if (this.tabNum == 0) {
            this.getQuestionLabelList();
          } else {
            this.getArticleByLabel()
          }
          this.getAboutLabels();
        }
      }
    });

  }
  ngOnInit() {
    //获取标签详情question/question_label/getLabel
    this.getLabelDetail();

    //获取标签下技术问答列表question/question/questionLabelList
    if (this.tabNum == 0) {
      this.getQuestionLabelList();
    } else {
      this.getArticleByLabel()
    }

    //获取相关标签列表question/question_label/aboutLabelList
    this.getAboutLabels();

  }

  /**
   * 获取标签详情question/question_label/getLabel
   */
  getLabelDetail() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getlabel');
    var api = 'question/question_label/getLabel';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'label_id': this.labelId,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        result.data.update_time = result.data.update_time + '000';
        this.labelDetail = result.data;
        // document.getElementById('q_html').innerHTML = this.articleDetail.content;

      }
    })
  }

  /**
   * 获取标签下技术问答列表question/question/questionLabelList
   */
  getQuestionLabelList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|questionlabellist');
    var api = 'question/question/questionLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'label_id': this.labelId,
      'page': this.page,
      'page_num': this.page_num,
    }
    this.http.doPost(api, body, (result) => {
      this.questionList = [];
      if (result.code == "200") {
        result.data.update_time = result.data.update_time + '000';
        this.questionList = result.data;
        this.local.fmDates(this.questionList);
        this.total = [];
        for (let i = 0; i < result.page_info.total; i++) {
          this.total.push({ num: i + 1, checked: this.page == i + 1 });
        }
      }
    })
  }

  /**
   * 获取标签下专栏文章列表column/columns/getArticlesByLabel
   */
  getArticleByLabel() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getarticlesbylabel');
    var api = 'column/columns/getArticlesByLabel';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'label_id': this.labelId,
      'page': this.page,
      'page_num': this.page_num,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        result.data.update_time = result.data.update_time + '000';
        this.articleList = result.data;
        this.local.fmDates(this.articleList);
        this.total = [];
        for (let i = 0; i < result.page_info.total; i++) {
          this.total.push({ num: i + 1, checked: this.page == i + 1 });
        }
      }
    })
  }

  /**
   * 获取相关标签列表question/question_label/aboutLabelList
   */
  getAboutLabels() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|aboutlabellist');
    var api = 'question/question_label/aboutLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'label_id': this.labelId,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.aboutLabelList = result.data;
      }
    })
  }

  /**
   * 关注标签操作
   */
  fouceTag() {
    if (!this.checkUser()) {
      return;
    }
    if (this.labelDetail.follow_status) {
      this.delFollowLabel();
    } else {
      this.followLabel()
    }
  }
  /**关注标签question/label_follow/addFollowLabel */
  followLabel() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addfollowlabel');
    var api = 'question/label_follow/addFollowLabel';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'label_id': this.labelId,
      'user_id': this.userinfo.id,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.getLabelDetail()
      }
    })
  }
  /**取消关注标签question/label_follow/deleteFollowLabel */
  delFollowLabel() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|deletefollowlabel');
    var api = 'question/label_follow/deleteFollowLabel';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'label_id': this.labelId,
      'user_id': this.userinfo.id,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.getLabelDetail()
      }
    })
  }
  /**
   * 加载数据
   * @param e 
   */
  changeTab(e) {
    // console.log(e[0].index);
    this.page = 1;
    this.tabNum = e[0].index;
    if (e[0].index == 0) {
      this.getQuestionLabelList();
    } else if (e[0].index == 1) {
      this.getArticleByLabel();
    }
  }

  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    if (this.tabNum == 0) {
      this.getQuestionLabelList();
    } else if (this.tabNum == 1) {
      this.getArticleByLabel();
    }
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    if (this.tabNum == 0) {
      this.getQuestionLabelList();
    } else if (this.tabNum == 1) {
      this.getArticleByLabel();
    }
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    if (this.tabNum == 0) {
      this.getQuestionLabelList();
    } else if (this.tabNum == 1) {
      this.getArticleByLabel();
    }
  }
  /**更新页面选择器 */
  updatepage() {
    console.log("tabNum:", this.tabNum);
    console.log("total:", this.total);
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
  }

  /**
   * 检查是否已经登录
   */
  checkUser() {
    // console.log("检查");
    if (!this.userinfo) {
      // console.log("未登录");
      let navigationExtras: NavigationExtras = {
        queryParams: { 'from': 'qdetail', },
      }
      this.router.navigate(['/login'], navigationExtras)
      return false;
    }
    return true;
  }

  /**
   * 点赞操作question/question_operate/operate
   * @param article 文章json
   * @param key 
   */
  doAgree(article) {
    if (!this.checkUser()) {
      return;
    }
    if (article.is_thumb_up) {
      var type = 6;
    } else {
      var type = 5;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'question/question_operate/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'question_id': article.id,
      'operate_type': type,
      'type': 1,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (article.is_thumb_up) {
          article.is_thumb_up = 0;
          article.praise_num--;
        } else {
          article.is_thumb_up = 1;
          article.praise_num++;
        }
      }
    })
  }

}
