import { HttpService } from './../services/http.service'; import { Md5 } from "ts-md5";
import { Router, NavigationExtras } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../services/storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.less']
})
export class LabelsComponent implements OnInit {

  userinfo: any = {};     //本地保存的用户登录信息

  followLabelList: any = [];      //我关注的标签列表
  tagList: any = [];      //全部标签列表，分一二级
  tags: any = [];         //二级标签

  tagId = '';             //要添加的标签名
  addTagId = 0;           //要添加的标签id

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
  ) {
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userinfo = this.local.get('userinfo');
    }
  }

  ngOnInit() {
    //获取我关注的标签列表question/label_follow/followLabelList
    if (this.userinfo && this.userinfo.id) {
      this.getFollowLabelList();
    }
    //获取所有标签列表（分一二级）question/question_label/allLabelList
    this.getAllTags();
  }

  /**进入标签详情页 */
  goTagDetail(tag) {
    this.router.navigate(['/labels-detail']);
  }

  /**
   * 获取我关注的标签列表question/label_follow/followLabelList
   */
  getFollowLabelList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|followlabellist');
    var api = 'question/label_follow/followLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.followLabelList = result.data;
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**
   * 获取所有标签列表（分一二级）question/question_label/allLabelList
   */
  getAllTags() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|alllabellist');
    var api = 'question/question_label/allLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.tagList = result.data;

          result.data.forEach(tag1 => {
            tag1.labelList.forEach(tag => {
              this.tags.push(tag);
            });
          });
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**
   * 加关注question/label_follow/addFollowLabel
   * @param tag 二级标签
   */
  addTag() {
    this.checkUser();
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addfollowlabel');
    var api = 'question/label_follow/addFollowLabel';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'label_id': this.tagId,
      'user_id': this.userinfo.id,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        // console.log("新标签加关注成功");
        this.getFollowLabelList();
        // } else {
        //   this.modalService.error({
        //     nzTitle: "系统提示",
        //     nzContent: result.message,
        //     nzOkText: "关闭",
        //     nzOnOk: () => { },
        //   })
      }
      this.tagId = null;
    })
  }

  /**
   * 检查是否已经登录
   */
  checkUser() {
    if (!this.userinfo.id) {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'from': 'labels', },
      }
      this.router.navigate(['/login'], navigationExtras)
      return false;
    }
    return true;
  }

}
