import { HttpService } from './../services/http.service'; import { Md5 } from "ts-md5";
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../services/storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruit',
  templateUrl: './recruit.component.html',
  styleUrls: ['./recruit.component.less']
})
export class RecruitComponent implements OnInit {

  jwList: any = [];           //求职信息列表
  recruitList: any[] = [];    //招聘信息列表
  recList: any = [];          //求职招聘推荐公用的列表
  listNum = 1;      //当前显示的数据数量
  tabNum = 0;       //当前tab编号

  loadingText = '载入中';   //列表底部载入控件显示的文字

  page = 1;                   //列表显示第几页
  page_num = 10;              //每页显示的数量
  total: any = [];            //总共几页
  page_prev = 1;
  page_next = 5;

  advert = true;
  offsetTop = 0;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,

  ) {
    this.advert = true;
  }
  ngOnInit() {
    //this.getJwList();
    this.getRecruitList();
  }

  close(){
    this.advert = false;
  }
  //点击求职
  gwlist() {
    this.page = 1;
    this.page_prev = 1;
    this.page_next = 5;
    this.getJwList();
  }
  //点击招聘
  rclist() {
    this.page = 1;
    this.page_prev = 1;
    this.page_next = 5;
    this.getRecruitList();
  }

  /**获取求职信息列表 */
  getJwList() {
    this.tabNum = 0;
    this.recList = [];
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|jobwantedlist');
    var api = 'work/work/jobwantedList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'page': this.page,
      'page_num': this.page_num,
    };
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      console.log(result);
      this.jwList = [];
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.jwList = result.data;
          this.getRecRecList();
          this.local.fmDates(this.jwList);
          this.total = [];
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      }
    });
  }
  /**获取求职推荐列表 */
  getRecJWList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|recjobwantedlist');
    var api = 'work/work/recJobwantedList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.recList = result.data;
        }
      }
    })
  }
  /**获取招聘推荐列表 */
  getRecRecList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|recrecruitlist');
    var api = 'work/work/recRecruitList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.recList = result.data;
        }
      }
    })
  }
  /**获取招聘列表 */
  getRecruitList() {
    this.tabNum = 1;
    this.recList = [];
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|recruitlist');
    var api = 'work/work/recruitList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'page': this.page,
      'page_num': this.page_num,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      this.recruitList = [];
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.recruitList = result.data;
          this.getRecJWList();
          this.local.fmDates(this.recruitList);
          this.total = [];
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      }
    });
    // this.recruitList = [
    //   {
    //     title: "用户运营实习生",
    //     content: "如果对React技术栈感兴趣的你，可以去阅读我的前面两篇文章： 从零自己实现一个mini-React框架 从零搭建一个React优化版脚手架 GitHub上 面都有对应的源码哦～ 欢迎Star 本项目在Github上的源码地址： mini-webpac... ",
    //     salary: '2k~3k',  //薪资
    //     place: '北京',    //工作地点
    //     years: 0,         //工作年限
    //     education: 2,     //学历：0=高中；1=专科；2=本科；3=研究生；4=博士生
    //     skills: [         //必备技能
    //       { name: 'PostgresSQL' },
    //       { name: 'Modeling' },
    //     ],
    //   }, {
    //     title: "项目实施工程师",
    //     salary: '5k~10k',  //薪资
    //     place: '北京',    //工作地点
    //     years: 2,         //工作年限
    //     education: 3,     //学历：0=高中；1=专科；2=本科；3=研究生；4=博士生
    //     skills: [         //必备技能
    //       { name: 'PostgresSQL' },
    //       { name: 'Modeling' },
    //     ],
    //   }, {
    //     title: "用户运营实习生",
    //     salary: '2k~3k',  //薪资
    //     place: '北京',    //工作地点
    //     years: 0,         //工作年限
    //     education: 2,     //学历：0=高中；1=专科；2=本科；3=研究生；4=博士生
    //     skills: [         //必备技能
    //       { name: 'PostgresSQL' },
    //     ],
    //   }, {
    //     title: "项目实施工程师",
    //     salary: '5k~10k',  //薪资
    //     place: '北京',    //工作地点
    //     years: 2,         //工作年限
    //     education: 3,     //学历：0=高中；1=专科；2=本科；3=研究生；4=博士生
    //     skills: [         //必备技能
    //       { name: 'PostgresSQL' },
    //       { name: 'Modeling' },
    //     ],
    //   }, {
    //     title: "用户运营实习生",
    //     salary: '2k~3k',  //薪资
    //     place: '北京',    //工作地点
    //     years: 0,         //工作年限
    //     education: 2,     //学历：0=高中；1=专科；2=本科；3=研究生；4=博士生
    //     skills: [         //必备技能
    //       { name: 'PostgresSQL' },
    //     ],
    //   }, {
    //     title: "项目实施工程师",
    //     salary: '5k~10k',  //薪资
    //     place: '北京',    //工作地点
    //     years: 2,         //工作年限
    //     education: 3,     //学历：0=高中；1=专科；2=本科；3=研究生；4=博士生
    //     skills: [         //必备技能
    //       { name: 'PostgresSQL' },
    //       { name: 'Modeling' },
    //     ],
    //   }, {
    //     title: "用户运营实习生",
    //     salary: '2k~3k',  //薪资
    //     place: '北京',    //工作地点
    //     years: 0,         //工作年限
    //     education: 2,     //学历：0=高中；1=专科；2=本科；3=研究生；4=博士生
    //     skills: [         //必备技能
    //       { name: 'PostgresSQL' },
    //     ],
    //   },
    // ];
  }


  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    if(this.tabNum==0){
      this.getJwList();
    }else{
      this.getRecruitList();
    }
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    if(this.tabNum==0){
      this.getJwList();
    }else{
      this.getRecruitList();
    }
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    if(this.tabNum==0){
      this.getJwList();
    }else{
      this.getRecruitList();
    }
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
    if (this.page == 1) {
      this.page_prev = this.page;
      this.page_next = this.page + 4;
    } else if (this.page == 2) {
      this.page_prev = this.page - 1;
      this.page_next = this.page + 3;
    } else if (this.page == this.total.length - 1) {
      this.page_prev = this.page - 3;
      this.page_next = this.page + 1;
    } else if (this.page == this.total.length) {
      this.page_prev = this.page - 4;
      this.page_next = this.page;
    } else {
      this.page_prev = this.page - 2;
      this.page_next = this.page + 2;
    }
  }
   /**向前5页 */
   prev(num) {
    if (num - 5 < 1) {
      this.page = 1;
    } else {
      this.page = num - 5;
    }
    this.updatepage();
    if(this.tabNum==0){
      this.getJwList();
    }else{
      this.getRecruitList();
    }
  }
  /**向后5页 */
  next(num) {
    if (num + 5 > this.total.length) {
      this.page = this.total.length;
    } else {
      this.page = num + 5;
    }
    this.updatepage();
    if(this.tabNum==0){
      this.getJwList();
    }else{
      this.getRecruitList();
    }
  }

}
