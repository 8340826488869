import { UserComponent } from './../user.component';
import { HttpService } from './../../services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Md5 } from 'ts-md5';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-myconcern',
  templateUrl: './myconcern.component.html',
  styleUrls: ['./myconcern.component.less']
})
export class MyconcernComponent implements OnInit {
  userId = 0;
  isMe = false;
  userinfo: any = {};
  concernList: any = [];
  recommends: any = [];   //右侧推荐关注的列表
  page = 1;                   //列表显示第几页
  page_num = 10;              //每页显示的数量
  total: any = [];            //总共几页
  isLoadingWaitOne: boolean = false;
  isLoadingWaitTwo: boolean = false;
  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((data) => {
      this.userId = data.userId;
    });
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      if (this.userId == this.local.get('userinfo').id) {
        this.isMe = true;
        this.userinfo = this.local.get('userinfo');
      } else {
        this.isMe = false;
        this.getUserInfo(this.userId);
      }
    } else {
      this.isMe = false;
      this.getUserInfo(this.userId);
    }
  }

  ngOnInit() {
    //获取关注我的人列表user/user_follow/followMeList
    this.getConcernList();
  }

  /**
   * 通过用户id获取用户信息user/user/getNewInfo
   */
  getUserInfo(id) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
    var api = 'user/user/getNewInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': id,
      // 'my_user_id': this.local.get('userinfo').id,
      // 'sessionid': this.local.get('userinfo').sessionid,
      'my_user_id': null,
      'sessionid': null,
    }
    if (this.local.get('userinfo')) {
      body.my_user_id = this.local.get('userinfo').id;
      body.sessionid = this.local.get('userinfo').sessionid;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.userinfo = result.data;
      }
    })
  }
  /**
   * 获取关注我的人列表user/user_follow/followMeList
   */
  getConcernList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|followmelist');
    var api = 'user/user_follow/followMeList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'page': this.page,
      'page_num': this.page_num,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.concernList = result.data;
        this.total = [];
        for (let i = 0; i < result.page_info.total; i++) {
          this.total.push({ num: i + 1, checked: this.page == i + 1 });
        }
      }
    })
  }

  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getConcernList();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getConcernList();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getConcernList();
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
  }

  /**
   * 关注用户user/user_follow/operate
   */
  followLabel(id, user) {
    user.isloading = true
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'user/user_follow/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'about_user_id': id,
      'type': 1,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      user.isloading = false
      // console.log(result);
      if (result.code == "200") {
        this.getConcernList();
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
  /**
   * 取消关注用户user/user_follow/operate
   */
  delFollowLabel(id,user) {
    user.isloading = true
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'user/user_follow/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'about_user_id': id,
      'type': 2,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      user.isloading = false
      // console.log(result);
      if (result.code == "200") {
        this.getConcernList();
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
}
