import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  /***********************************************************************
   * 封装localStorage的三个方法，这样就可以直接存取对象类型
   * @param key 
   * @param value 
   */
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));  /*对象转换成字符串*/
  }
  get(key) {
    return JSON.parse(localStorage.getItem(key));   /*字符串转换成对象*/
  }
  remove(key) {
    localStorage.removeItem(key);
  }
  fmDates(list) {
    var nowTime = new Date().getTime();
    var minuteTime = 1000 * 60;
    var hourTime = minuteTime * 60;
    var dayTime = hourTime * 24;
    for (let i = 0; i < list.length; i++) {
      var dataTime = 0;
      if (list[i].update_time) {
        dataTime = Number(list[i].update_time + '000');
      } else if (list[i].create_time) {
        dataTime = Number(list[i].create_time + '000');
      }
      let diffTime = nowTime - dataTime;
      if (diffTime < 0) {
        list[i].dateTime = "刚刚";
      }
      var dayC = diffTime / dayTime;
      var hourC = diffTime / hourTime;
      var minC = diffTime / minuteTime;
      if (dayC >= 1) {
        list[i].dateTime = new Date(dataTime).getFullYear() + '-' + (new Date(dataTime).getMonth() + 1) + '-' + new Date(dataTime).getDate();
        // } else if (dayC == 1) {
        //   list[i].dateTime = "昨天";
      } else if (hourC >= 1) {
        list[i].dateTime = "" + Math.floor(hourC) + "小时前";
      } else if (minC >= 5) {
        list[i].dateTime = "" + Math.floor(minC) + "分钟前";
      } else {
        list[i].dateTime = "刚刚";
      }
    }
  }

}
