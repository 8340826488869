import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from './../../services/http.service';
import { StorageService } from './../../services/storage.service';
import { Md5 } from "ts-md5";
import { Router, NavigationExtras } from '@angular/router';
import { NzModalService, UploadFile, NzMessageService } from 'ng-zorro-antd';
import { Component, OnInit } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { EditorComponent } from './../../editormd/editor/editor.component';
import * as $ from 'jquery';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';

declare var editormd: any;


@Component({
  selector: 'app-b-write-article',
  templateUrl: './b-write-article.component.html',
  styleUrls: ['./b-write-article.component.less']
})
export class BWriteArticleComponent implements OnInit {

  userinfo: any = {};   //本地保存的用户登录信息

  tagList: any = [];    //获取到的所有标签
  columnList: any = []; //所有专栏列表
  labelList: any = [];  //动态二级标签
  thisbtn = 0;          //选中的以及标签

  askTag: any = [];     //提问的标签

  articleTitle = '';    //文章的标题
  textType = 1;         //文章类型：1=原创；2=翻译；3=转载
  origin_url = '';      //转载|翻译原文url
  column_id = -1;       //所属专栏id，默认-1表示"个人文章",未在任何专栏
  introduce = '';       //文章简介
  quillContent = '';    //文章内容，富文本数据
  column_labels = '';   //选择的标签id字符串

  isAdd = false;
  isShowTags = false;   //是否显示下拉菜单
  isShowAddTag = false; //是否显示创建标签对话框
  isShowAddColumn = false;   //是否显示申请专栏对话框

  newBlog: any = {};    //申请专栏的json
  newTag = '';          //新标签名
  describe = '';        //新标签名的描述
  tagType;         //新标签类别
  options:any = [];    //所有标签一级列表  
  
  filterTags:any = [];  
  allTags:any = [];
  tag;
   

  editorType = '2'; // 1:富文本编辑器；2：markdown编辑器

  quilleditor: any;
  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['link', 'blockquote', 'code-block', 'image'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'header': 1 }, { 'header': 2 }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
    ]
  };

  editor: any;
  conf = new EditorComponent();
  showInfo: any; //编辑器markdown内容
  intro: any;  // 编辑器html内容

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    public HTTP: HttpClient,
    private msg: NzMessageService
  ) {
    if (local.get('userinfo') != null && local.get('userinfo').id > 0) {
      this.userinfo = local.get('userinfo');
    }
  }

  ngOnInit() {
    //获取专栏列表
    this.getAllColumns();
    //获取所有标签列表（分一二级）
    this.getTagList();
    //获取所有二级标签
    this.geAllTags();
  }
  ngAfterViewInit(): void {
    this.EditorCreated();
  }


  /**
   * 获取一级标签列表question/question_label/questionOneLabelList
   */
  getOneTags(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|questiononelabellist');
    var api = 'question/question_label/questionOneLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        var temp;
        for(let i=0;i < result.data.length/2;i++){
          temp=result.data[i];
          result.data[i]=result.data[result.data.length-1-i];
          result.data[result.data.length-1-i]=temp;
        }
        this.options = result.data;
      }
    })
  }

  /**
   * 获取所有标签列表question/question_label/allLabelList
   */
  getTagList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|alllabellist');
    var api = 'question/question_label/allLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.tagList = result.data;
        this.labelList = this.tagList[0].labelList;
      }
    })
  }

  /**
   * 获取所有二级标签
   */
   geAllTags(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|questiontwolabellist');
    var api = 'question/question_label/questionTwoLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'parent_id': 0,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.allTags = result.data;
        for(let i=0;i<this.allTags.length;i++){
          if(this.userinfo.id!='1'){
            if(this.allTags[i].id == 118){
              this.allTags.splice(i,1);
            } else if(this.allTags[i].id == 132){
              this.allTags.splice(i,1);
            } 
          }
         
        }
        this.filterTags = this.allTags;
      }
    })
   }

  addTag(){
    for(let i=0;i<this.allTags.length;i++){
      if(this.tag == this.allTags[i].id){
        if(this.askTag.length == 0){
          this.askTag.push({id:this.allTags[i].id,label:this.allTags[i].label,checked:true})
        }else{
          var is = 0;
          this.askTag.forEach(val => {
            if(val.id==this.tag){
              is = 1;
            }
          });
          if(is == 0){
            this.askTag.push({id:this.allTags[i].id,label:this.allTags[i].label,checked:true})
          }
        } 
      }
    }
   
  }

  onChange(value){
    var isN = isNaN(value);
    if(isN){
      this.filterTags = this.allTags.filter(option => option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }else{
      this.filterTags = this.allTags;
    }
      
  }

  test(){
    this.addTag();
  }

  keyupChanged(e){
    if(e.keyCode == 13){
      this.addTag();
    }
  }

  /**
   * 获取所有专栏列表column/columns/getAllColumns
   */
  getAllColumns() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getallcolumns');
    var api = 'column/columns/getAllColumns';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    };
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.columnList = result.data;
        }
      }
    });
  }

  /**选择文章类型触发事件 */
  typeChange(e) {
    this.textType = e;
  }

  /**切换标签 */
  selectChange(num) {
    this.thisbtn = num;
    this.labelList = this.tagList[num].labelList;
    for(let i=0;i<this.labelList.length;i++){
    if(this.userinfo.id!='1'){
      if(this.labelList[i].id == 118){
        this.labelList.splice(i,1);
      }else if(this.labelList[i].id == 132){
        this.labelList.splice(i,1);
      }
    }
    }
  }
  checkChange(e, key): void {
    // console.log(e);
    this.tagList[this.thisbtn].labelList[key].checked = !this.tagList[this.thisbtn].labelList[key].checked;
    this.askTag = this.askTag.filter(value => value.id != e.id);
    if (this.tagList[this.thisbtn].labelList[key].checked) {
      this.askTag.push(e);
    }
    // console.log("this.askTag=", this.askTag.toString());
    // console.log("this.askTag.length=", this.askTag.length);
  }
  delTag(e, key): void {
    this.askTag.splice(key, 1);
    this.tagList.forEach(tag1 => {
      tag1.labelList.forEach(tag => {
        if (tag.id == e.id) tag.checked = false;
      });
    });
  }

  //切换编辑器
  changeEditor(type){
    this.editorType = type;
  }
  /**
   * 添加专栏文章column/columns/addColumnArticle
   */
  doSubmit(type) {
    if(this.origin_url==''&&this.textType!=1){
      this.modalService.error({
        nzTitle: "系统提示",
        nzContent: "请填写原文地址",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }
    var introduce1 = $.trim($('#introduce').text());
    this.loading = true;
    var label_ids: any = [];
    for (let tag of this.askTag) {
      label_ids.push(tag.id);
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnarticle');
    var api = 'column/columns/addColumnArticle';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'title': this.articleTitle,
      'is_origin': this.textType,
      'origin_url': this.origin_url,
      'column_id': this.column_id,
      'introduce': introduce1.substring(0, 200),
      'content': this.showInfo,
      // 'content': this.quillContent,
      'column_labels': label_ids.toString(),
      'sessionid': this.userinfo.sessionid,
      'content_type': type,
    };
    if(type == 1){
      // body.introduce = '';
      body.content = this.quillContent;
    }
    // console.log(body.title);
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.modalService.success({
          nzTitle: "创建文章成功",
          nzContent: "文章正在审核中，请稍后...",
          nzOkText: "关闭",
          nzOnOk: () => {
            let navigationExtras: NavigationExtras = {
              queryParams: { 'userId': this.userinfo.id, },
            };
            this.router.navigate(['/user/article'], navigationExtras);
          },
        });
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
      this.loading = false;
    });
  }

  /**显示申请专栏对话框 */
  showAddColumn() {
    this.isShowTags = false;
    this.isShowAddColumn = true;
  }
  /**显示创建标签对话框 */
  showAddTag() {
    this.isAdd = false;
    this.isShowTags = false;
    this.isShowAddTag = true;
    this.getOneTags();
  }
  handleOk(): void {
    // console.log("newBlog:", this.newBlog);

      this.isShowAddTag = false;
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumn');
      var api = 'column/columns/addColumn';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'user_id': this.userinfo.id,
        'sessionid': this.userinfo.sessionid,
        'title': this.newBlog.name,
        'diyurl': this.newBlog.website,
        'company': this.newBlog.company,
        'position': this.newBlog.postion,
        'introduce': this.newBlog.describe,
        'columnlogo': this.avatarUrl,
      };
      // console.log("body", body);
      // return;
      this.http.doPost(api, body, (result) => {
        // console.log(result);
        if (result.code == "200") {
          this.modalService.success({
            nzTitle: "申请新专栏成功",
            nzContent: "申请专栏成功，等待审核",
            nzOkText: "确定",
            nzOnOk: () => {
              this.getAllColumns();
              this.isShowAddColumn = false;
              // window.history.back();
              // let navigationExtras: NavigationExtras = {
              //   queryParams: { 'userId': this.userinfo.id, },
              // }
              // this.router.navigate(['/user/article'], navigationExtras);
            },
          });
        } else {
          this.modalService.error({
            nzTitle: "系统提示",
            nzContent: result.message,
            nzOkText: "关闭",
            nzOnOk: () => {
            },
          });
        }
      });
  }
  timer: any;
  trimUrlOk = true;
  diyUrlChange(event) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.doTrimdiyurl(event);
    }, 500)
  }
  doTrimdiyurl(url) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|trimdiyurl');
    var api = 'column/columns/trimdiyurl';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'diyurl': url,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.trimUrlOk = true;
      } else {
        this.trimUrlOk = false;
      }
    })
  }
  handleCancel(): void {
    this.isShowAddTag = false;
    this.isShowAddColumn = false;
  }
  selectedFileOnChanged(event) {
    // console.log("event:", event);
  }
  beforeUpload = (file: File) => {
    // console.log('file:', file)
    return new Observable((observer: Observer<boolean>) => {
      const isJPG = file.type === 'image/jpeg';//image/png,image/jpeg,image/gif,image/bmp
      const isPNG = file.type === 'image/png';
      const isBMP = file.type === 'image/bmp';
      var isjpb: boolean = isJPG || isPNG || isBMP;
      if (!isjpb) {
        this.msg.error('请上传JPG、PNG、BMP文件!');
        observer.complete();
        return;
      }
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.msg.error('图片请不要超过1MB!');
        observer.complete();
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        var dataURL = reader.result;
        this.addColumnLogo(dataURL)
      }
      this.checkImageDimension(file).then(dimensionRes => {
        observer.next(false);
        observer.complete();
      });
    });
  };

  getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  private checkImageDimension(file: File): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src!);
        resolve(width === height && width >= 300);
      };
    });
  }
  loading: any;
  avatarUrl: any
  handleChange(info: { file: UploadFile }): void {
    console.log(info.file.status)
    console.log('changeinfo', info);
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          this.avatarUrl = img;
          // console.log('base64:', img);
          // this.addColumnLogo();
        });
        break;
      default:
        break;
    }
  }

  /**
   * 上传图片column/columns/addColumnLogo
   */
  addColumnLogo(dataURL) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnlogo');
    var api = 'column/columns/addColumnLogo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'columnlogo': dataURL,
      'type': 2
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.avatarUrl = 'https://admin.pgfans.cn' + result.data.url;
        // this.submitEdit(5);
      }
    })
  }

  /**
   * 创建标签question/question_label/addQuestionLabel
   */
  doAddTag() {
    if(this.newTag == ''){
      this.modalService.error({
        nzTitle: "提示",
        nzContent: "请输入标签名！",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }
    if(!this.tagType){
      this.modalService.error({
        nzTitle: "提示",
        nzContent: "请选择标签分类！",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }
    // console.log("创建标签");
    if (!this.userinfo) {
      this.userinfo.id = '';
    }
    // return;
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addquestionlabel');
    var api = 'question/question_label/addQuestionLabel';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'label': this.newTag,
      'remarks': this.describe,
      'parent_id':this.tagType,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.getTagList();
        this.isShowAddTag = false;
        this.modalService.success({
          nzTitle: "提示",
          nzContent: "添加标签" + this.newTag + "成功",
          nzOkText: "关闭",
          nzOnOk: () => { 
            this.askTag.push({id:result.data.id,label:result.data.label,checked:true})
          },
        })
        this.newTag = '';
        this.describe = '';
        this.tagType = '';
      } else {
        this.modalService.error({
          nzTitle: "提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }
  EditorCreated() {
    this.conf.placeholder = '请输入内容';
    this.editor = editormd('mdBox', this.conf);
    // $('#mdBox').on('paste', function (ev) {
    //   console.log(ev)
    // });
    this.editor.on('change', () => {
      this.showInfo = this.editor.getMarkdown();
      this.intro = this.editor.getHTML();
      $('#introduce').html(this.intro);
    });
  }

  quillEditorCreated(quill) {
    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler.bind(this));
    this.quilleditor = quill;
  }
  imageHandler() {
    const Imageinput = document.createElement('input');
    Imageinput.setAttribute('type', 'file');
    Imageinput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp');
    Imageinput.classList.add('ql-image');
    Imageinput.addEventListener('change', () => {
      if (Imageinput.files != null && Imageinput.files[0] != null) {
        const file = Imageinput.files[0];
        if(file.size>5*1024*1024){
          this.modalService.error({
            nzTitle: "提示",
            nzContent: "图片尺寸不能超过5MB！",
            nzOkText: "关闭",
            nzOnOk: () => {},
          })
        }else{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          var dataURL = reader.result;
          let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnlogo');
          var api = 'column/columns/addColumnLogo';
          var body = {
            'timestamp': new Date().getTime().toString().substr(0, 10),
            'signature': signature,
            'columnlogo': dataURL,
            'type': 2
          }
          this.http.doPost(api, body, (result) => {
            if (result.code == "200") {
              let imgUrl = 'https://admin.pgfans.cn' + result.data.url;
              this.quilleditor.insertEmbed(this.quilleditor.getSelection(true).index, 'image', imgUrl);
            }
          })
        };
        }
      }
    });
    Imageinput.click();
  }

  contentChanged(event) {
    var that = this;
    console.log(event)
    if(event.html.indexOf("img") != -1 ){
      $('.ql-editor img').each(function(index){
        var ht = $(this).attr('src');
        console.log('ht:', ht)
        if(ht.split(':')[0] == 'http'||ht.split('/')[0]=='data:image'){
          that.imgUpload(ht,index);
        }
      })
    }
    this.quillContent = event.html;  // 内容编辑
    $('#introduce').html(this.quillContent);
  }

  //图片链接转base64
  getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var ext = img.src.substring(img.src.lastIndexOf(".")+1).toLowerCase();
    var dataURL = canvas.toDataURL("image/"+ext);
    return dataURL;
  }

  //图片链接是http或者base64,上传到服务器
  imgUpload(img,index){
    var that = this;
    var base64 = ''
    var tempImage = new Image();
    tempImage.src = img;
    tempImage.crossOrigin = "*";
    tempImage.onload = function(){
      if(tempImage.src.split(':')[0] == 'http') {
        base64 = that.getBase64Image(tempImage);
      }else {
        base64 = img
      }
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnlogo');
      var api = 'column/columns/addColumnLogo';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'columnlogo': base64,
        'type': 2,
      }
      that.http.doPost(api, body, (result) => {
        if (result.code == "200") {
          var imgUrl = 'https://admin.pgfans.cn' + result.data.url;
          $('.ql-editor img').eq(index).attr('src',imgUrl)
        }
      })
    }
  }
}
