import { Component, OnInit } from '@angular/core';
import { Md5 } from 'ts-md5';
import { HttpService } from './../../services/http.service';
import { StorageService } from './../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-mygoods',
  templateUrl: './mygoods.component.html',
  styleUrls: ['./mygoods.component.less']
})
export class MygoodsComponent implements OnInit {

  OrderList:any = [];  // 订单列表
  
  page = 1;                   //列表显示第几页
  page_num = 5;              //每页显示的数量
  total = [];            //总共几页
  page_prev = 1;
  page_next = 5;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
  ) { }

  ngOnInit() {
    this.getMyOrder();
  }

  /**
   * 我的订单列表goods/order/orderList
   */
  getMyOrder(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|orderlist');
    var api = 'goods/order/orderList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'page': this.page,
      'page_num': this.page_num,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result)
      if (result.code == "200") {
        this.OrderList = result.data;
        this.total = [];
        for (let i = 0; i < result.page_info.total; i++) {
          this.total.push({ num: i + 1, checked: this.page == i + 1 });
        }
      }
    })
  }

  //订单详情
  goDetail(id){
    this.router.navigate(['/user/or-detail'],{queryParams:{userId:this.local.get('userinfo').id,orderId:id}});
  }

  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getMyOrder();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getMyOrder();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getMyOrder();
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
    if (this.page == 1) {
      this.page_prev = this.page;
      this.page_next = this.page + 4;
    } else if (this.page == 2) {
      this.page_prev = this.page - 1;
      this.page_next = this.page + 3;
    } else if (this.page == this.total.length - 1) {
      this.page_prev = this.page - 3;
      this.page_next = this.page + 1;
    } else if (this.page == this.total.length) {
      this.page_prev = this.page - 4;
      this.page_next = this.page;
    } else {
      this.page_prev = this.page - 2;
      this.page_next = this.page + 2;
    }
  }

  /**向前5页 */
  prev(num) {
    if (num - 5 < 1) {
      this.page = 1;
    } else {
      this.page = num - 5;
    }
    this.updatepage();
    this.getMyOrder();
  }
  /**向后5页 */
  next(num) {
    if (num + 5 > this.total.length) {
      this.page = this.total.length;
    } else {
      this.page = num + 5;
    }
    this.updatepage();
    this.getMyOrder();
  }
}
