import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { StorageService } from './../../services/storage.service';

@Component({
  selector: 'app-result-success',
  templateUrl: './result-success.component.html',
  styleUrls: ['./result-success.component.less']
})
export class ResultSuccessComponent implements OnInit {
  pgdou = 0; // 剩余pg豆

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private local: StorageService,
  ) { 
    route.queryParams.subscribe((data) => {
      this.pgdou = data.dou;
    });
  }

  ngOnInit() {
  }

  goMyGoods(){
    this.router.navigate(['/user/mygoods'], {queryParams: { 'userId': this.local.get('userinfo').id }});
  }
}
