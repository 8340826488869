import { Md5 } from 'ts-md5';
import { HttpService } from './../services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.less']
})
export class MessagesComponent implements OnInit {

  userinfo: any = {};     //本地保存的用户登录信息

  tabIndex = 0;
  messageList: any = [];
  noticeList: any = [];

  loading = false;
  data = [
    {
      title: 'PostgreSQL Fans'
    },
    {
      title: 'PostgreSQL Fans'
    },
    {
      title: 'PostgreSQL Fans'
    },
    {
      title: 'PostgreSQL Fans'
    }
  ];

  page = 1;                   //列表显示第几页
  page_num = 20;              //每页显示的数量
  total: any = [];            //总共几页
  isShowMessageDetail = false;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
    private appPage: AppComponent,
  ) {
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userinfo = this.local.get('userinfo');
    }

    route.queryParams.subscribe((data) => {
      this.tabIndex = data.tabIndex;
    });
  }

  ngOnInit() {
    if (this.tabIndex == 0) {
      this.getNotices();
    } else if (this.tabIndex == 1) {
      this.getMessages();
    }
  }

  selectTab(event) {
    this.tabIndex = event.index;
    this.total = [];
    if (event.index == 0) {
      this.getNotices();
    } else if (event.index == 1) {
      this.getMessages();
    }
  }
  /**
   * 获取消息通知user/notice/noticeList
   */
  getNotices() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|allnoticelist');
    var api = 'user/notice/allNoticeList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'page': this.page,
      'page_num': this.page_num,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        console.log("notices:", result);
        this.total = [];
        if (result.data != null) {
          this.noticeList = result.data;
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      }
    })
  }
  /**
   * 获取私信user/user/getAllPrivateMessage
   */
  getMessages() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getallprivatemessage');
    var api = 'user/user/getAllPrivateMessage';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'page': this.page,
      'page_num': this.page_num,
      'total': 0,
      //????怎么还有个总页数？写代码的人可以偶尔粗心，毕竟工作量大强度高千头万绪难免出错，但是总是粗心就不要干了！
    }
    this.http.doPost(api, body, (result) => {
      // console.log("messages:", result);
      if (result.code == "200") {
        this.total = [];
        if (result.data != null) {
          this.messageList = result.data;
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      }
    })
  }


  doOperate(notice, key) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|noticeoperate');
    var api = 'user/notice/noticeOperate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'op_type': notice.type,
      'notice_id': notice.id,
    }
    if (key == -1) {
      body.op_type = 5;
      body.notice_id = 0;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        if (key == -1) {
          this.getNotices();
        } else {
          this.noticeList[key].status = 1;
        }
        this.appPage.getNoticeCount();
      }
    })
  }
  mDetail: any = {};
  mDetailUser = '';
  mDetailTitle = '';
  mDetailContent = '';
  doRead(message, key) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|setmessagereaded');
    var api = 'user/user/setMessageReaded';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'message_id': message.id,
    }
    if (key == -1) {
      body.message_id = -1;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        if (key == -1) {
          this.getMessages();
        } else {
          this.isShowMessageDetail = true;
          this.mDetail = message;
          this.mDetailUser = message.user_name;
          this.mDetailTitle = message.title;
          this.mDetailContent = message.content;
          this.messageList[key].status = 1;
        }
        this.appPage.getUnreadMessages();
      }
    })
  }
  doOperateAll() {
    if (this.noticeList != null && this.noticeList.length > 0) {
      this.doOperate(this.noticeList[0], -1);
    }
  }
  doReadAll() {
    if (this.messageList != null && this.messageList.length > 0) {
      this.doRead(this.messageList[0], -1);
    }
  }

  sendTitle = '';
  sendContent = '';
  sendUser = 0;
  isShowSendMessage = false;    //是否显示发送私信对话框
  reply(mDetail) {
    this.sendTitle = mDetail.user_name;
    this.sendContent = '';
    this.sendUser = mDetail.user_id;
    this.isShowSendMessage = true;
  }
  send() {
    this.isShowSendMessage = false;
    this.doSend();
  }
  sendCancel() {
    this.isShowSendMessage = false;
  }
  /**
   * 发送私信user/user/sendPrivateMessage
   */
  doSend() {
    if (this.local.get('userinfo') == null) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|sendprivatemessage');
    var api = 'user/user/sendPrivateMessage';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'title': '无标题',
      'content': this.sendContent,
      'accepted_user_id': this.sendUser
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.modalService.success({
          nzTitle: "系统提示",
          nzContent: "私信发送成功",
          nzOkText: "关闭",
          nzOnOk: () => {
            this.sendTitle = '';
            this.sendContent = '';
          },
        })
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: "发送私信失败，请联系管理员",
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getNotices();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getNotices();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getNotices();
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
  }

  handleCancel() {
    this.isShowMessageDetail = false;
  }
}
