import { Component, OnInit } from '@angular/core';
declare var editormd: any;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.less']
})
export class EditorComponent implements OnInit {

    public width = '100%';
    public height = '500';
    public path = '../../../assets/editor.md/lib/';
    public codeFold = true;
    public searchReplace = true;
    public toolbar = true;
    public emoji = true;
    public taskList = false;
    public tex = false;  // 数学公式类默认不解析
    public readOnly = false;
    public tocm = true;
    public watch = true;
    public previewCodeHighlight = true;
    public saveHTMLToTextarea = true;
    public markdown = '';
    public flowChart = false; // 流程图
    public syncScrolling = true;
    public sequenceDiagram = false; // UML时序图
    public imageUpload = true;
    public imageFormats = ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'webp', 'JPG', 'JPEG', 'GIF', 'PNG', 'BMP', 'WEBP'];
    // public imageUploadURL = 'https://admin.pgfans.cn/work/work/upload';  // 上传图片服务器
    public crossDomainUpload = false;
    // public uploadCallbackURL = location.href; // 上传成功后跳转的地址
    public htmlDecode = 'style, script, iframe, sub, sup|on*';
    public editorFunction = ''; // 定义调用的方法
    public placeholder = '';
    public toolbarIcons = function() {
      // Or return editormd.toolbarModes[name]; // full, simple, mini
      // Using "||" set icons align right.
      return ["undo","redo","|","bold","italic","quote","uppercase","lowercase","|","h1","h2","h3","h4","|","list-ul","list-ol","hr","|","link","image","code","code-block","table","|","preview","fullscreen","clear","|","help"]
    };
    public onload = function() {
      this.executePlugin("imagePaste", "../../../assets/editor.md/plugins/image-handle-paste/image-handle-paste");
    }




    constructor() {
    }

  ngOnInit() {
   
  }



}
