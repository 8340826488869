import { Component, OnInit } from '@angular/core';
import { Md5 } from 'ts-md5';
import { HttpService } from './../../../services/http.service';
import { StorageService } from './../../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.less']
})
export class OrderDetailComponent implements OnInit {

  orderDetail:any = [];

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
    private message: NzMessageService,
  ) {
    route.queryParams.subscribe((data) => {
      // console.log(data);
      this.getOrderDetail(data.userId,data.orderId);
      window.scroll(0,0);
    })
   }

  ngOnInit() {
  }

  /**
   * 获取订单信息goods/order/getOrder
   */
  getOrderDetail(uid,oid){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getorder');
    var api = 'goods/order/getOrder';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': uid,
      'order_id': oid,
    };
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.orderDetail = result.data;
        // console.log(this.orderDetail)
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }

  //复制物流单号
  copy() {
    const copyEl = document.querySelector('#number');
    const range = document.createRange();
    range.selectNode(copyEl);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.message.success('已复制', {
      nzDuration: 1500
    });
  }
}
