import { HttpService } from './../../services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Md5 } from 'ts-md5';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mydynamic',
  templateUrl: './mydynamic.component.html',
  styleUrls: ['./mydynamic.component.less']
})
export class MydynamicComponent implements OnInit {
  userId = 0;
  isMe = false;
  userinfo: any = {};   //本地保存的用户登录信息

  dynamicList: any = [];  //获取到的个人动态列表

  page = 1;                   //列表显示第几页
  page_num = 10;              //每页显示的数量
  total: any = [];            //总共几页
  page_prev = 1;
  page_next = 5;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((data) => {
      this.userId = data.userId;
    });
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      if (this.userId == this.local.get('userinfo').id) {
        this.isMe = true;
        this.userinfo = this.local.get('userinfo');
      } else {
        this.isMe = false;
        this.getUserInfo(this.userId);
      }
    } else {
      this.isMe = false;
      this.getUserInfo(this.userId);
    }
  }

  ngOnInit() {
    //获取全部个人动态（分页）user/dynamic/allDynamicList
    this.getDynamicList();
  }

  getUserInfo(id) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
    var api = 'user/user/getNewInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': id,
      'my_user_id': 0,
      'sessionid': 0,
    };
    if (this.local.get('userinfo') == null) {
      body.my_user_id = 0;
    } else {
      body.my_user_id = this.local.get('userinfo').id;
      body.sessionid = this.local.get('userinfo').sessionid;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.userinfo = result.data;
      }
    });
  }
  /**获取个人动态列表 */
  getDynamicList() {
    // console.log("获取分页个人动态");
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|alldynamiclist');
    var api = 'user/dynamic/allDynamicList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'page': this.page,
      'page_num': this.page_num,
    };
    // console.log("body", body);
    // return;
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.dynamicList = result.data;
        this.local.fmDates(this.dynamicList);
        this.total = [];
        for (let i = 0; i < result.page_info.total; i++) {
          this.total.push({ num: i + 1, checked: this.page == i + 1 });
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

 /**跳转到页面 */
 getPageData(num) {
  this.page = num;
  this.updatepage();
  // console.log(this.page);
  this.getDynamicList();
}
/**到上一页 */
doPrevious() {
  this.page--;
  this.updatepage();
  // console.log(this.page);
  this.getDynamicList();
}
/**到下一页 */
doNext() {
  this.page++;
  this.updatepage();
  // console.log(this.page);
  this.getDynamicList();
}
/**更新页面选择器 */
updatepage() {
  window.scrollTo(0, 0);
  for (let i = 0; i < this.total.length; i++) {
    if (this.total[i].num == this.page) {
      this.total[i].checked = 'true';
    } else {
      this.total[i].checked = 'false';
    }
  }
  if (this.page == 1) {
    this.page_prev = this.page;
    this.page_next = this.page + 4;
  } else if (this.page == 2) {
    this.page_prev = this.page - 1;
    this.page_next = this.page + 3;
  } else if (this.page == this.total.length - 1) {
    this.page_prev = this.page - 3;
    this.page_next = this.page + 1;
  } else if (this.page == this.total.length) {
    this.page_prev = this.page - 4;
    this.page_next = this.page;
  } else {
    this.page_prev = this.page - 2;
    this.page_next = this.page + 2;
  }
}
 /**向前5页 */
 prev(num) {
  if (num - 5 < 1) {
    this.page = 1;
  } else {
    this.page = num - 5;
  }
  this.updatepage();
  this.getDynamicList();
}
/**向后5页 */
next(num) {
  if (num + 5 > this.total.length) {
    this.page = this.total.length;
  } else {
    this.page = num + 5;
  }
  this.updatepage();
  this.getDynamicList();
}


}
