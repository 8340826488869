import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { HttpService } from '../../services/http.service';
import { CartService } from '../../services/cart.service';
import { NzModalService } from 'ng-zorro-antd';
import { Router,ActivatedRoute } from '@angular/router';
import { Md5 } from "ts-md5";

@Component({
  selector: 'app-shoppingcart',
  templateUrl: './shoppingcart.component.html',
  styleUrls: ['./shoppingcart.component.less']
})
export class ShoppingcartComponent implements OnInit {
  user_id = '';
  cartGoodsList:any = []; // 购物车商品
  checkGoods:any=[];  // 选中的商品信息
  check_all = false; // 全选按钮
  total = 0;  // 选中商品的pgdou总计


  constructor(
    private local: StorageService,
    private http: HttpService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    private cart: CartService,
  ) {
    route.queryParams.subscribe((data) => {
      this.user_id = data.userId;
      if(this.user_id!=local.get('userinfo').id){
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: '您没有权限访问他人购物车！',
          nzOkText: "关闭",
          nzOnOk: () => {
            window.history.back();
          },
        });
      }
      this.getShoppingCart(this.user_id);
    });
   }

  ngOnInit() {
    
  }

  
  /**
   *我的购物车goods/cart/cartList
   */
  getShoppingCart(id){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|cartlist');
    var api = 'goods/cart/cartList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': id,
    };
    this.http.doPost(api, body, (result) => {
    // console.log(result);
      if (result.code == "200") {
        this.total = 0;
        this.check_all = false;
        this.checkGoods = [];
        if (result.data != null && result.data.length > 0) { 
          this.cartGoodsList = result.data;
          for(let i = 0;i<this.cartGoodsList.length;i++){
            this.cartGoodsList[i].checked = false;
          }
          // console.log(this.cartGoodsList)
        }else{
          this.cartGoodsList = [];
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }


  //更改数量
  changeNum(e,key){
    if(e){
      var cid = this.cartGoodsList[key].id;
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|editcart');
      var api = 'goods/cart/editCart';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'user_id': this.user_id,
        'keytype': 4,
        'cart_id': cid,
        'goods_num':parseInt(e),
      };
      this.http.doPost(api, body, (result) => {
        if (result.code == "200") {
          this.getShoppingCart(this.user_id);
        } else {
          this.modalService.error({
            nzTitle: "系统提示",
            nzContent: result.message,
            nzOkText: "关闭",
            nzOnOk: () => { },
          });
        }
      }); 
    } 
  }
  onblur(num){
    if(num == ''){
      this.getShoppingCart(this.user_id);
    }
  }

  //删除
  delete(id){
    this.modalService.confirm({
      nzTitle: '删除好物',
      nzContent: '确认要删除该好物吗？',
      nzOnOk: () => {
        this.editCart(id,1);
      }
    });  
  }

  /**
    * 购物车操作goods/cart/editCart
    * @param id 购物车id
    */
  editCart(id,keytype){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|editcart');
    var api = 'goods/cart/editCart';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.user_id,
      'keytype': keytype,
      'cart_id': id,
    };
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.getShoppingCart(this.user_id);
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });  
  }

  //选中商品
  checkedSingle(e,key){
    this.checkGoods = [];
    this.total = 0;
    this.cartGoodsList[key].checked = e;
    if (this.cartGoodsList.every(item => item.checked)) {
      this.check_all = true;
    }else{
      this.check_all = false;
    }
    for(let i = 0;i<this.cartGoodsList.length;i++){
      if(this.cartGoodsList[i].checked){
        this.checkGoods.push(this.cartGoodsList[i]);
        this.total = this.total + this.cartGoodsList[i].total;
      }
    }
  }

  //全选
  checkAll(e){
    if(e){
      for(let i = 0;i<this.cartGoodsList.length;i++){
        this.cartGoodsList[i].checked = true;
      }
    }else{
      for(let i = 0;i<this.cartGoodsList.length;i++){
        this.cartGoodsList[i].checked = false;
      }
    }
    this.checkGoods = [];
    this.total = 0;
    for(let i = 0;i<this.cartGoodsList.length;i++){
      if(this.cartGoodsList[i].checked){
        this.checkGoods.push(this.cartGoodsList[i]);
        this.total = this.total + this.cartGoodsList[i].total;
      }
    }
  }

  //删除选中
  deleteCheck(){
    const cids = [];
    for(let i = 0;i<this.checkGoods.length;i++){
      cids[i] = this.checkGoods[i].id;
    }
    if(cids.length>0){
      this.delete(cids);
    }else{
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: '您未选中任何商品！',
        nzOkText: "关闭",
        nzOnOk: () => {},
      });
    }
    
  }

  //结算
  settle(){
    // console.log(this.checkGoods);
    this.cart.setCheckedGoods(this.checkGoods);
    this.router.navigate(['/or-confirm'],{queryParams: {type:2}});
    // this.router.navigate(['/or-confirm'],{queryParams: this.checkGoods});
  }
}
