import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-result-error',
  templateUrl: './result-error.component.html',
  styleUrls: ['./result-error.component.less']
})
export class ResultErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  backorder(){
    window.history.back();
  }

}
