import { HttpService } from './../services/http.service';
import { Md5 } from "ts-md5";
import { Router, NavigationExtras } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../services/storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.less']
})
export class QuestionsComponent implements OnInit {


  question_type_number = 1;   //问答类型：1=最新问答；2=等待回答；3=热门问答

  labelList: any = [];        //所有标签列表
  questionList: any = [];     //所有问题列表
  rankingList: any = [];      //排行榜TOP5
  hotLabelList: any = [];     //热门标签列表

  page = 1;                   //列表显示第几页
  page_num = 20;              //每页显示的数量
  total: any = [];            //总共几页

  page_prev = 1;
  page_next = 5;


  advert = true;
  offsetTop = 0;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,

  ) {
    this.advert = true;

  }
  ngOnInit() {
    //获取所有问题标签列表:question/question_label/allLabelList
    this.getAllLabelList();
    //获取问答列表:question/question/questionList
    this.getQuestionList();
    //获取排行榜top5:user/User/rankingList
    this.getRankingList();
    //获取热门标签:question/question_label/questionHotLabelList
    this.getHotLabelList();
  }

  close(){
    this.advert = false;
  }

  /**
   * 获取所有问题标签列表question/question_label/allLabelList
   */
  getAllLabelList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|questiontwolabellist');
    var api = 'question/question_label/questionTwoLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'parent_id': 0,
    };
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.labelList = result.data;
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }
  /**
   * 获取问答列表question/question/questionList
   */
  getQuestionList() {
    // console.log(this.question_type_number);
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|questionlist');
    var api = 'question/question/questionList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'question_type_number': this.question_type_number,
      'page': this.page,
      'page_num': this.page_num,
    };
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      this.questionList = [];
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.questionList = result.data;
          this.local.fmDates(this.questionList);
          this.total = [];
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
        // console.log(this.total);
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }

  /**
   * 获取排行榜top5
   */
  getRankingList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|adoptlist');
    var api = 'user/notice/adoptList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    };
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
            this.rankingList = result.data;  
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }
  /**
   * 获取热门标签:question/question_label/questionHotLabelList
   */
  getHotLabelList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|questionhotlabellist');
    var api = 'question/question_label/questionHotLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'num': 5
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.hotLabelList = result.data;
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }

  /**
   * 加载数据
   * @param e
   */
  changeTab(e) {
    this.page = 1;
    this.page_prev = 1;
    this.page_next = 5;
    // console.log("e", e[0].index);
    // console.log(this.index);
    this.question_type_number = e[0].index + 1;
    this.getQuestionList();
  }



  /**
   * 跳转到标签详情页面
   * @param label 标签数据
   */
  goLabel(label) {
    // let navigationExtras: NavigationExtras = {
    //   queryParams: { 'id': label.id, },
    // }
    this.router.navigate(['/labels-detail/'+label.id]);
  }


  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getQuestionList();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getQuestionList();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getQuestionList();
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
    if (this.page == 1) {
      this.page_prev = this.page;
      this.page_next = this.page + 4;
    } else if (this.page == 2) {
      this.page_prev = this.page - 1;
      this.page_next = this.page + 3;
    } else if (this.page == this.total.length - 1) {
      this.page_prev = this.page - 3;
      this.page_next = this.page + 1;
    } else if (this.page == this.total.length) {
      this.page_prev = this.page - 4;
      this.page_next = this.page;
    } else {
      this.page_prev = this.page - 2;
      this.page_next = this.page + 2;
    }
  }

  /**向前5页 */
  prev(num) {
    if (num - 5 < 1) {
      this.page = 1;
    } else {
      this.page = num - 5;
    }
    this.updatepage();
    this.getQuestionList();
  }
  /**向后5页 */
  next(num) {
    if (num + 5 > this.total.length) {
      this.page = this.total.length;
    } else {
      this.page = num + 5;
    }
    this.updatepage();
    this.getQuestionList();
  }
  /**
   * 跳转到用户主页
   * @param user 用户信息，主要是要用到主键id
   */
  goUserHome(user) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'userId': user.user_id, },
    }
    this.router.navigate(['/user/home'], navigationExtras);
  }


  /**点击显示所有标签出发时间 */
  showAll() {
    this.router.navigate(['/labels']);
  }
}
