
import { MyconcernComponent } from './user/myconcern/myconcern.component';
import { EditComponent } from './questions/edit/edit.component';
import { EditArticleComponent } from './blogs/edit-article/edit-article.component';
import { SearchComponent } from './search/search.component';
import { ForgetComponent } from './forget/forget.component';
import { MydynamicComponent } from './user/mydynamic/mydynamic.component';
import { MyaskComponent } from './user/myask/myask.component';
import { ADetailComponent } from './activity/a-detail/a-detail.component';
import { ActivityComponent } from './activity/activity.component';
import { MyjwComponent } from './user/myjw/myjw.component';
import { RecruitDetailComponent } from './recruit/recruit-detail/recruit-detail.component';
import { JwDetailComponent } from './recruit/jw-detail/jw-detail.component';
import { WriteJwComponent } from './recruit/write-jw/write-jw.component';
import { RecruitComponent } from './recruit/recruit.component';
import { MyanswerComponent } from './user/myanswer/myanswer.component';
import { MyfocusComponent } from './user/myfocus/myfocus.component';
import { MydataComponent } from './user/mydata/mydata.component';
import { MycollectComponent } from './user/mycollect/mycollect.component';
import { MyarticleComponent } from './user/myarticle/myarticle.component';
import { MyhomeComponent } from './user/myhome/myhome.component';
import { MybeansComponent } from './user/mybeans/mybeans.component';
import { MygoodsComponent } from './user/mygoods/mygoods.component';
import { MessagesComponent } from './messages/messages.component';
import { LoginComponent } from './login/login.component';
import { LArticleComponent } from './labels/l-article/l-article.component';
import { BWriteArticleComponent } from './blogs/b-write-article/b-write-article.component';
import { BArticleDetailComponent } from './blogs/b-article-detail/b-article-detail.component';
import { TagComponent } from './blogs/tag/tag.component';
import { AskComponent } from './questions/ask/ask.component';
import { DetailComponent } from './questions/detail/detail.component';
import { QuestionsComponent } from './questions/questions.component';
import { BlogsComponent } from './blogs/blogs.component';
import { LabelsComponent } from './labels/labels.component';
import { EventsComponent } from './events/events.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './register/register.component';
import { BeanRuleComponent } from './beans/bean-rule/bean-rule.component';
import { ShoplistComponent } from './shop/shoplist/shoplist.component';
import { ShopdetailComponent } from './shop/shopdetail/shopdetail.component';
import { ShoppingcartComponent } from './shop/shoppingcart/shoppingcart.component'
import { FeedbackComponent } from './feedback/feedback.component';
import { OrderConfirmComponent } from './shop/order-confirm/order-confirm.component';
import { ResultSuccessComponent } from './shop/result-success/result-success.component';
import { ResultErrorComponent } from './shop/result-error/result-error.component';
import { OrderDetailComponent } from './user/mygoods/order-detail/order-detail.component';
import { RankingComponent } from './beans/ranking/ranking.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DatabaseComponent } from './database/database.component';
import { QuestionBankComponent } from './database/question-bank/question-bank.component';
import { ExambankComponent } from './database/exambank/exambank.component';
import { BanklistComponent } from './database/question-bank/banklist/banklist.component';
import { ManualComponent } from './database/manual/manual.component';
import { ActivityMeansComponent } from './database/activity-means/activity-means.component';
import { BookComponent } from './database/book/book.component';
import { CourseComponent } from './database/course/course.component';
import { CooperateComponent } from './cooperate/cooperate.component';
import { OrgRegisterComponent } from './org-register/org-register.component';
import { ClauseComponent } from './clause/clause.component';
import { AllBlogsComponent } from './blogs/all-blogs/all-blogs.component';
import { TryManualComponent } from './database/manual/try-manual/try-manual.component';


const routes: Routes = [
  { path: 'login', component:LoginComponent },
  { path: 'register', component:RegisterComponent },
  { path: 'forget', component:ForgetComponent },
  { path: 'questions', component: QuestionsComponent},
  { path: 'blogs', component: BlogsComponent},
  { path: 'blogs-tag/:id', component: TagComponent, },
  { path: 'a/:id', component: BArticleDetailComponent },
  { path: 'blogs-write', component: BWriteArticleComponent, },
  { path: 'blogs-edit', component: EditArticleComponent, },
  { path: 'labels', component: LabelsComponent, },
  { path: 'labels-detail/:id', component: LArticleComponent, },
  { path: 'events', component: EventsComponent, },
  { path: 'recruit', component: RecruitComponent, },
  { path: 'jw-detail', component: JwDetailComponent },
  { path: 'write-jw', component: WriteJwComponent },
  { path: 'r-detail/:id', component: RecruitDetailComponent },
  {
    path: 'user', component: UserComponent, children: [
      { path: 'home', component: MyhomeComponent },
      { path: 'article', component: MyarticleComponent },
      { path: 'collect', component: MycollectComponent },
      { path: 'data', component: MydataComponent },
      { path: 'focus', component: MyfocusComponent },
      { path: 'concern', component: MyconcernComponent },
      { path: 'answer', component: MyanswerComponent },
      { path: 'ask', component: MyaskComponent },
      { path: 'jw', component: MyjwComponent },
      { path: 'dynamic', component: MydynamicComponent },
      { path: 'mybeans', component: MybeansComponent },
      { path: 'mygoods', component: MygoodsComponent },
      { path: 'or-detail', component: OrderDetailComponent },
    ]
  },
  { path: 'q/:id', component: DetailComponent, },
  { path: 'q-edit', component: EditComponent, },
  { path: 'ask', component: AskComponent, },
  { path: 'messages', component: MessagesComponent, },
  { path: 'activity', component: ActivityComponent, },
  { path: 'a-detail/:id', component: ADetailComponent, },
  { path: 'search', component: SearchComponent, },
  { path: 'bean-rule', component: BeanRuleComponent, },
  { path: 'shop', component: ShoplistComponent,},
  { path: 'g-detail', component: ShopdetailComponent,},
  { path: 'feedback', component: FeedbackComponent,},
  { path: 'cart', component: ShoppingcartComponent,},
  { path: 'or-confirm', component: OrderConfirmComponent,},
  { path: 're-success', component: ResultSuccessComponent,},
  { path: 're-error', component: ResultErrorComponent,},
  { path: 'ranking', component: RankingComponent,},
  { path: 'exam_bank', component: ExambankComponent },
  { path: 'database', component: DatabaseComponent,children: [
    { path: 'manual', component: ManualComponent },
    { path: 'means', component: ActivityMeansComponent },
    { path: 'books', component: BookComponent },
    { path: 'course', component: CourseComponent },
    { path: 'q_bank', component: QuestionBankComponent },
    { path: 'banklist', component: BanklistComponent },
    
  ]},
  { path: 'cooperate', component: CooperateComponent },
  { path: 'org', component: OrgRegisterComponent },
  { path: 'clause', component: ClauseComponent},
  { path: 'allblogs', component: AllBlogsComponent },
  { path: 'tryManual', component: TryManualComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)], //, {initialNavigation: 'enabled'}
  exports: [RouterModule]
})
export class AppRoutingModule { }
