import { HttpService } from '../../services/http.service';
import { Md5 } from "ts-md5";
import { NzModalService } from 'ng-zorro-antd';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router,  ActivatedRoute } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import { Component,OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.less']
})
export class ManualComponent implements OnInit {

  manual_name = 1; 
  manual_type = 0;
  userinfo:any = [];
  oneManual: any = []; //一级手册列表
  twoManual: any = []; //二级手册列表
  manualList: any = []; //手册内容

  page = 1;                   //列表显示第几页
  page_num = 15;              //每页显示的数量
  total: any = [];            //总共几页
  page_prev = 1;
  page_next = 5;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
    private message: NzMessageService,
  ) { 
    this.getOneManual();
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userinfo = this.local.get('userinfo');
    }
  }

  ngOnInit() {  
    this.getTwoManual(this.manual_name);
    this.getManual(this.manual_name,this.manual_type);
  }

  //获取一级手册manual/manual/oneClassifyList
  getOneManual(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|oneclassifylist');
    var api = 'manual/manual/oneClassifyList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result)
      if (result.code == "200") {
        this.oneManual = result.data;
      }
    })
  }

  //获取二级手册manual/manual/twoClassifyList
  getTwoManual(index){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|twoclassifylist');
    var api = 'manual/manual/twoClassifyList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'parent_id': index,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result)
      if (result.code == "200") {
        this.twoManual = result.data;
      }
    })
  }

  //获取手册内容manual/manual/manualList
  getManual(name,type){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|manuallist');
    var api = 'manual/manual/manualList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'category_id': name,
      'parent_id': type,
      'page': this.page,
      'page_num': this.page_num,
      'user_id': 0,
    }
    if(this.local.get('userinfo')!=null&&this.local.get('userinfo').id>0){
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
      console.log(result)
      if (result.code == "200") {
        // this.manualList = result.data;
        this.total = [];
        if (result.data != null && result.data.length > 0) {
          this.manualList = result.data;
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      }
    })
  }

  //一级手册
  changeName(index){
    this.manual_name = index;
    this.manual_type = 0;
    this.getTwoManual(index);
    this.getManual(this.manual_name,this.manual_type);
  }
  //二级手册
  changeType(index){
    this.manual_type = index;
    this.getManual(this.manual_name,this.manual_type);
  }

  async goDownload(manual){
    if(this.local.get('userinfo')==null||this.local.get('userinfo').id<0){
      this.router.navigate(['/login']);
    }else{
      if(manual.pgdou>0){
        if(manual.ispay==1){
          if(manual.file_types=='pdf'){
            this.downloadBlob(manual.id,manual.file_name);
            this.addCount(manual.id,manual.file_name);
          }else{
            this.Download(manual.file_url,manual.file_name);
            this.addCount(manual.id,manual.file_name)
          }
          return;
        }
         //获取PG豆
        let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
        var api = 'user/user/getNewInfo';
        var body = {
          'timestamp': new Date().getTime().toString().substr(0, 10),
          'signature': signature,
          'user_id': this.userinfo.id,
          'sessionid':this.userinfo.sessionid,
        }
        this.http.doPost(api, body, (result) => {
          if (result.code == "200") {
            this.userinfo = result.data;
            if(this.userinfo.pgdou>=manual.pgdou){
              this.modalService.confirm({
                nzTitle: "您确认用"+manual.pgdou+"PG豆兑换该文档吗？",
                nzContent: "您当前PG豆："+this.userinfo.pgdou,
                nzOnOk: () => {
                  if(manual.file_types=='pdf'){
                    this.downloadBlob(manual.id,manual.file_name);
                    this.addCount(manual.id,manual.file_name);
                    this.doDownload(manual.id);
                  }else{
                    this.Download(manual.file_url,manual.file_name);
                    this.addCount(manual.id,manual.file_name);
                    this.doDownload(manual.id);
                  }
                },
              })
            }else{
              this.modalService.error({
                nzTitle: "系统提示",
                nzContent: "您的PG豆不足！",
                nzOkText: "赚取PG豆",
                nzOnOk: () => {
                  this.router.navigate(['/bean-rule']);
                },
              })
            }
          }
        })
      }else{
        if(manual.file_types=='pdf'){
          this.downloadBlob(manual.id,manual.file_name);
          this.addCount(manual.id,manual.file_name);
        }else{
          this.Download(manual.file_url,manual.file_name);
          this.addCount(manual.id,manual.file_name)
        }
      }
       
    }
  }

  //下载非pdf文件
  Download(url,name){
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // 下载pdf二进制流文件
  downloadBlob(id,name){
    const msg = this.message.loading('准备下载...', { nzDuration: 0 }).messageId;
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getdownload');
    var api = 'user/pgdou/getDownload';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'id': id,
      'type':1,
    }
    this.http.postBlob(api, body,(result)=>{
      const blob = new Blob([result], {type: 'application/pdf'});
      var test = setTimeout(() =>{
        if(blob!=null){
          // console.log('获取到内容啦');
          this.message.remove(msg);
          clearTimeout(test);
        }
      }, 1000)

      saveAs(blob,name)
    })
  }

   //下载减PG豆
  doDownload(id){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|downloaddata');
    var api = 'user/pgdou/downloadData';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id':this.userinfo.id,
      'id': id,
      'type':2,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result.data)
    })
  }

  //下载增加下载量
  addCount(id,title){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|downloadadd');
    var api = 'user/pgdou/downloadAdd';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id':this.userinfo.id,
      'id': id,
      'type':1,
      'title':title,
    }
    // console.log('body',body)
    this.http.doPost(api, body, (result) => {
      // console.log(result)
      if(result.code=='200'){
        this.getManual(this.manual_name,this.manual_type);
      }
    })
  }

  //试看
  // trySee(){
    // const msg = this.message.loading('正在打开文件...', { nzDuration: 0 }).messageId;
    // let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getdownload');
    // var api = 'user/pgdou/getDownload';
    // var body = {
    //   'timestamp': new Date().getTime().toString().substr(0, 10),
    //   'signature': signature,
    //   'id': manual.id,
    //   'type':1,
    // }
    // this.http.postBlob(api, body,(result)=>{
    //   const blob = new Blob([result], {type: 'application/pdf'});
    //   var test = setTimeout(() =>{
    //     if(blob!=null){
    //       // console.log('获取到内容啦');
    //       this.message.remove(msg);
    //       clearTimeout(test);
    //     }
    //   }, 1000)
    //   var reader = new FileReader()
		// 		reader.readAsArrayBuffer(blob)
		// 		reader.onload = function(e) {
		// 			const buffer = e.target.result // 此时是arraybuffer类型
		// 			mammoth.convertToHtml({ arrayBuffer: buffer }).then((result) => {
		// 				this.vHtml = result.value
		// 			}).done()
		// 		}
    // })
  // }

  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getManual(this.manual_name,this.manual_type);
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getManual(this.manual_name,this.manual_type);
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getManual(this.manual_name,this.manual_type);
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
    if (this.page == 1) {
      this.page_prev = this.page;
      this.page_next = this.page + 4;
    } else if (this.page == 2) {
      this.page_prev = this.page - 1;
      this.page_next = this.page + 3;
    } else if (this.page == this.total.length - 1) {
      this.page_prev = this.page - 3;
      this.page_next = this.page + 1;
    } else if (this.page == this.total.length) {
      this.page_prev = this.page - 4;
      this.page_next = this.page;
    } else {
      this.page_prev = this.page - 2;
      this.page_next = this.page + 2;
    }
  }
   /**向前5页 */
   prev(num) {
    if (num - 5 < 1) {
      this.page = 1;
    } else {
      this.page = num - 5;
    }
    this.updatepage();
    this.getManual(this.manual_name,this.manual_type);
  }
  /**向后5页 */
  next(num) {
    if (num + 5 > this.total.length) {
      this.page = this.total.length;
    } else {
      this.page = num + 5;
    }
    this.updatepage();
    this.getManual(this.manual_name,this.manual_type);
  }


}
