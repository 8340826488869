import { HttpService } from '../../services/http.service';
import { Md5 } from "ts-md5";
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Router,  ActivatedRoute } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import { Component,OnInit } from '@angular/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-activity-means',
  templateUrl: './activity-means.component.html',
  styleUrls: ['./activity-means.component.less']
})
export class ActivityMeansComponent implements OnInit {
  userinfo:any = [];
  means_name = 0;
  means_type = 0;
  means_time = 0;

  name:any = [];
  time:any = [];
  type:any = [];

  activityList:any = [];

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
    private message: NzMessageService,
  ) { 
    this.getMeansName();
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userinfo = this.local.get('userinfo');
    }
    
  }

  ngOnInit() {
  }

  /**
   * 获取活动名称
   */
  getMeansName(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|activitylist');
    var api = 'activitydata/activity/activityList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.name = result.data; 
        this.means_name = this.name[0].id;
        this.getMeansTime();
      }
    })
  }
  /**
   * 获取活动时间
   */
  getMeansTime(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|yearlist');
    var api = 'activitydata/activity/yearList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'parent_id': this.means_name,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result)
      if (result.code == "200") {
        this.time = result.data;
        this.means_time = this.time[0].year;
        this.getMeansFormat();
      }
    })
  }
  /**
   * 获取活动格式
   */
  getMeansFormat(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|formatlist');
    var api = 'activitydata/activity/formatList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'activity_id': this.means_name,
      'year': this.means_time,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result)
      if (result.code == "200") {
        this.type = result.data;
        this. getMeansList();
      }
    })
  }

  /**
   * 获取活动资料
   */
  getMeansList(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|activitydatalist');
    var api = 'activitydata/activity/activityDataList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'activity_id': this.means_name,
      'year': this.means_time,
      'type': this.means_type + 1,
      'user_id': 0,
    }
    if(this.local.get('userinfo')!=null&&this.local.get('userinfo').id>0){
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
      console.log(result)
      if (result.code == "200") {
        this.activityList = result.data;
      }
    })
  }

  goDownload(list){
    if(this.local.get('userinfo')==null||this.local.get('userinfo').id<0){
      this.router.navigate(['/login']);
    }else{
      if(list.file_type==1){
        if(list.pgdou>0){
          if(list.ispay==1){
            if(list.file_types=='pdf'){
              this.downloadBlob(list.id,list.file_name);
              this.addCount(list.id,list.file_name);
            }else{
              this.Download(list.file_url,list.file_name);
              this.addCount(list.id,list.file_name);
            }
            return;

          }
           //获取PG豆
          let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
          var api = 'user/user/getNewInfo';
          var body = {
            'timestamp': new Date().getTime().toString().substr(0, 10),
            'signature': signature,
            'user_id': this.userinfo.id,
            'sessionid':this.userinfo.sessionid,
          }
          this.http.doPost(api, body, (result) => {
            if (result.code == "200") {
              this.userinfo = result.data;
              if(this.userinfo.pgdou>=list.pgdou){
                this.modalService.confirm({
                  nzTitle: "您确认用"+list.pgdou+"PG豆兑换该文档吗？",
                  nzContent: "您当前PG豆："+this.userinfo.pgdou,
                  nzOnOk: () => {
                    if(list.file_types=='pdf'){
                      this.downloadBlob(list.id,list.file_name);
                      this.addCount(list.id,list.file_name);
                      this.doDownload(list.id);
                    }else{
                      this.Download(list.file_url,list.file_name);
                      this.addCount(list.id,list.file_name);
                      this.doDownload(list.id);
                    } 
                  },
                })
              }else{
                this.modalService.error({
                  nzTitle: "系统提示",
                  nzContent: "您的PG豆不足！",
                  nzOkText: "赚取PG豆",
                  nzOnOk: () => {
                    this.router.navigate(['/bean-rule']);
                  },
                })
              }
            }
          })
        }else{
          if(list.file_types=='pdf'){
            this.downloadBlob(list.id,list.file_name);
            this.addCount(list.id,list.file_name);
          }else{
            this.Download(list.file_url,list.file_name);
            this.addCount(list.id,list.file_name)
          }
        }
      }else{
        if(list.file_code!=''){
          this.modalService.confirm({
            nzTitle: '您即将跳转到百度网盘',
            nzContent: '提取码：'+list.file_code,
            nzOkText: '确定',
            nzOnOk: () => {
              window.open(list.file_url,'_blank');
              this.addCount(list.id,list.file_name)
            },
            nzCancelText: '取消',
          });
        }else{
          window.open(list.file_url,'_blank');
          this.addCount(list.id,list.file_name)
        }

      }
    }
  }

  //下载非pdf文件
  Download(url,name){
    console.log(url,name)
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // 下载pdf二进制流文件
  downloadBlob(id,name){
    const msg = this.message.loading('准备下载...', { nzDuration: 0 }).messageId;
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getdownload');
    var api = 'user/pgdou/getDownload';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'id': id,
      'type':1,
    }
    this.http.postBlob(api, body,(result)=>{
      const blob = new Blob([result], {type: 'application/pdf'});
      var test = setTimeout(() =>{
        if(blob!=null){
          // console.log('获取到内容啦');
          this.message.remove(msg);
          clearTimeout(test);
        }
      }, 1000)

      saveAs(blob,name)
    })
  }

   //下载减PG豆
  doDownload(id){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|downloaddata');
    var api = 'user/pgdou/downloadData';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id':this.userinfo.id,
      'id': id,
      'type':1,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result.data)
    })
  }

  //下载增加下载量
  addCount(id,title){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|downloadadd');
    var api = 'user/pgdou/downloadAdd';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id':this.userinfo.id,
      'id': id,
      'type':2,
      'title':title,
    }
    // console.log('body',body)
    this.http.doPost(api, body, (result) => {
      console.log(result)
      if(result.code=='200'){
        this.getMeansList();
      }
    })
  }


  changeName(index){
    this.means_name = index;
    // this.getMeansList();
    this.getMeansTime()
  }
  changeType(index){
    this.means_type = index;
    this.getMeansList();
  }
  changeTime(index){
    this.means_time = index;
    this.getMeansList();
  }
}
