import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /***********************************************
   * api请求地址
   * PG Fans：    https://admin.pgfans.cn/
   **********************************************/
  // public apiUrl = "http://localhost:8080/";
  // public apiUrl = "https://admin.pgfans.cn/";
  public apiUrl = "https://admin.pgfans.cn/";
  constructor() { }
}
