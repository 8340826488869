import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { CookieService } from "ngx-cookie-service";
import { RouteReuseStrategy } from "@angular/router";
import { ReuseService } from "./services/route-reuse.service";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';
import { NzListModule } from 'ng-zorro-antd/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { HttpClientModule } from '@angular/common/http';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { QRCodeModule } from 'angular2-qrcode';
import zh from '@angular/common/locales/zh';
import { RegisterComponent } from './register/register.component';
import { QuestionsComponent } from './questions/questions.component';
import { BlogsComponent } from './blogs/blogs.component';
import { LabelsComponent } from './labels/labels.component';
import { EventsComponent } from './events/events.component';
import { UserComponent } from './user/user.component';
import { DetailComponent } from './questions/detail/detail.component';
import { QuillModule } from 'ngx-quill';
import { AskComponent } from './questions/ask/ask.component';
import { TagComponent } from './blogs/tag/tag.component';
import { LArticleComponent } from './labels/l-article/l-article.component';
import { BArticleDetailComponent } from './blogs/b-article-detail/b-article-detail.component';
import { BWriteArticleComponent } from './blogs/b-write-article/b-write-article.component';
import { LoginComponent } from './login/login.component';
import { MessagesComponent } from './messages/messages.component';
import { MyhomeComponent } from './user/myhome/myhome.component';
import { MyarticleComponent } from './user/myarticle/myarticle.component';
import { MyanswerComponent } from './user/myanswer/myanswer.component';
import { MyfocusComponent } from './user/myfocus/myfocus.component';
import { MycollectComponent } from './user/mycollect/mycollect.component';
import { MydataComponent } from './user/mydata/mydata.component';
import { MybeansComponent } from './user/mybeans/mybeans.component';
import { RecruitComponent } from './recruit/recruit.component';
import { JwDetailComponent } from './recruit/jw-detail/jw-detail.component';
import { WriteJwComponent } from './recruit/write-jw/write-jw.component';
import { RecruitDetailComponent } from './recruit/recruit-detail/recruit-detail.component';
import { MyjwComponent } from './user/myjw/myjw.component';
import { ActivityComponent } from './activity/activity.component';
import { ADetailComponent } from './activity/a-detail/a-detail.component';
import { BeanRuleComponent } from './beans/bean-rule/bean-rule.component';
import { EditorComponent } from './editormd/editor/editor.component';
import { ShoplistComponent } from './shop/shoplist/shoplist.component';
import { ShopdetailComponent } from './shop/shopdetail/shopdetail.component';
import { ShoppingcartComponent } from './shop/shoppingcart/shoppingcart.component';
import { OrderConfirmComponent } from './shop/order-confirm/order-confirm.component';
import { ResultSuccessComponent } from './shop/result-success/result-success.component';
import { ResultErrorComponent } from './shop/result-error/result-error.component';
import { MygoodsComponent } from './user/mygoods/mygoods.component';
import { OrderDetailComponent } from './user/mygoods/order-detail/order-detail.component';
import { RankingComponent } from './beans/ranking/ranking.component';
//import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import { FileUploadModule } from 'ng2-file-upload';

import { BaiduMapModule } from 'angular2-baidu-map';
import { MyaskComponent } from './user/myask/myask.component';
import { MydynamicComponent } from './user/mydynamic/mydynamic.component';
import { ForgetComponent } from './forget/forget.component';
import { SearchComponent } from './search/search.component';
import { EditArticleComponent } from './blogs/edit-article/edit-article.component';
import { EditComponent } from './questions/edit/edit.component';
import { MyconcernComponent } from './user/myconcern/myconcern.component';
import { ClauseComponent } from './clause/clause.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { DatabaseComponent } from './database/database.component';
import { QuestionBankComponent } from './database/question-bank/question-bank.component';
import { BookComponent } from './database/book/book.component';
import { ExambankComponent } from './database/exambank/exambank.component';
import { BanklistComponent } from './database/question-bank/banklist/banklist.component';
import { ManualComponent } from './database/manual/manual.component';
import { ActivityMeansComponent } from './database/activity-means/activity-means.component';
import { CourseComponent } from './database/course/course.component';
import { CooperateComponent } from './cooperate/cooperate.component';
import { OrgRegisterComponent } from './org-register/org-register.component';
import { AllBlogsComponent } from './blogs/all-blogs/all-blogs.component';
import { TryManualComponent } from './database/manual/try-manual/try-manual.component';

registerLocaleData(zh);

@NgModule({
  declarations: [							
    AppComponent,
    RegisterComponent,
    QuestionsComponent,
    BlogsComponent,
    LabelsComponent,
    EventsComponent,
    UserComponent,
    DetailComponent,
    AskComponent,
    TagComponent,
    LArticleComponent,
    BArticleDetailComponent,
    BWriteArticleComponent,
    LoginComponent,
    MessagesComponent,
    MyhomeComponent,
    MyarticleComponent,
    MyanswerComponent,
    MyfocusComponent,
    MycollectComponent,
    MydataComponent,
    MybeansComponent,
    RecruitComponent,
    JwDetailComponent,
    RecruitDetailComponent,
    MyjwComponent,
    ActivityComponent,
    ADetailComponent,
    MyaskComponent,
    MydynamicComponent,
    ForgetComponent,
    SearchComponent,
    EditArticleComponent,
    EditComponent,
    MyconcernComponent,
    ClauseComponent,
    WriteJwComponent,
    BeanRuleComponent,
    EditorComponent,
    ShoplistComponent,
    ShopdetailComponent,
    ShoppingcartComponent,
    FeedbackComponent,
    OrderConfirmComponent,
    ResultSuccessComponent,
    ResultErrorComponent,
    MygoodsComponent,
    OrderDetailComponent,
    RankingComponent,
    DatabaseComponent,
    QuestionBankComponent,
    BookComponent,
    ExambankComponent,
    BanklistComponent,
    ManualComponent,
    ActivityMeansComponent,
    CourseComponent,
    CooperateComponent,
    OrgRegisterComponent,
    AllBlogsComponent,
    TryManualComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NzListModule,
    FormsModule,
    NzCarouselModule,
    NzRadioModule,
    NzToolTipModule,
    NzTableModule,
    NzFormModule,
    NzPopoverModule,
    ReactiveFormsModule,
    HttpClientModule,
    NzAutocompleteModule,
    NzSelectModule,
    BrowserAnimationsModule,
    QRCodeModule,
    NgZorroAntdModule,
    QuillModule.forRoot(),
    BaiduMapModule.forRoot({ ak: 'gZLbOobyjhzA3CWykqeDsVZqvzpGP82m' }),
    // FileUploadModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    { provide: RouteReuseStrategy, useClass: ReuseService},
    // CookieService
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
  bootstrap: [AppComponent],
})
export class AppModule {
 }
