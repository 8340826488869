import { HttpService } from 'src/app/services/http.service';
import { Md5 } from "ts-md5";
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from 'src/app/services/storage.service';
import { Component, OnInit } from '@angular/core';
import { MapOptions } from 'angular2-baidu-map';
import { Title,Meta } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-a-detail',
  templateUrl: './a-detail.component.html',
  styleUrls: ['./a-detail.component.less']
})
export class ADetailComponent implements OnInit {

  userid = 0;
  appoint = 0;  
  activityDetail: any = {};     //获取到的活动详情
  aid = 0;
  is_open = 1;  // 0：立即报名；1：报名结束;2:报名未开始；
  is_open_live = 0;  //0:预约直播；1：直播未开始；2：进入直播；3：直播已结束
  timer: any;

  options: MapOptions;      //地图配置信息
  markers: any = {};
  weekDay: any = ['零', '一', '二', '三', '四', '五', '六', '七'];

  advert = true;  //广告
  offsetTop = 0;

  appoint_disalog = false; //预约直播后填写信息
  appointForm!: FormGroup;
  overDate: any = {};

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
    private titleService: Title,
    private fb: FormBuilder,
    private message: NzMessageService,
    private meta: Meta,
  ) {
    if(this.local.get('userinfo')!=null&&this.local.get('userinfo').id>0){
      this.userid = this.local.get('userinfo').id;
    }
    // route.queryParams.subscribe((data) => {
    //   this.aid = data.id;
    //   this.appoint = data.appoint;
    //   this.getActivityDetail(); 
    // });
    route.params.subscribe((data)=>{
      this.aid = data.id;
      this.getActivityDetail(); 
    })
    this.advert = true;
    
    
  }

  ngOnInit() {
    this.options = {
      centerAndZoom: {
        lng: 120.330452,
        lat: 30.317953,
        zoom: 15
      },
      enableKeyboard: true
    }

    this.appointForm = this.fb.group({
      name: [null, [Validators.required]],
      company: [null, [Validators.required]],
      position: [null, [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
    this.titleService.setTitle('PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
  }

  close(){
    this.advert = false;
  }

 
//预约直播
  submitAppoint(){
    for (const i in this.appointForm.controls) {
      this.appointForm.controls[i].markAsDirty();
      this.appointForm.controls[i].updateValueAndValidity();
    }
    if(this.appointForm.status == 'VALID'){
      // console.log(this.appointForm.value)
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|signup');
      var api = 'activity/activity/signup';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'user_id': this.userid,
        'act_id': this.aid,
        'name': this.appointForm.value.name,
        'company': this.appointForm.value.company,
        'position': this.appointForm.value.position,
      }
      // console.log("body:",body)
      this.http.doPost(api, body, (result) => {
        if (result.code == "200") {
          this.appoint_disalog = false;
          this.message.success('预约成功！您可在直播开始当天进入此页面观看直播。');
          this.getActivityDetail();
        }
      });
    }

  }

  getActivityDetail() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|activitydetail');
    var api = 'activity/activity/activityDetail';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'activity_id': this.aid,
      'user_id': this.userid,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result.data.content);
      if (result.code == "200") {
        if (result.data != null) {
          this.titleService.setTitle(result.data.title+'-PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
          this.activityDetail = result.data;
          $('#a_html').html(this.activityDetail.content)
          this.meta.updateTag({ name: 'description', content: $('#a_html').text().substring(0,120)});
          // this.meta.updateTag({ name: 'keywords', content: words })
          //console.log("adweek:", new Date(Number(this.activityDetail.start_time + '000')).getDay());
          var startday = new Date(Number(this.activityDetail.start_time + '000')).getDay();
          var endday = new Date(Number(this.activityDetail.end_time + '000')).getDay();
          this.activityDetail.startday = this.weekDay[startday];
          this.activityDetail.endday = this.weekDay[endday];
          var startDate = this.activityDetail.enroll_start_time; // 报名开始时间
          var endDate = this.activityDetail.enroll_end_time; // 报名结束时间
          this.is_open = this.activityDetail.is_open;
          var nowTime = new Date().getTime();
          var startTime = Number(startDate + '000');
          var endTime = Number(endDate + '000');
          if (nowTime < startTime) {  //未开始
            this.is_open = 2;
          } else if (nowTime > endTime) {  //已结束
            this.is_open = 1;
          } else {                         //正在进行
            this.is_open = 0;
          }
         
          if(this.activityDetail.is_signup == 1){
            this.appoint_disalog = false;
            if (nowTime < startTime) {  //预约未开始
              this.is_open_live = 1;
            } else if (nowTime > endTime) {  //预约已结束，
              if(Number(this.activityDetail.start_time + '000')>nowTime){  //开始时间>当前时间，直播未开始
                this.is_open_live = 1;
              }else if(nowTime > Number(this.activityDetail.end_time + '000')){ //当前时间>结束时间,直播已结束
                this.is_open_live = 3;
              }else{
                this.is_open_live = 2;
              } 
            } else {                         //预约正在进行
              this.is_open_live = 0;
            }
          }else if(this.activityDetail.is_signup == 2&&this.appoint == 1){
            if (nowTime < startTime) {  //预约未开始
              this.appoint_disalog = true;
              this.is_open_live = 0;
            } else if (nowTime > endTime) {  //预约已结束，
              if(Number(this.activityDetail.start_time + '000')>nowTime){  //开始时间>当前时间，直播未开始
                this.is_open_live = 1;
              }else if(nowTime > Number(this.activityDetail.end_time + '000')){ //当前时间>结束时间,直播已结束
                this.is_open_live = 3;
              }else{
                this.is_open_live = 2;
              } 
            } else {                         //预约正在进行
              this.is_open_live = 0;
            }
          }else if(this.activityDetail.is_signup == 2){
            if (nowTime < startTime) {  //预约未开始
              this.is_open_live = 0;
            } else if (nowTime > endTime) {  //预约已结束，
              if(Number(this.activityDetail.start_time + '000')>nowTime){  //开始时间>当前时间，直播未开始
                this.is_open_live = 1;
              }else if(nowTime > Number(this.activityDetail.end_time + '000')){ //当前时间>结束时间,直播已结束
                this.is_open_live = 3;
              }else{
                this.is_open_live = 2;
              } 
            } else {                         //预约正在进行
              this.is_open_live = 0;
            }
          }

          if (this.timer) {
            clearTimeout(this.timer);
          }
          this.timer = setInterval(() => {
            this.fmDates();
          }, 1000);
          window.scrollTo(0, 0);
          var start = result.data.location.indexOf(',');//获得字符串的开始位置
          var map1 = result.data.location.substring(0, start, start);//截取字符串
          var map2 = result.data.location.substring(start + 1);//截取字符串
          this.options = {
            centerAndZoom: {
              lng: Number(map1),
              lat: Number(map2),
              zoom: 15
            },
            enableKeyboard: true
          };
          this.markers = {
              point: {
                lng: Number(map1),   // 经度
                lat: Number(map2),    // 纬度
              }
          };
        }
      }
    })
  }
  fmDates() {
    var nowTime = new Date().getTime();
    var dataTime = 0;
    dataTime = Number(this.activityDetail.enroll_end_time + '000');
    var timediff = Math.round((dataTime - nowTime) / 1000);
    this.overDate.d = parseInt((timediff / 3600 / 24).toString());
    this.overDate.h = parseInt((timediff / 3600 % 24).toString());
    this.overDate.m = parseInt((timediff / 60 % 60).toString());
    this.overDate.s = timediff % 60;
  }

  goMap() {
    console.log("location", this.activityDetail.location);
    console.log("activityDetail", this.activityDetail);
    var start = this.activityDetail.location.indexOf(',');//获得字符串的开始位置
    var lng = this.activityDetail.location.substring(0, start, start);//截取字符串
    var lat = this.activityDetail.location.substring(start + 1);//截取字符串
    window.open("http://api.map.baidu.com/marker?location="
      + lat + "," + lng
      + "&title=地址&content="
      + this.activityDetail.address + "&output=html",
      '_blank');
  }

  // 预约直播
  appointment(){
    if(this.local.get('userinfo')!=null&&this.local.get('userinfo').id>0){
      this.appoint_disalog = true;
    }else{
      this.router.navigate(['/login'],{queryParams:{from:'appoint',a_id:this.aid}});
    }
  }

  handleCancel(){
    this.appoint_disalog = false;
  }

  copy(){
    var oInput = document.createElement('label');
    oInput.innerHTML = '123445';
    oInput.id = 'cpInput';
    document.body.appendChild(oInput);
    var range = document.createRange();
    range.selectNode(document.getElementById('cpInput'));
    var selection = window.getSelection();
    if (selection.rangeCount > 0) {
        selection.removeAllRanges();
    }
    selection.addRange(range);
    document.execCommand('copy');
    document.getElementById('cpInput').remove();
    this.message.success('已复制', {
      nzDuration: 1500
    });
  }

  goHrf() {
    window.open(this.activityDetail.enroll_third_url, '_blank');
    // "{{activityDetail.enroll_third_url}}"
  }
  setShare(val) {
    // let linshi = 'http://service.weibo.com/share/share.php?title=Mob&url=http%3A%2F%2Fwww.baidu.com%2Fcode%2Fdemo%2F%23&pic=#_loginLayer_1574669916123'
    var linshi;
    // console.log("detail:", this.activityDetail);
    if (val == 'weibo') {
      linshi = 'http://service.weibo.com/share/share.php?title=推荐【'
        + this.activityDetail.title
        + '】。传送门&url=https%3A%2F%2Fpgfans.cn%2Fa-detail%2F'
        + this.activityDetail.id
    } else if (val == 'qq') {
      //https://connect.qq.com/widget/shareqq/index.html?url=https%3A%2F%2Fwww.uisdc.com%2Ftencent-animation-8-0&title=%E5%A5%BD%E6%96%87%EF%BC%81%E3%80%90%E7%82%B9%E5%87%BB%E7%8E%87%E6%8F%90%E5%8D%87115%25%EF%BC%81%E8%85%BE%E8%AE%AF%E5%8A%A8%E6%BC%AB8.0%E6%94%B9%E7%89%88%E5%AE%9E%E6%88%98%E5%AE%8C%E6%95%B4%E5%A4%8D%E7%9B%98%E3%80%91%E6%8E%A8%E8%8D%90%E7%BB%99%E5%A4%A7%E5%AE%B6%EF%BC%81%E5%8E%9F%E6%96%87%E6%88%B3%E2%86%92&summary=&site=baidu&pics=https%3A%2F%2Fimage.uisdc.com%2Fwp-content%2Fuploads%2F2020%2F01%2Fuisdc-banner-20200106-2.jpg
      linshi = 'https://connect.qq.com/widget/shareqq/index.html?title=推荐【'
        + this.activityDetail.title
        + '】。传送门&url=https%3A%2F%2Fpgfans.cn%2Fa-detail%2F'
        + this.activityDetail.id
    }
    // yijianshengcai%3Futm_source%3DWeibo%26utm_medium%3DshareLink%26utm_campaign%3DsocialShare&pic=https%3A%2F%2Fcdn.segmentfault.com%2Fv-5dcd2518%2Fglobal%2Fimg%2Fuser-256.png&appkey=1742025894&searchPic=false#_loginLayer_1576841667829'

    window.open(linshi, '_blank')
  }

  isShow2code = false;
  qrcode = ''
  show2code() {
    this.qrcode = 'https://m.pgfans.cn/a-detail/' + this.activityDetail.id;
    this.isShow2code = true;
  }
  codeCancel() {
    this.isShow2code = false;
  }
}
