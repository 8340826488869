import { Component, OnInit } from '@angular/core';
import { HttpService } from './../services/http.service';
import { Md5 } from "ts-md5";
import { NzModalService } from 'ng-zorro-antd';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RoutesRecognized, NavigationExtras } from '@angular/router';
import { StorageService } from './../services/storage.service';
import { data } from 'jquery';

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.less']
})
export class ForgetComponent implements OnInit {
  step = 1;
  mobile = '';
  moblie_type = 1;
  img_code = '';
  code = '';
  m_code = '';
  password = '';
  repassword = '';

  codeLoading = false;      //验证码等待中
  loadingTime = 60;         //等待时间
  Timer;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.drawPic();
    }, 10);
  }
  randomNum(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  randomColor(min, max) {
    var r = this.randomNum(min, max);
    var g = this.randomNum(min, max);
    var b = this.randomNum(min, max);
    return "rgb(" + r + "," + g + "," + b + ")";
  }
  drawPic() {
    var canvas: any = document.getElementById("canvas");
    var width = canvas.width;
    var height = canvas.height;
    var ctx = canvas.getContext('2d');
    ctx.textBaseline = 'bottom';

    /**绘制背景色**/
    // ctx.fillStyle = this.randomColor(180, 240); //颜色若太深可能导致看不清
    ctx.fillStyle = '#d1b8bb'
    ctx.fillRect(0, 0, width, height);
    /**绘制文字**/
    var str = 'ABCEFGHJKLMNPQRSTWXY123456789';
    this.img_code = '';
    for (var i = 0; i < 4; i++) {
      var txt = str[this.randomNum(0, str.length)];
      this.img_code = this.img_code + txt;
      ctx.fillStyle = this.randomColor(50, 160);  //随机生成字体颜色
      // #6a3b95
      console.log(ctx.fillStyle)
      ctx.font = this.randomNum(30, 40) + 'px SimHei'; //随机生成字体大小
      var x = i * 25;
      var y = this.randomNum(35, 35);
      var deg = this.randomNum(0, 30);
      //修改坐标原点和旋转角度
      ctx.translate(x, y);
      ctx.rotate(deg * Math.PI / 180);
      ctx.fillText(txt, 0, 0);
      //恢复坐标原点和旋转角度
      ctx.rotate(-deg * Math.PI / 180);
      ctx.translate(-x, -y);
    }
    /**绘制干扰线**/
    for (var i = 0; i < 5; i++) {
      ctx.strokeStyle = this.randomColor(40, 180);
      ctx.beginPath();
      ctx.moveTo(this.randomNum(0, width), this.randomNum(0, height));
      ctx.lineTo(this.randomNum(0, width), this.randomNum(0, height));
      ctx.stroke();
    }
    /**绘制干扰点**/
    for (var i = 0; i < 100; i++) {
      ctx.fillStyle = this.randomColor(0, 255);
      ctx.beginPath();
      ctx.arc(this.randomNum(0, width), this.randomNum(0, height), 1, 0, 2 * Math.PI);
      ctx.fill();
    }
  }

  spanChange(type){
    this.moblie_type = type;
    this.step = 1;
    this.mobile = '';
    this.code = '';
    this.m_code = '';
    setTimeout(() => {
      this.drawPic();
    }, 10);
    clearInterval(this.Timer);
    this.codeLoading = false;
    this.loadingTime = 60;  
    
  }

  /**获取手机验证码 */
  getCode() {
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getcode');
      var api = 'user/User/getCode';
      var body1 = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'regstate': '2',
        'mobile': this.mobile,
      }
    // console.log("body", body);
      this.http.doPost(api, body1, (result) => {
        if (result.code == "2011") {
          this.codeLoading = true;
          this.timesOut();
        } else {
          this.modalService.error({
            nzTitle: "验证码获取失败",
            nzContent: result.message,
            nzOkText: "关闭",
            nzOnOk: () => { },
          });
        }
      });
  }

  /**
   * 获取邮箱验证码
   */
  getCodeEmail(){
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getemailcode');
      var api = 'user/user/getEmailCode';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'email': this.mobile,
        'isnew': 2,
      };
    // console.log("body", body);
      this.http.doPost(api, body, (result) => {
        // console.log(result);
        if (result.code == "200") {
          this.codeLoading = true;
          this.timesOut();
        } else {
          this.modalService.error({
            nzTitle: "验证码获取失败",
            nzContent: result.message,
            nzOkText: "关闭",
            nzOnOk: () => { },
          });
        }
      });
  }
  /**倒计时控制验证码按钮 */
  timesOut() {
    // if (this.loadingTime == 0) {
    //   this.codeLoading = false;
    //   this.loadingTime = 60;
    //   clearTimeout(Timer);
    //   return;
    // }else{
    //   this.codeLoading = true;
    //   this.loadingTime--;
    //   var Timer = setTimeout(() => {
    //     this.timesOut();
    //   }, 1000);
    // }

    this.Timer = setInterval(()=>{
      if(this.loadingTime==0){
        this.codeLoading = false;
        this.loadingTime = 60;
        clearInterval(this.Timer);
        return;
      }
      this.loadingTime--;
      this.codeLoading = true; 
    },1000)
    
  }

  next() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|forgetpasscodevali');
    var api = 'user/User/forgetpasscodevali';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'mobile': this.mobile,
      'code': this.m_code,
      'sendto': this.mobile,
    };
  // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.step = 2;
      } else {
        this.modalService.error({
          nzTitle: "验证码错误",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }

  doSubmit() {
    //doSubmit
    let sign = '|forgetpass';
    let forget_api = 'user/User/forgetpass';
    if (this.moblie_type == 2) {
      sign = '|forgetemailpass';
      forget_api = 'user/user/forgetemailpass';
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + sign);
    var api = forget_api;
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'mobile': this.mobile,
      'code': this.m_code,
      'user_pass': this.password,
      'user_repass': this.password,
      'sendto': this.mobile,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.modalService.success({
          nzTitle: result.data.message,
          nzContent: "请重新登录",
          nzOkText: "关闭",
          nzOnOk: () => {
            this.router.navigate(['/login']);
          },
        })
      } else {
        this.modalService.error({
          nzTitle: "重置密码失败",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => {
            window.location.reload();
          },
        })
      }
    })
  }
}
