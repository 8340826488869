import { HttpService } from 'src/app/services/http.service'; import { Md5 } from "ts-md5";
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NzModalService, UploadFile } from 'ng-zorro-antd';
import { StorageService } from 'src/app/services/storage.service';
import { Component, OnInit } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'app-write-jw',
  templateUrl: './write-jw.component.html',
  styleUrls: ['./write-jw.component.less']
})
export class WriteJwComponent implements OnInit {

  userinfo: any = {};
  jwDetail: any = {};
  jwId = 0;
  tagList: any = [];    //获取到的所有标签
  labelList: any = [];  //动态二级标签
  thisbtn = 0;          //选中的一级标签

  salarys = [];

  quillConfig = {
    toolbar: [
      ['bold', 'italic',],
      ['link', 'blockquote', 'code-block', 'image'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'code-block', { 'header': 1 }, { 'header': 2 }],
      [{ 'indent': '-1' }, { 'indent': '+1' }], [{ 'color': [] }],
    ]
  };

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
  ) {
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userinfo = this.local.get('userinfo');
      console.log(this.userinfo);
    }
    route.queryParams.subscribe((data) => {
      this.jwId = data.id;
      if (this.jwId > 0) {
        this.getJobwanted();
      }
      if (!this.jwDetail.education || this.jwDetail.education.length < 1) {
        this.jwDetail.education = [{}];
      }
      if (!this.jwDetail.work || this.jwDetail.work.length < 1) {
        this.jwDetail.work = [{}];
      }
      if (!this.jwDetail.project || this.jwDetail.project.length < 1) {
        this.jwDetail.project = [{}];
      }
    });
  }

  ngOnInit() {
    this.getTagList();
  }


  /**
   * 获取所有标签列表question/question_label/allLabelList
   */
  getTagList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|alllabellist');
    var api = 'question/question_label/allLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.tagList = result.data;
        this.labelList = this.tagList[0].labelList;
      }
    })
  }
  /**切换标签 */
  selectChange(num) {
    this.thisbtn = num;
    this.labelList = this.tagList[num].labelList;
  }
  checkChange(e, key): void {
    // console.log(e);
    if (!this.jwDetail.label_ids) {
      this.jwDetail.label_ids = [];
    }
    this.tagList[this.thisbtn].labelList[key].checked = !this.tagList[this.thisbtn].labelList[key].checked;
    this.jwDetail.label_ids = this.jwDetail.label_ids.filter(value => value.id != e.id);
    if (this.tagList[this.thisbtn].labelList[key].checked) {
      this.jwDetail.label_ids.push(e);
    }
    console.log(this.jwDetail);
  }
  delTag(e, key): void {
    this.jwDetail.label_ids.splice(key, 1);
    this.tagList.forEach(tag1 => {
      tag1.labelList.forEach(tag => {
        if (tag.id == e.id) tag.checked = false;
      });
    });
  }
  getJobwanted() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getjobwanted');
    var api = 'work/work/getJobwanted';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'jobwanted_id': this.jwId,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null) {
          this.jwDetail = result.data;

        }
      }
    })
  }

  selectSalaryType() {
    this.jwDetail.salary = null;
    if (this.jwDetail.salary_type == 1) {
      this.salarys = [100000, 150000, 200000, 250000, 300000];
    } else if (this.jwDetail.salary_type == 2) {
      this.salarys = [10000, 15000, 20000, 25000, 30000];
    } else {
      this.salarys = [];
    }
  }

  yearChange(event) {
    this.jwDetail.year = new Date(event).getFullYear();
  }
  birthdayChange(event) {
    this.jwDetail.birthday = this.fmDate(event);
  }

  editor: any;
  EditorCreated(quill) {
    // console.log("quill:", quill)
    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler.bind(this));
    this.editor = quill;
  }
  imageHandler() {
    const Imageinput = document.createElement('input');
    Imageinput.setAttribute('type', 'file');
    Imageinput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp');
    Imageinput.classList.add('ql-image');
    Imageinput.addEventListener('change', () => {
      if (Imageinput.files != null && Imageinput.files[0] != null) {
        const file = Imageinput.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          var dataURL = reader.result;
          let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnlogo');
          var api = 'column/columns/addColumnLogo';
          var body = {
            'timestamp': new Date().getTime().toString().substr(0, 10),
            'signature': signature,
            'columnlogo': dataURL,
            'type': 2
          }
          this.http.doPost(api, body, (result) => {
            if (result.code == "200") {
              let imgUrl = 'https://admin.pgfans.cn' + result.data.url;
              var range = this.editor.getSelection(true);
              var length = range.index;
              var isok = this.editor.insertEmbed(length, 'image', imgUrl);
            }
          })
        };
      }
    });
    Imageinput.click();
  }
  contentChanged(event) {
    this.jwDetail.content = event.html;  // 内容编辑
  }

  doSubmit() {
    let res = this.checkNull();
    if (!res.success) {
      this.modalService.error({
        nzTitle: res.msg,
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      console.log("jwDetail:", this.jwDetail);
      return;
    }
    this.jwDetail.user_id = this.userinfo.id;
    this.jwDetail.education.forEach(ed => {
      ed.start_time = this.fmDate(ed.start_time);
      ed.end_time = this.fmDate(ed.end_time);
    });
    this.jwDetail.work.forEach(work => {
      work.start_time = this.fmDate(work.start_time);
      work.end_time = this.fmDate(work.end_time);
    });
    this.jwDetail.project.forEach(project => {
      project.start_time = this.fmDate(project.start_time);
      project.end_time = this.fmDate(project.end_time);
    });
    var list = this.jwDetail.education.sort((a, b) => {
      return b.education - a.education;
    });
    if (list[0].education == 1) {
      this.jwDetail.degree = '学士';
    } else if (list[0].education == 2) {
      this.jwDetail.degree = '硕士';
    } else if (list[0].education == 3) {
      this.jwDetail.degree = '博士';
    }
    console.log("jwDetail:", this.jwDetail);
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addjobwanted');
    var api = 'work/work/addJobwanted';
    // var body = {
    //   'timestamp': new Date().getTime().toString().substr(0, 10),
    //   'signature': signature,
    //   'user_id': this.jwDetail,
    // }
    this.jwDetail.timestamp = new Date().getTime().toString().substr(0, 10);
    this.jwDetail.signature = signature;
    delete this.jwDetail.yeartemp;
    delete this.jwDetail.birthdaytemp;
    // console.log("body", body);

    this.modalService.error({
      nzTitle: "写求职错误",
      nzContent: "此处设置的字段和数据结构复杂，我们已经实现了填写和提交（可查看调试信息的“提交求职信息”log），但是按要求提交写求职报错，所以此功能将作为后期完善部分实现！",
      nzOkText: "关闭",
      nzOnOk: () => { },
    })
    console.log("提交求职信息:", this.jwDetail);
    return;

    this.http.doPost(api, this.jwDetail, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null) {
          this.jwDetail = result.data;

        }
      } else {
        this.modalService.error({
          nzTitle: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  fmDate(date) {
    let day = new Date(date);
    let yy = day.getFullYear().toString();
    let mm = (day.getMonth() + 1).toString();
    let dd = day.getDate().toString();
    if (mm.length == 1) {
      mm = '0' + mm;
    }
    if (dd.length == 1) {
      dd = '0' + dd;
    }
    let res = yy + '/' + mm + '/' + dd;
    return res;
  }

  checkNull() {
    let succ = true;
    let msg = '没有空';
    this.jwDetail.project.forEach(ed => {
      if (!ed.start_time || !ed.end_time || !ed.project || !ed.content) {
        succ = false;
        msg = '项目经验不得有空项';
      }
    });
    this.jwDetail.work.forEach(ed => {
      if (!ed.start_time || !ed.end_time || !ed.company || !ed.position) {
        succ = false;
        msg = '工作经历不得有空项';
      }
    });
    this.jwDetail.education.forEach(ed => {
      if (!ed.start_time || !ed.end_time || !ed.school || !ed.major || !ed.education) {
        succ = false;
        msg = '教育经历不得有空项';
      }
    });
    if (!this.jwDetail.label_ids || this.jwDetail.label_ids.length < 1) {
      succ = false;
      msg = '至少选择一个发展方向';
    }
    if (!this.jwDetail.position) {
      succ = false;
      msg = '应聘职位不得为空';
    }
    if (!this.jwDetail.salary) {
      succ = false;
      msg = '期望薪资不得为空';
    }
    if (!this.jwDetail.workplace) {
      succ = false;
      msg = '工作地点不得为空';
    }
    if (!this.jwDetail.email) {
      succ = false;
      msg = '邮箱地址不得为空';
    }
    if (!this.jwDetail.phone) {
      succ = false;
      msg = '手机号不得为空';
    }
    if (!this.jwDetail.birthdaytemp) {
      succ = false;
      msg = '请选择出生日期';
    }
    if (!this.jwDetail.yeartemp) {
      succ = false;
      msg = '请选择参加工作时间';
    }
    if (!this.jwDetail.sex) {
      succ = false;
      msg = '请选择性别';
    }
    if (!this.jwDetail.name) {
      succ = false;
      msg = '姓名不得为空';
    }
    if (!this.jwDetail.title) {
      succ = false;
      msg = '标题不得为空';
    }
    return { success: succ, msg: msg };
  }


  selectedFileOnChanged(event) {
    // console.log("Changedevent:", event);
  }
  selectFileName = '';
  beforeUpload = (file: File) => {
    // console.log('file:', file);
    this.selectFileName = file.name;
    this.addColumnLogo(file)
    // return new Observable((observer: Observer<boolean>) => {
    //   var reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onloadend = () => {
    //     var dataURL = reader.result;
    //     this.addColumnLogo(dataURL)
    //   }
    // });
  };

  /**
   * 上传图片column/columns/addColumnLogo
   */
  addColumnLogo(dataURL) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getfile');
    var api = 'work/work/getFile';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'file': dataURL,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        // console.log("返回的url：", result.data);
        this.jwDetail.url = result.data;
        alert("1");
        console.log("返回的url：", this.jwDetail.url);
      }
    })
  }

  isShowView = false;
  preview() {

    // let res = this.checkNull();
    // if (!res.success) {
    //   this.modalService.error({
    //     nzTitle: res.msg,
    //     nzOkText: "关闭",
    //     nzOnOk: () => { },
    //   })
    //   console.log("jwDetail:", this.jwDetail);
    //   return;
    // }
    
    this.modalService.error({
      nzTitle: "预览错误",
      nzContent: "写求职和返回的求职数据结构不同，此功能将作为后期完善部分实现！",
      nzOkText: "关闭",
      nzOnOk: () => { },
    })
    return;
    
    this.jwDetail.user_id = this.userinfo.id;
    this.jwDetail.education.forEach(ed => {
      ed.start_time = this.fmDate(ed.start_time);
      ed.end_time = this.fmDate(ed.end_time);
    });
    this.jwDetail.work.forEach(work => {
      work.start_time = this.fmDate(work.start_time);
      work.end_time = this.fmDate(work.end_time);
    });
    this.jwDetail.project.forEach(project => {
      project.start_time = this.fmDate(project.start_time);
      project.end_time = this.fmDate(project.end_time);
    });
    var list = this.jwDetail.education.sort((a, b) => {
      return b.education - a.education;
    });
    if (list[0].education == 1) {
      this.jwDetail.degree = '学士';
    } else if (list[0].education == 2) {
      this.jwDetail.degree = '硕士';
    } else if (list[0].education == 3) {
      this.jwDetail.degree = '博士';
    }
    console.log("jwDetail:", this.jwDetail);
    this.isShowView = true;
  }


}
