import { HttpService } from 'src/app/services/http.service'; import { Md5 } from "ts-md5";
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from 'src/app/services/storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jw-detail',
  templateUrl: './jw-detail.component.html',
  styleUrls: ['./jw-detail.component.less']
})
export class JwDetailComponent implements OnInit {

  userinfo: any = {};

  jwId = 0;             //传来的求职id
  jwDetail: any = {};   //求职详情

  quillContent;

  answer1List: any = [];      //一级评论列表
  answer2List: any = [];      //二级评论列表

  answerComment = '';             //双向绑定用于提交评论的输入框文本
  plackText = "评论ta";   //提交评论输入框的缺省文字
  replyBtnText = "提交评论";      //提交评论按钮的文字
  replyUserId = 0;
  answerId = 0;                   //被回复答案的id，当answertype为1回答问题的时候，此处送0

  recList: any = [];

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
  ) {
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userinfo = this.local.get('userinfo');
      console.log(this.userinfo);
    }
    route.queryParams.subscribe((data) => {
      this.jwId = data.id;
    });
  }

  ngOnInit() {
    this.getJobwanted();
  }

  getJobwanted() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getjobwanted');
    var api = 'work/work/getJobwanted';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'jobwanted_id': this.jwId,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null) {
          this.jwDetail = result.data;
          this.gainAge(this.jwDetail.birthday)
          this.getAnswerList();
          this.getRecRecList();
        }
      }
    })
  }

  gainDateTime() {

  }
  gainAge(birthday) {
    let nowDate = new Date();
    let nowYear = nowDate.getFullYear();
    let nowMonth = nowDate.getMonth() + 1;
    let nowDay = nowDate.getDate();
    let borthDate = new Date(birthday);
    let birthYear = borthDate.getFullYear();
    let birthMonth = borthDate.getMonth() + 1;
    let birthDay = borthDate.getDate();
    let ageDiff = nowYear - birthYear;
    if (nowMonth == birthMonth) {
      let dayDiff = nowDay - birthDay;
      if (dayDiff < 0) {
        this.jwDetail.age = ageDiff - 1;
      } else {
        this.jwDetail.age = ageDiff;
      }
    } else {
      let monthDiff = nowMonth - birthMonth;
      if (monthDiff < 0) {
        this.jwDetail.age = ageDiff - 1;
      } else {
        this.jwDetail.age = ageDiff;
      }
    }
  }

  down() {
    window.open(this.jwDetail.url, '_blank');
    this.getJobwanted();
  }

  /**获取招聘推荐列表 */
  getRecRecList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|recrecruitlist');
    var api = 'work/work/recRecruitList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.recList = result.data;
        }
      }
    })
  }


  /**
   * 获取所有该文章的一级评论
   */
  getAnswerList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|answerlist');
    var api = 'work/jobwant_answer/answerList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'answer_type': 1,
      'parent_answer_id': 0,
      'jobwanted_id': this.jwId,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        if (result.data != null) {
          this.answer1List = result.data;
        }
      }
    })
  }
  doShowReAnswer(id, key) {
    this.answer2List = [];
    if (this.answer1List[key].checked) {
      this.answer1List[key].checked = false;
      return;
    }
    for (let i = 0; i < this.answer1List.length; i++) {
      if (this.answer1List[i].checked) {
        this.answer1List[i].checked = false;
      }
    }
    this.getReAnswer(id, key);
  }
  /**
   * 获取此一级评论的二级评论列表
   */
  getReAnswer(id, key) {
    this.plackText = "评论ta";
    this.replyBtnText = "提交评论";
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|answerlist');
    var api = 'work/jobwant_answer/answerList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'answer_type': 2,
      'parent_answer_id': id,
      'jobwanted_id': this.jwId,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.answer2List = result.data;
        for (let i = 0; i < this.answer1List.length; i++) {
          if (key == i) {
            this.answer1List[key].checked = true;
            this.answer1List[key].answer_count = this.answer2List.length;
          }
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }
  /**
   * 添加文章评论work/jobwant_answer/addAnswer
   */
  doAddAnswer() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addanswer');
    var api = 'work/jobwant_answer/addAnswer';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'jobwanted_id': this.jwId,
      'answer_type': 1,
      'answer_id': 0,
      'user_id': this.userinfo.id,
      'parent_answer_id': 0,
      'reply_answer_type': 1,
      'content': this.quillContent,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.quillContent = '';
        this.getAnswerList();
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**
   * 回复答案
   * @param reAnswer 要回复的答案json
   */
  handReply(reAnswer, id) {
    this.plackText = "回复ta";
    this.replyBtnText = "回复";
    this.replyUserId = reAnswer.id;
    // document.getElementById(id).focus();
  }

  /**
   * 提交评论（二级回复）
   */
  addComment(answerId, key) {
    var replyAnswerType = 1;
    if (this.replyBtnText == "回复") {
      replyAnswerType = 2
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addanswer');
    var api = 'work/jobwant_answer/addAnswer';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'jobwanted_id': this.jwId,
      'answer_type': 2,
      'answer_id': answerId,
      'user_id': this.userinfo.id,
      'parent_answer_id': answerId,
      'reply_answer_type': replyAnswerType,
      'content': this.answerComment,
    }
    if (this.replyBtnText == "回复") {
      body.answer_id = this.replyUserId;
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        // console.log("2级回复成功");
        this.answerComment = '';
        this.getReAnswer(body.parent_answer_id, key);
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
    this.plackText = "评论ta的留言";
    this.replyBtnText = "提交评论";
    this.answerComment = '';
  }

  /**删除评论work/jobwant_answer/deleteAnswer */
  delAnswer(reAnswer) {
    this.modalService.warning({
      nzTitle: '删除',
      nzContent: '<font>您确定要删除吗？</font>',
      nzOkText: '确定',
      nzOnOk: () => {
        let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|deleteanswer');
        var api = 'work/jobwant_answer/deleteAnswer';
        var body = {
          'timestamp': new Date().getTime().toString().substr(0, 10),
          'signature': signature,
          'answer_id': reAnswer.id,
          'user_id': this.userinfo.id,
        }
        // console.log("body", body);
        this.http.doPost(api, body, (result) => {
          // console.log(result);
          if (result.code == "200") {
            this.btnTitle = '提交评论';
            this.quillContent = '';
            this.getAnswerList();
          }
        })
      },
      nzCancelText: '取消',
      nzOnCancel: () => { },
    });
  }

  btnTitle = '提交评论';
  doSubmit() {
    if (this.btnTitle == '提交评论') {
      this.doAddAnswer();
    } else {
      this.doEditQuestion();
    }
  }
  /**编辑答案work/jobwant_answer/editAnswer */
  doEditQuestion() {
    // console.log("quillContent：", this.quillContent);    
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|editanswer');
    var api = 'work/jobwant_answer/editAnswer';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'answer_id': this.answerId,
      'user_id': this.userinfo.id,
      'content': this.quillContent,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.btnTitle = '提交评论';
        this.quillContent = '';
        this.getAnswerList();
      }
    })
  }
  /**编辑回答 */
  editAnswer(answer) {
    this.answerId = answer.id;
    // document.getElementById('writeAnswer').scrollIntoView(true);
    this.btnTitle = '修改评论';
    this.quillContent = answer.content;
  }
  /**
   * 检查是否已经登录
   */
  checkUser() {
    if (!this.userinfo) {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'from': 'jw-detail', },
      }
      this.router.navigate(['/login'], navigationExtras)
      return false;
    }
    return true;
  }

}
