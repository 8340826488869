
import { AppComponent } from './../app.component';
import { Md5 } from 'ts-md5';
import { HttpService } from './../services/http.service';
import { NzModalService, NzMessageService, UploadFile, UploadXHRArgs } from 'ng-zorro-antd';
import { StorageService } from './../services/storage.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized, NavigationExtras } from '@angular/router';
import { transliterate as tr } from 'transliteration'; //汉字转拼音组件
import { Observable, Observer, Subscription } from 'rxjs';

import { HttpClient, HttpHeaders, HttpEventType, HttpParams, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.less']
})
export class UserComponent implements OnInit {

  subscription: Subscription;
  userId = 0;
  public isMe = false;

  public userinfo: any = {};       //本地保存的用户登录信息
  numberInfo: any = {};   //获取到的各种数量

  loading = false;
  avatarUrl: string;

  sendTitle = '';
  sendContent = '';

  userName = '';
  address = '';     //所在地
  work = '';        //工作
  company = '';     //公司组织
  user_url = '';    //个人网址
  signatureword = '';   //个性签名(简介)
  cityList: any = [];       //动态获取到的城市列表
  editAddress = false;     //所在地
  editWork = false;        //工作
  editCompany = false;     //公司组织
  editUser_url = false;    //个人网址
  editSignature = false;   //个性签名(简介)
  editAvatar = false;
  editUsername = false;
  timer: any;
  check = false; //未签到

  isShowSendMessage = false;    //是否显示发送私信对话框

  navSelect;       //本页面的子页面，默认home
  thisUrl = '';             //路由

  nzFilterOption = () => true;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    public HTTP: HttpClient,
    private appPage: AppComponent,
    private msg: NzMessageService,
    private route: ActivatedRoute,
    private titleService: Title,
  ) {
    window.scrollTo(0,0);
    this.titleService.setTitle('用户中心'+'-PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
    route.queryParams.subscribe((data) => {
      this.userId = data.userId;
    });
   // console.log("url:", this.router.url);

    let urlEnd = this.router.url.indexOf('?');
    let abc = this.router.url.substr(6, urlEnd - 6);
    // console.log("abc:", abc);
    this.navSelect = abc;


    this.getUserInfo(this.userId);
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      if (this.userId == this.local.get('userinfo').id) {
        this.isMe = true;
      } else {
        this.isMe = false;
        this.lookCount();
      }
    } else {
      this.isMe = false;
    }

    this.subscription = router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        this.thisUrl = event.url;
        // console.log("thisUrl:", this.thisUrl);

        let urlEnd = this.thisUrl.indexOf('?');
        let abc = this.thisUrl.substr(6, urlEnd - 6);
        // console.log("abc:", abc);
        this.navSelect = abc;

        if (this.thisUrl.substring(1, 5) == 'user') {
          // this.navSelect = this.thisUrl.replace('/user/', '');
          // if (event.url.indexOf('fouceTab') < 0) {
          let idId = 0;
          idId = Number(event.url.substr(event.url.indexOf('userId=') + 7));
          if (event.url.indexOf('&') > 0) {
            idId = Number(event.url.substr(
              event.url.indexOf('userId=') + 7,
              event.url.indexOf('&') - event.url.indexOf('userId=') - 7
            ));
          }
          // console.log("useridId:", idId)
          this.userId = idId;
          this.getUserInfo(this.userId);
          if (this.local.get('userinfo') && idId == this.local.get('userinfo').id) {
            this.userId = this.local.get('userinfo').id;
            this.isMe = true;
            this.getNumberInfo();
          } else {
            this.isMe = false;
            this.userId = idId;
            this.getNumberInfo();
          }
          // }
        }

      }
    });

  }

  ngOnInit(): void {
    // console.log("userinfo", this.userinfo);
    //获取用户资料，用于展示在头部
    //获取我的各种数量user/user/getNumberInfo
    this.getNumberInfo();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.titleService.setTitle('PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
  }

  /**
   * 增加个人主页浏览量user/user/lookCountInc
   */
  lookCount() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|lookcountinc');
    var api = 'user/user/lookCountInc';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {

      }
    })
  }
  /**
   * 通过用户id获取用户信息user/user/getNewInfo
   */
  getUserInfo(id) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
    var api = 'user/user/getNewInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': id,
      'my_user_id': 0,
      'sessionid': 0,
    }
    if (this.local.get('userinfo') == null) {
      body.my_user_id = 0;
    } else {
      body.my_user_id = this.local.get('userinfo').id;
      body.sessionid = this.local.get('userinfo').sessionid;
    }
    // console.log("body:", body);
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        // console.log("更新头像");
        this.userinfo = result.data;
        // console.log(this.userinfo)
        if (this.userinfo.is_signin == 1) {
          this.check = true;
        }
        this.getNumberInfo();
      }
    })
  }
  customReq = (item: UploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('file', item.file as any);
    formData.append('id', '1000');
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true,
      withCredentials: true
    });
    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.HTTP.request(req).subscribe(
      // tslint:disable-next-line no-any
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            // tslint:disable-next-line:no-any
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      err => {
        item.onError!(err, item.file!);
      }
    );
  };


  setData = ((file: UploadFile) => {
    // console.log('setdata:', file);
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnlogo');
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'columnlogo': file.name,
    }
    return body;
  });
  beforeUpload = (file: File) => {
    // this.doUpload(file);
    // console.log('file:', file)
    // this.submitEdit(5);
    // return false;
    return new Observable((observer: Observer<boolean>) => {
      const isJPG = file.type === 'image/jpeg';//image/png,image/jpeg,image/gif,image/bmp
      const isPNG = file.type === 'image/png';
      const isBMP = file.type === 'image/bmp';
      var isjpb: boolean = isJPG || isPNG || isBMP;
      if (!isjpb) {
        this.msg.error('请上传JPG、PNG、BMP文件!');
        observer.complete();
        return;
      }
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.msg.error('图片请不要超过1MB!');
        observer.complete();
        return;
      }


      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        var dataURL = reader.result;
        this.addColumnLogo(dataURL)
      }
      this.checkImageDimension(file).then(dimensionRes => {
        // if (!dimensionRes) {
        //   this.msg.error('Image oly 300x300 above');
        //   observer.complete();
        //   return;
        // }

        // observer.next(isjpb && isLt2M && dimensionRes);
        // observer.next(isjpb && isLt2M);
        // if (isjpb && isLt1M) {
        //   this.submitEdit(5);
        // }
        observer.next(false);
        observer.complete();
      });
    });
  };

  getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  private checkImageDimension(file: File): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src!);
        resolve(width === height && width >= 300);
      };
    });
  }

  handleChange(info: { file: UploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          this.avatarUrl = img;
          // console.log('base64:', img);
        });

        break;
    }
  }
  /**
   * 上传图片column/columns/addColumnLogo
   */
  addColumnLogo(dataURL) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnlogo');
    var api = 'column/columns/addColumnLogo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'columnlogo': dataURL,
      'type': 2
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.avatarUrl = 'https://admin.pgfans.cn' + result.data.url;
        this.submitEdit(5);
      }
    })
  }

  /**
   * 获取用户的各种数量user/user/getNumberInfo
   */
  getNumberInfo() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnumberinfo');
    var api = 'user/user/getNumberInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'sessionid': 0
    }
    if (this.local.get('userinfo') == null) {
      body.sessionid = 0;
    } else {
      body.sessionid = this.local.get('userinfo').sessionid;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.numberInfo = result.data;
        // console.log("numberinfo", this.numberInfo);
      }
    })
  }

  showSendMessage() {
    if (this.local.get('userinfo') == null) {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'from': 'adetail', },
      }
      this.router.navigate(['/login'], navigationExtras)
      return;
    }
    this.isShowSendMessage = true;
  }
  send() {
    this.isShowSendMessage = false;
    this.doSend();
  }
  sendCancel() {
    this.isShowSendMessage = false;
  }
  /**
   * 发送私信user/user/sendPrivateMessage
   */
  doSend() {
    if (this.local.get('userinfo') == null) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|sendprivatemessage');
    var api = 'user/user/sendPrivateMessage';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'title': '无标题',
      'content': this.sendContent,
      'accepted_user_id': this.userId
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.modalService.success({
          nzTitle: "系统提示",
          nzContent: "私信发送成功",
          nzOkText: "关闭",
          nzOnOk: () => {
            this.sendTitle = '';
            this.sendContent = '';
          },
        })
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: "发送私信失败，请联系管理员",
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**
   * 点击修改资料项
   * @param key 资料项编号：0=地点；1=工作；2=公司；3=网址；4=简介
   */
  editItem(key) {
    if (!this.isMe) {
      return;
    }
    switch (key) {
      case 0:
        this.editAddress = true;
        this.editWork = false;
        this.editCompany = false;
        this.editUser_url = false;
        this.editSignature = false;
        this.editAvatar = false;
        this.editUsername = false;
        break;
      case 1:
        this.editAddress = false;
        this.editWork = true;
        this.editCompany = false;
        this.editUser_url = false;
        this.editSignature = false;
        this.editAvatar = false;
        this.editUsername = false;
        break;
      case 2:
        this.editAddress = false;
        this.editWork = false;
        this.editCompany = true;
        this.editUser_url = false;
        this.editSignature = false;
        this.editAvatar = false;
        this.editUsername = false;
        break;
      case 3:
        this.editAddress = false;
        this.editWork = false;
        this.editCompany = false;
        this.editUser_url = true;
        this.editSignature = false;
        this.editAvatar = false;
        this.editUsername = false;
        break;
      case 4:
        this.editAddress = false;
        this.editWork = false;
        this.editCompany = false;
        this.editUser_url = false;
        this.signatureword = this.userinfo.signatureword;
        this.editSignature = true;
        this.editAvatar = false;
        this.editUsername = false;
        break;
      case 5:
        this.editAddress = false;
        this.editWork = false;
        this.editCompany = false;
        this.editUser_url = false;
        this.editSignature = false;
        this.editAvatar = true;
        this.editUsername = false;
        break;
      case 6:
        this.editAddress = false;
        this.editWork = false;
        this.editCompany = false;
        this.editUser_url = false;
        this.editSignature = false;
        this.editAvatar = false;
        this.editUsername = true;
        break;
      default:
        break;
    }
  }

  //**输入字母或文字动态显示城市列表 */
  search(event: string): void {
    if (!event) {
      this.cityList = [];
      return;
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.doSearch(event);
    }, 400)
  }
  doSearch(event) {
    // console.log(event);
    this.initializeItems();
    var val = event;//.target.value;
    if (val == null || val == undefined || val.trim() == '') {
      return;
    }
    var Temp: any = null;
    Temp = [];
    this.cityList.filter((v) => {
      var py = v.pinYinName.replace(' ', '').toLowerCase().indexOf(val.replace(' ', '').toLowerCase());
      var cname = v.city.replace(' ', '').toLowerCase().indexOf(val.replace(' ', '').toLowerCase());
      if (py > -1 || cname > -1) {
        Temp.push(v);
      }
    })
    this.cityList = Temp;
  }


  //**优化用户列表数据结构，便于搜索 */
  initializeItems() {
    this.cityList = this.citys;
    for (let i = 0; i < this.cityList.length; i++) {
      this.cityList[i].pinYinName = tr(this.cityList[i].city).toLowerCase().split(" ").join("");
      // console.log(this.cityList[i].pinYinName);
    }
  }
  /**
   * 关注该用户（加关注）user/user_follow/operate
   */
  doFouce(fouce) {
    if (this.local.get('userinfo') == null) {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'from': 'adetail', },
      }
      this.router.navigate(['/login'], navigationExtras)
      return;
    }
    if (!this.userinfo) {
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: "请先登录",
        nzOkText: "关闭",
        nzOnOk: () => {
          this.router.navigate(['/login']);
        },
      })
    } else {
      var type = 1;
      if (fouce) {
        type = 2;
      }
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
      var api = 'user/user_follow/operate';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'user_id': this.local.get('userinfo').id,
        'about_user_id': this.userId,
        'type': type
      }
      this.http.doPost(api, body, (result) => {
        if (result.code == "200") {
          this.getUserInfo(this.userId);

          var text = "关注用户";
          if (fouce) {
            text = "取消关注";
          }
          this.modalService.success({
            nzTitle: text,
            nzContent: result.message,
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
        }
      })
    }
  }
  /**点击提交修改user/User/changeInfo */
  submitEdit(key) {
    switch (key) {
      case 0:
        if (!this.address) {
          this.modalService.warning({
            nzTitle: "系统提示",
            nzContent: "地址不得为空",
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
          this.editAddress = false;
          return;
        }
        break;
      case 1:
        if (!this.work) {
          this.modalService.warning({
            nzTitle: "系统提示",
            nzContent: "工作不得为空",
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
          this.editWork = false;
          return;
        }
        break;
      case 2:
        if (!this.company) {
          this.modalService.warning({
            nzTitle: "系统提示",
            nzContent: "公司组织不得为空",
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
          this.editCompany = false;
          return;
        }
        break;
      case 3:
        if (!this.user_url) {
          this.modalService.warning({
            nzTitle: "系统提示",
            nzContent: "网址不得为空",
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
          this.editUser_url = false;
          return;
        }
        break;
      case 4:
        // if (!this.signatureword) {
        //   this.modalService.warning({
        //     nzTitle: "系统提示",
        //     nzContent: "简介不得为空",
        //     nzOkText: "关闭",
        //     nzOnOk: () => { },
        //   })
        //   this.editSignature = false;
        //   return;
        // }
        break;
      case 5:
        // if (!this.avatarUrl) {
        //   this.modalService.warning({
        //     nzTitle: "系统提示",
        //     nzContent: "头像不得为空",
        //     nzOkText: "关闭",
        //     nzOnOk: () => { },
        //   })
        //   this.editAvatar = false;
        //   return;
        // }
        break;
      case 6:
        // console.log(this.userName);
        if (!this.userName) {
          this.modalService.warning({
            nzTitle: "系统提示",
            nzContent: "昵称不得为空",
            nzOkText: "关闭",
            nzOnOk: () => { },
          })
          this.editUsername = false;
          return;
        }
        break;
      default:
        break;
    }
    // console.log("user修改个人资料");
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|changeinfo');
    var api = 'user/User/changeInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'id': this.userinfo.id,
      'sex': this.userinfo.sex,
      'birthday': this.userinfo.birthday,
      'work': this.userinfo.work,
      'user_login': this.userinfo.user_login,
      'old_user_pass': this.userinfo.old_user_pass,
      'user_pass': this.userinfo.user_pass,
      'user_repass': this.userinfo.user_repass,
      'user_email': this.userinfo.user_email,
      'user_url': this.userinfo.user_url,
      'avatar': this.userinfo.avatar,
      'company': this.userinfo.company,
      'address': this.userinfo.address,
      'skills': this.userinfo.skills,
      'signatureword': this.userinfo.signatureword,
      'sessionid': this.userinfo.sessionid
    }
    switch (key) {
      case 0:
        body.address = this.address;
        this.editAddress = false;
        break;
      case 1:
        body.work = this.work;
        this.editWork = false;
        break;
      case 2:
        body.company = this.company;
        this.editCompany = false;
        break;
      case 3:
        body.user_url = this.user_url;
        this.editUser_url = false;
        break;
      case 4:
        body.signatureword = this.signatureword;
        this.editSignature = false;
        break;
      case 5:
        body.avatar = this.avatarUrl;
        this.editAvatar = false;
        break;
      case 6:
        body.user_login = this.userName;
        this.editUsername = false;
        break;
      default:
        break;
    }
    // console.log("body", body);
    // return;
    this.http.doPost(api, body, (result) => {
      // console.log("user修改个人资料", result);
      if (result.code == "200") {
        // this.modalService.success({
        //   nzTitle: "系统提示",
        //   nzContent: "修改用户资料" + result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
        switch (key) {
          case 0:
            this.userinfo.address = this.address;
            break;
          case 1:
            this.userinfo.work = this.work;
            break;
          case 2:
            this.userinfo.company = this.company;
            break;
          case 3:
            this.userinfo.user_url = this.user_url;
            break;
          case 4:
            this.signatureword = result.data.signatureword;
            this.userinfo.signatureword = this.signatureword;
            this.editSignature = false;
            break;
          case 5:
            this.userinfo.avatar = this.avatarUrl;
            this.appPage.userinfo.avatar = this.userinfo.avatar;
            break;
          case 6:
            this.userinfo.user_login = this.userName;
            this.appPage.userinfo.user_login = this.userinfo.userName;
            break;
          default:
            break;
        }
        this.signatureword = result.data.signatureword;
        // this.getUserInfo(this.userId);
        this.userinfo = result.data;
        this.local.set('userinfo', this.userinfo);
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }
  /**签到 */
  checkin(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|signin');
    var api = '/user/pgdou/signIn';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
    };
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null) {
          this.check = true;
          this.modalService.success({
            nzTitle: "今日签到成功奖励",
            nzContent: "<img src='../../assets/imgs/pgdou.png' width='32px'>&nbsp;&nbsp;<b>x5</b>",
            nzOkText: "确定",
            nzOnOk: () => {
              this.sendTitle = '';
              this.sendContent = '';
              location.reload();
            },
          });
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }
  /**
   * 跳转到子页面
   * @param page 子页面：home、article、answer...
   */
  goPage(page) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'userId': this.userId, },
    }
    this.router.navigate(['/user/' + page], navigationExtras);
  }

  citys = [
    { city: '北京 北京市' },
    { city: '上海 上海市' },
    { city: '天津 天津市' },
    { city: '重庆 重庆市' },
    { city: '内蒙古 呼和浩特市' },
    { city: '内蒙古 包头市' },
    { city: '内蒙古 乌海市' },
    { city: '内蒙古 赤峰市' },
    { city: '内蒙古 通辽市' },
    { city: '内蒙古 鄂尔多斯市' },
    { city: '内蒙古 呼伦贝尔市' },
    { city: '内蒙古 巴彦淖尔市' },
    { city: '内蒙古 乌兰察布市' },
    { city: '内蒙古 兴安盟' },
    { city: '内蒙古 锡林郭勒盟' },
    { city: '内蒙古 阿拉善盟' },
    { city: '内蒙古 丰镇市' },
    { city: '内蒙古 锡林浩特市' },
    { city: '内蒙古 二连浩特市' },
    { city: '内蒙古 满州里市' },
    { city: '内蒙古 牙克石市' },
    { city: '内蒙古 扎兰屯市' },
    { city: '内蒙古 霍林郭勒市' },
    { city: '内蒙古 乌兰浩特市' },
    { city: '内蒙古 根河市' },
    { city: '内蒙古 额尔古纳市' },
    { city: '内蒙古 阿尔山市' },
    { city: '山西 太原市' },
    { city: '山西 大同市' },
    { city: '山西 阳泉市' },
    { city: '山西 长治市' },
    { city: '山西 晋城市' },
    { city: '山西 朔州市' },
    { city: '山西 忻州市' },
    { city: '山西 临汾市' },
    { city: '山西 运城市' },
    { city: '山西 晋中市' },
    { city: '山西 古交市' },
    { city: '山西 霍州市' },
    { city: '山西 潞城市' },
    { city: '山西 高平市' },
    { city: '山西 原平市' },
    { city: '山西 介休市' },
    { city: '山西 汾阳市' },
    { city: '山西 孝义市' },
    { city: '山西 河津市' },
    { city: '山西 永济市' },
    { city: '山西 侯马市' },
    { city: '山西 吕梁市' },
    { city: '河北 石家庄市' },
    { city: '河北 邯郸市' },
    { city: '河北 邢台市' },
    { city: '河北 保定市' },
    { city: '河北 张家口市' },
    { city: '河北 承德市' },
    { city: '河北 唐山市' },
    { city: '河北 秦皇岛市' },
    { city: '河北 沧州市' },
    { city: '河北 廊坊市' },
    { city: '河北 衡水市' },
    { city: '河北 武安市' },
    { city: '河北 霸州市' },
    { city: '河北 泊头市' },
    { city: '河北 任丘市' },
    { city: '河北 黄骅市' },
    { city: '河北 河间市' },
    { city: '河北 迁安市' },
    { city: '河北 遵化市' },
    { city: '河北 三河市' },
    { city: '河北 定州市' },
    { city: '河北 安国市' },
    { city: '河北 涿州市' },
    { city: '河北 高碑店市' },
    { city: '河北 辛集市' },
    { city: '河北 晋州市' },
    { city: '河北 藁城市' },
    { city: '河北 新乐市' },
    { city: '河北 鹿泉市' },
    { city: '河北 沙河市' },
    { city: '河北 南宫市' },
    { city: '河北 冀州市' },
    { city: '河北 深州市' },
    { city: '辽宁 沈阳市' },
    { city: '辽宁 大连市' },
    { city: '辽宁 鞍山市' },
    { city: '辽宁 抚顺市' },
    { city: '辽宁 本溪市' },
    { city: '辽宁 丹东市' },
    { city: '辽宁 锦州市' },
    { city: '辽宁 葫芦岛市' },
    { city: '辽宁 营口市' },
    { city: '辽宁 阜新市' },
    { city: '辽宁 辽阳市' },
    { city: '辽宁 铁岭市' },
    { city: '辽宁 朝阳市' },
    { city: '辽宁 盘锦市' },
    { city: '辽宁 瓦房店市' },
    { city: '辽宁 海城市' },
    { city: '辽宁 兴城市' },
    { city: '辽宁 调兵山市' },
    { city: '辽宁 北票市' },
    { city: '辽宁 开原市' },
    { city: '辽宁 新民市' },
    { city: '辽宁 庄河市' },
    { city: '辽宁 凤城市' },
    { city: '辽宁 北镇市' },
    { city: '辽宁 灯塔市' },
    { city: '辽宁 凌源市' },
    { city: '辽宁 盖州市' },
    { city: '辽宁 东港市' },
    { city: '辽宁 普兰店市' },
    { city: '辽宁 凌海市' },
    { city: '辽宁 大石桥市' },
    { city: '吉林 长春市' },
    { city: '吉林 吉林市' },
    { city: '吉林 四平市' },
    { city: '吉林 辽源市' },
    { city: '吉林 通化市' },
    { city: '吉林 白城市' },
    { city: '吉林 白山市' },
    { city: '吉林 松原市' },
    { city: '吉林 延边朝鲜族自治州' },
    { city: '吉林 公主岭市' },
    { city: '吉林 梅河口市' },
    { city: '吉林 集安市' },
    { city: '吉林 九台市' },
    { city: '吉林 桦甸市' },
    { city: '吉林 蛟河市' },
    { city: '吉林 榆树市' },
    { city: '吉林 洮南市' },
    { city: '吉林 大安市' },
    { city: '吉林 延吉市' },
    { city: '吉林 图们市' },
    { city: '吉林 敦化市' },
    { city: '吉林 龙井市' },
    { city: '吉林 珲春市' },
    { city: '吉林 德惠市' },
    { city: '吉林 磐石市' },
    { city: '吉林 舒兰市' },
    { city: '吉林 双辽市' },
    { city: '吉林 和龙市' },
    { city: '吉林 临江市' },
    { city: '黑龙江 哈尔滨市' },
    { city: '黑龙江 齐齐哈尔市' },
    { city: '黑龙江 鹤岗市' },
    { city: '黑龙江 双鸭山市' },
    { city: '黑龙江 鸡西市' },
    { city: '黑龙江 大庆市' },
    { city: '黑龙江 伊春市' },
    { city: '黑龙江 牡丹江市' },
    { city: '黑龙江 佳木斯市' },
    { city: '黑龙江 七台河市' },
    { city: '黑龙江 绥化市' },
    { city: '黑龙江 黑河市' },
    { city: '黑龙江 大兴安岭地区' },
    { city: '黑龙江 绥芬河市' },
    { city: '黑龙江 同江市' },
    { city: '黑龙江 富锦市' },
    { city: '黑龙江 铁力市' },
    { city: '黑龙江 密山市' },
    { city: '黑龙江 安达市' },
    { city: '黑龙江 肇东市' },
    { city: '黑龙江 海伦市' },
    { city: '黑龙江 北安市' },
    { city: '黑龙江 五大连池市' },
    { city: '黑龙江 尚志市' },
    { city: '黑龙江 双城市' },
    { city: '黑龙江 讷河市' },
    { city: '黑龙江 海林市' },
    { city: '黑龙江 穆棱市' },
    { city: '黑龙江 虎林市' },
    { city: '黑龙江 宁安市' },
    { city: '黑龙江 五常市' },
    { city: '江苏 南京市' },
    { city: '江苏 徐州市' },
    { city: '江苏 连云港市' },
    { city: '江苏 盐城市' },
    { city: '江苏 扬州市' },
    { city: '江苏 南通市' },
    { city: '江苏 镇江市' },
    { city: '江苏 常州市' },
    { city: '江苏 无锡市' },
    { city: '江苏 苏州市' },
    { city: '江苏 泰州市' },
    { city: '江苏 宿迁市' },
    { city: '江苏 淮安市' },
    { city: '江苏 仪征市' },
    { city: '江苏 常熟市' },
    { city: '江苏 张家港市' },
    { city: '江苏 江阴市' },
    { city: '江苏 丹阳市' },
    { city: '江苏 东台市' },
    { city: '江苏 兴化市' },
    { city: '江苏 宜兴市' },
    { city: '江苏 昆山市' },
    { city: '江苏 启东市' },
    { city: '江苏 新沂市' },
    { city: '江苏 溧阳市' },
    { city: '江苏 大丰市' },
    { city: '江苏 泰兴市' },
    { city: '江苏 江都市' },
    { city: '江苏 靖江市' },
    { city: '江苏 高邮市' },
    { city: '江苏 如皋市' },
    { city: '江苏 海门市' },
    { city: '江苏 句容市' },
    { city: '江苏 扬中市' },
    { city: '江苏 金坛市' },
    { city: '江苏 吴江市' },
    { city: '江苏 太仓市' },
    { city: '江苏 通州市' },
    { city: '江苏 邳州市' },
    { city: '江苏 姜堰市' },
    { city: '安徽 合肥市' },
    { city: '安徽 淮南市' },
    { city: '安徽 淮北市' },
    { city: '安徽 芜湖市' },
    { city: '安徽 铜陵市' },
    { city: '安徽 蚌埠市' },
    { city: '安徽 马鞍山市' },
    { city: '安徽 安庆市' },
    { city: '安徽 黄山市' },
    { city: '安徽 宿州市' },
    { city: '安徽 滁州市' },
    { city: '安徽 巢湖市' },
    { city: '安徽 六安市' },
    { city: '安徽 阜阳市' },
    { city: '安徽 亳州市' },
    { city: '安徽 宣城市' },
    { city: '安徽 池州市' },
    { city: '安徽 界首市' },
    { city: '安徽 桐城市' },
    { city: '安徽 天长市' },
    { city: '安徽 宁国市' },
    { city: '安徽 明光市' },
    { city: '山东 济南市' },
    { city: '山东 青岛市' },
    { city: '山东 淄博市' },
    { city: '山东 枣庄市' },
    { city: '山东 东营市' },
    { city: '山东 烟台市' },
    { city: '山东 威海市' },
    { city: '山东 济宁市' },
    { city: '山东 泰安市' },
    { city: '山东 日照市' },
    { city: '山东 莱芜市' },
    { city: '山东 德州市' },
    { city: '山东 滨州市' },
    { city: '山东 临沂市' },
    { city: '山东 聊城市' },
    { city: '山东 潍坊市' },
    { city: '山东 菏泽市' },
    { city: '山东 青州市' },
    { city: '山东 龙口市' },
    { city: '山东 曲阜市' },
    { city: '山东 新泰市' },
    { city: '山东 胶州市' },
    { city: '山东 诸城市' },
    { city: '山东 莱阳市' },
    { city: '山东 莱州市' },
    { city: '山东 滕州市' },
    { city: '山东 文登市' },
    { city: '山东 荣成市' },
    { city: '山东 即墨市' },
    { city: '山东 平度市' },
    { city: '山东 莱西市' },
    { city: '山东 胶南市' },
    { city: '山东 乐陵市' },
    { city: '山东 临清市' },
    { city: '山东 章丘市' },
    { city: '山东 昌邑市' },
    { city: '山东 高密市' },
    { city: '山东 安丘市' },
    { city: '山东 寿光市' },
    { city: '山东 招远市' },
    { city: '山东 栖霞市' },
    { city: '山东 海阳市' },
    { city: '山东 蓬莱市' },
    { city: '山东 乳山市' },
    { city: '山东 兖州市' },
    { city: '山东 肥城市' },
    { city: '山东 禹城市' },
    { city: '山东 邹城市' },
    { city: '浙江 杭州市' },
    { city: '浙江 宁波市' },
    { city: '浙江 温州市' },
    { city: '浙江 嘉兴市' },
    { city: '浙江 湖州市' },
    { city: '浙江 绍兴市' },
    { city: '浙江 金华市' },
    { city: '浙江 衢州市' },
    { city: '浙江 舟山市' },
    { city: '浙江 丽水市' },
    { city: '浙江 台州市' },
    { city: '浙江 余姚市' },
    { city: '浙江 海宁市' },
    { city: '浙江 兰溪市' },
    { city: '浙江 瑞安市' },
    { city: '浙江 江山市' },
    { city: '浙江 东阳市' },
    { city: '浙江 义乌市' },
    { city: '浙江 慈溪市' },
    { city: '浙江 奉化市' },
    { city: '浙江 诸暨市' },
    { city: '浙江 临海市' },
    { city: '浙江 龙泉市' },
    { city: '浙江 临安市' },
    { city: '浙江 富阳市' },
    { city: '浙江 建德市' },
    { city: '浙江 乐清市' },
    { city: '浙江 平湖市' },
    { city: '浙江 桐乡市' },
    { city: '浙江 上虞市' },
    { city: '浙江 永康市' },
    { city: '浙江 温岭市' },
    { city: '浙江 嵊州市' },
    { city: '江西 南昌市' },
    { city: '江西 景德镇市' },
    { city: '江西 萍乡市' },
    { city: '江西 新余市' },
    { city: '江西 九江市' },
    { city: '江西 鹰潭市' },
    { city: '江西 上饶市' },
    { city: '江西 宜春市' },
    { city: '江西 吉安市' },
    { city: '江西 赣州市' },
    { city: '江西 抚州市' },
    { city: '江西 瑞昌市' },
    { city: '江西 德兴市' },
    { city: '江西 丰城市' },
    { city: '江西 樟树市' },
    { city: '江西 乐平市' },
    { city: '江西 贵溪市' },
    { city: '江西 高安市' },
    { city: '江西 南康市' },
    { city: '江西 瑞金市' },
    { city: '江西 井冈山市' },
    { city: '福建 福州市' },
    { city: '福建 厦门市' },
    { city: '福建 三明市' },
    { city: '福建 莆田市' },
    { city: '福建 泉州市' },
    { city: '福建 漳州市' },
    { city: '福建 南平市' },
    { city: '福建 宁德市' },
    { city: '福建 龙岩市' },
    { city: '福建 永安市' },
    { city: '福建 石狮市' },
    { city: '福建 福清市' },
    { city: '福建 邵武市' },
    { city: '福建 武夷山市' },
    { city: '福建 福安市' },
    { city: '福建 漳平市' },
    { city: '福建 长乐市' },
    { city: '福建 南安市' },
    { city: '福建 晋江市' },
    { city: '福建 龙海市' },
    { city: '福建 建阳市' },
    { city: '福建 建瓯市' },
    { city: '福建 福鼎市' },
    { city: '湖南 长沙市' },
    { city: '湖南 株洲市' },
    { city: '湖南 湘潭市' },
    { city: '湖南 衡阳市' },
    { city: '湖南 岳阳市' },
    { city: '湖南 常德市' },
    { city: '湖南 张家界市' },
    { city: '湖南 郴州市' },
    { city: '湖南 永州市' },
    { city: '湖南 娄底市' },
    { city: '湖南 怀化市' },
    { city: '湖南 益阳市' },
    { city: '湖南 邵阳市' },
    { city: '湖南 湘西土家族苗族自治州' },
    { city: '湖南 醴陵市' },
    { city: '湖南 湘乡市' },
    { city: '湖南 津市市' },
    { city: '湖南 韶山市' },
    { city: '湖南 资兴市' },
    { city: '湖南 冷水江市' },
    { city: '湖南 涟源市' },
    { city: '湖南 洪江市' },
    { city: '湖南 沅江市' },
    { city: '湖南 吉首市' },
    { city: '湖南 浏阳市' },
    { city: '湖南 武冈市' },
    { city: '湖南 临湘市' },
    { city: '湖南 耒阳市' },
    { city: '湖南 常宁市' },
    { city: '湖南 汨罗市' },
    { city: '湖北 武汉市' },
    { city: '湖北 黄石市' },
    { city: '湖北 襄樊市' },
    { city: '湖北 荆州市' },
    { city: '湖北 荆门市' },
    { city: '湖北 鄂州市' },
    { city: '湖北 随州市' },
    { city: '湖北 孝感市' },
    { city: '湖北 咸宁市' },
    { city: '湖北 仙桃市' },
    { city: '湖北 天门市' },
    { city: '湖北 潜江市' },
    { city: '湖北 宜昌市' },
    { city: '湖北 十堰市' },
    { city: '湖北 神农架林区' },
    { city: '湖北 黄冈市' },
    { city: '湖北 恩施土家族苗族自治州' },
    { city: '湖北 老河口市' },
    { city: '湖北 枣阳市' },
    { city: '湖北 应城市' },
    { city: '湖北 安陆市' },
    { city: '湖北 广水市' },
    { city: '湖北 麻城市' },
    { city: '湖北 武穴市' },
    { city: '湖北 石首市' },
    { city: '湖北 洪湖市' },
    { city: '湖北 当阳市' },
    { city: '湖北 丹江口市' },
    { city: '湖北 恩施市' },
    { city: '湖北 利川市' },
    { city: '湖北 汉川市' },
    { city: '湖北 钟祥市' },
    { city: '湖北 松滋市' },
    { city: '湖北 枝江市' },
    { city: '湖北 大冶市' },
    { city: '湖北 宜都市' },
    { city: '湖北 赤壁市' },
    { city: '河南 郑州市' },
    { city: '河南 开封市' },
    { city: '河南 洛阳市' },
    { city: '河南 平顶山市' },
    { city: '河南 焦作市' },
    { city: '河南 鹤壁市' },
    { city: '河南 新乡市' },
    { city: '河南 安阳市' },
    { city: '河南 濮阳市' },
    { city: '河南 许昌市' },
    { city: '河南 漯河市' },
    { city: '河南 三门峡市' },
    { city: '河南 济源市' },
    { city: '河南 商丘市' },
    { city: '河南 周口市' },
    { city: '河南 驻马店市' },
    { city: '河南 信阳市' },
    { city: '河南 南阳市' },
    { city: '河南 义马市' },
    { city: '河南 汝州市' },
    { city: '河南 禹州市' },
    { city: '河南 卫辉市' },
    { city: '河南 辉县市' },
    { city: '河南 沁阳市' },
    { city: '河南 舞钢市' },
    { city: '河南 邓州市' },
    { city: '河南 荥阳市' },
    { city: '河南 登封市' },
    { city: '河南 新郑市' },
    { city: '河南 偃师市' },
    { city: '河南 长葛市' },
    { city: '河南 灵宝市' },
    { city: '河南 永城市' },
    { city: '河南 项城市' },
    { city: '河南 巩义市' },
    { city: '河南 林州市' },
    { city: '河南 新密市' },
    { city: '河南 孟州市' },
    { city: '广东 广州市' },
    { city: '广东 深圳市' },
    { city: '广东 珠海市' },
    { city: '广东 汕头市' },
    { city: '广东 韶关市' },
    { city: '广东 河源市' },
    { city: '广东 梅州市' },
    { city: '广东 惠州市' },
    { city: '广东 汕尾市' },
    { city: '广东 东莞市' },
    { city: '广东 中山市' },
    { city: '广东 江门市' },
    { city: '广东 佛山市' },
    { city: '广东 阳江市' },
    { city: '广东 湛江市' },
    { city: '广东 茂名市' },
    { city: '广东 肇庆市' },
    { city: '广东 清远市' },
    { city: '广东 潮州市' },
    { city: '广东 揭阳市' },
    { city: '广东 云浮市' },
    { city: '广东 从化市' },
    { city: '广东 增城市' },
    { city: '广东 南雄市' },
    { city: '广东 乐昌市' },
    { city: '广东 兴宁市' },
    { city: '广东 陆丰市' },
    { city: '广东 台山市' },
    { city: '广东 开平市' },
    { city: '广东 恩平市' },
    { city: '广东 鹤山市' },
    { city: '广东 阳春市' },
    { city: '广东 吴川市' },
    { city: '广东 廉江市' },
    { city: '广东 高州市' },
    { city: '广东 信宜市' },
    { city: '广东 化州市' },
    { city: '广东 高要市' },
    { city: '广东 四会市' },
    { city: '广东 罗定市' },
    { city: '广东 英德市' },
    { city: '广东 连州市' },
    { city: '广东 普宁市' },
    { city: '广东 雷州市' },
    { city: '海南 海口市' },
    { city: '海南 三亚市' },
    { city: '海南 五指山市' },
    { city: '海南 文昌市' },
    { city: '海南 琼海市' },
    { city: '海南 万宁市' },
    { city: '海南 东方市' },
    { city: '海南 儋州市' },
    { city: '广西 南宁市' },
    { city: '广西 柳州市' },
    { city: '广西 桂林市' },
    { city: '广西 梧州市' },
    { city: '广西 北海市' },
    { city: '广西 玉林市' },
    { city: '广西 贵港市' },
    { city: '广西 百色市' },
    { city: '广西 河池市' },
    { city: '广西 来宾市' },
    { city: '广西 贺州市' },
    { city: '广西 钦州市' },
    { city: '广西 祟左' },
    { city: '广西 防城港市' },
    { city: '广西 凭祥市' },
    { city: '广西 合山市' },
    { city: '广西 崇左市' },
    { city: '广西 岑溪市' },
    { city: '广西 桂平市' },
    { city: '广西 北流市' },
    { city: '广西 宜州市' },
    { city: '广西 东兴市' },
    { city: '贵州 贵阳市' },
    { city: '贵州 六盘水市' },
    { city: '贵州 遵义市' },
    { city: '贵州 安顺市' },
    { city: '贵州 黔西南布依族苗族自治州' },
    { city: '贵州 黔东南苗族侗族自治州' },
    { city: '贵州 黔南布依族苗族自治州' },
    { city: '贵州 铜仁地区' },
    { city: '贵州 毕节地区' },
    { city: '贵州 赤水市' },
    { city: '贵州 铜仁市' },
    { city: '贵州 兴义市' },
    { city: '贵州 凯里市' },
    { city: '贵州 都匀市' },
    { city: '贵州 仁怀市' },
    { city: '贵州 毕节市' },
    { city: '贵州 清镇市' },
    { city: '贵州 福泉市' },
    { city: '四川 成都市' },
    { city: '四川 自贡市' },
    { city: '四川 攀枝花市' },
    { city: '四川 泸州市' },
    { city: '四川 德阳市' },
    { city: '四川 绵阳市' },
    { city: '四川 广元市' },
    { city: '四川 遂宁市' },
    { city: '四川 内江市' },
    { city: '四川 乐山市' },
    { city: '四川 南充市' },
    { city: '四川 宜宾市' },
    { city: '四川 广安市' },
    { city: '四川 巴中市' },
    { city: '四川 雅安市' },
    { city: '四川 眉山市' },
    { city: '四川 阿坝藏族羌族自治州' },
    { city: '四川 甘孜藏族自治州' },
    { city: '四川 凉山彝族自治州' },
    { city: '四川 资阳市' },
    { city: '四川 达州市' },
    { city: '四川 广汉市' },
    { city: '四川 西昌市' },
    { city: '四川 江油市' },
    { city: '四川 彭州市' },
    { city: '四川 崇州市' },
    { city: '四川 都江堰市' },
    { city: '四川 邛崃市' },
    { city: '四川 峨眉山市' },
    { city: '四川 简阳市' },
    { city: '四川 绵竹市' },
    { city: '四川 什邡市' },
    { city: '四川 阆中市' },
    { city: '四川 华蓥市' },
    { city: '四川 万源市' },
    { city: '云南 昆明市' },
    { city: '云南 昭通市' },
    { city: '云南 曲靖市' },
    { city: '云南 玉溪市' },
    { city: '云南 保山市' },
    { city: '云南 普洱市' },
    { city: '云南 临沧市' },
    { city: '云南 丽江市' },
    { city: '云南 西双版纳傣族自治州' },
    { city: '云南 德宏傣族景颇族自治州' },
    { city: '云南 怒江傈僳族自治州' },
    { city: '云南 迪庆藏族自治州' },
    { city: '云南 文山壮族苗族自治州' },
    { city: '云南 红河哈尼族彝族自治州' },
    { city: '云南 楚雄彝族自治州' },
    { city: '云南 大理白族自治州' },
    { city: '云南 个旧市' },
    { city: '云南 开远市' },
    { city: '云南 楚雄市' },
    { city: '云南 大理市' },
    { city: '云南 安宁市' },
    { city: '云南 宣威市' },
    { city: '云南 景洪市' },
    { city: '云南 潞西市' },
    { city: '云南 瑞丽市' },
    { city: '陕西 西安市' },
    { city: '陕西 宝鸡市' },
    { city: '陕西 咸阳市' },
    { city: '陕西 榆林市' },
    { city: '陕西 延安市' },
    { city: '陕西 渭南市' },
    { city: '陕西 安康市' },
    { city: '陕西 汉中市' },
    { city: '陕西 铜川市' },
    { city: '陕西 商洛市' },
    { city: '陕西 韩城市' },
    { city: '陕西 华阴市' },
    { city: '陕西 兴平市' },
    { city: '甘肃 兰州市' },
    { city: '甘肃 金昌市' },
    { city: '甘肃 白银市' },
    { city: '甘肃 天水市' },
    { city: '甘肃 嘉峪关市' },
    { city: '甘肃 平凉市' },
    { city: '甘肃 武威市' },
    { city: '甘肃 张掖市' },
    { city: '甘肃 酒泉市' },
    { city: '甘肃 定西市' },
    { city: '甘肃 庆阳市' },
    { city: '甘肃 陇南市' },
    { city: '甘肃 临夏回族自治州' },
    { city: '甘肃 甘南藏族自治州' },
    { city: '甘肃 玉门市' },
    { city: '甘肃 临夏市' },
    { city: '甘肃 合作市' },
    { city: '宁夏 银川市' },
    { city: '宁夏 石嘴山市' },
    { city: '宁夏 吴忠市' },
    { city: '宁夏 固原市' },
    { city: '宁夏 青铜峡市' },
    { city: '宁夏 灵武市' },
    { city: '宁夏 中卫市' },
    { city: '青海 西宁市' },
    { city: '青海 海东地区' },
    { city: '青海 海北藏族自治州' },
    { city: '青海 黄南藏族自治州' },
    { city: '青海 海南藏族自治州' },
    { city: '青海 果洛藏族自治州' },
    { city: '青海 玉树藏族自治州' },
    { city: '青海 海西蒙古族藏族自治州' },
    { city: '青海 格尔木市' },
    { city: '青海 德令哈市' },
    { city: '青海 冷湖行委' },
    { city: '新疆 乌鲁木齐市' },
    { city: '新疆 克拉玛依市' },
    { city: '新疆 石河子市' },
    { city: '新疆 五家渠市' },
    { city: '新疆 阿拉尔市' },
    { city: '新疆 博尔塔拉蒙古自治州' },
    { city: '新疆 巴音郭楞蒙古自治州' },
    { city: '新疆 克孜勒苏柯尔克孜自治州' },
    { city: '新疆 伊犁哈萨克自治州' },
    { city: '新疆 图木舒克市' },
    { city: '新疆 吐鲁番地区' },
    { city: '新疆 哈密地区' },
    { city: '新疆 和田地区' },
    { city: '新疆 阿克苏地区' },
    { city: '新疆 喀什地区' },
    { city: '新疆 昌吉回族自治州' },
    { city: '新疆 塔城地区' },
    { city: '新疆 阿勒泰地区' },
    { city: '新疆 吐鲁番地区' },
    { city: '新疆 吐鲁番市' },
    { city: '新疆 哈密市' },
    { city: '新疆 和田市' },
    { city: '新疆 阿克苏市' },
    { city: '新疆 喀什市' },
    { city: '新疆 阿图什市' },
    { city: '新疆 库尔勒市' },
    { city: '新疆 昌吉市' },
    { city: '新疆 博乐市' },
    { city: '新疆 伊宁市' },
    { city: '新疆 塔城市' },
    { city: '新疆 阿勒泰市' },
    { city: '新疆 阜康市' },
    { city: '新疆 乌苏市' },
    { city: '新疆 奎屯市' },
    { city: '新疆 博乐市' },
    { city: '西藏 拉萨市' },
    { city: '西藏 山南地区' },
    { city: '西藏 阿里地区' },
    { city: '西藏 林芝地区' },
    { city: '西藏 山南地区' },
    { city: '西藏 日喀则地区' },
    { city: '西藏 那曲地区' },
    { city: '西藏 昌都地区' },
    { city: '西藏 日喀则市' },
    { city: '西藏 那曲县' },
    { city: '西藏 昌都县' },
    { city: '西藏 林芝县' },
    { city: '香港 香港地区' },
    { city: '澳门 澳门地区' },
    { city: '台湾 台湾全省' },
  ]
}
