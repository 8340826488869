import { HttpService } from '../services/http.service';
import { Md5 } from "ts-md5";
import { NzModalService } from 'ng-zorro-antd';
import { Router, ActivatedRoute , NavigationExtras} from '@angular/router';
import { StorageService } from '../services/storage.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-cooperate',
  templateUrl: './cooperate.component.html',
  styleUrls: ['./cooperate.component.less']
})
export class CooperateComponent {

  public userinfo: any = {};     //本地保存的用户登录信息
  newuserinfo: any = {};
  unreadMessageNum = 0;   //未读私信数量，显示在顶部信封
  unreadNoticeNum = 0;    //未读通知数量，显示在顶部小铃铛

  unreadMessageList: any = [];    //未读私信列表，显示在顶部信封下拉列表
  unreadNoticeList: any = [];     //未读通知列表，显示在顶部小铃铛下拉列表
  noticeType = 1;
  pgdou = 0; //pg豆数量
  browseModal = false;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
  ) { 
    window.scrollTo(0,0);
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userinfo = this.local.get('userinfo');
    }
  }

  ngOnInit() {
     //获取未读私信user/user/myacceptedMessage
     this.getUnreadMessages();
     //获取未读消息通知user/notice/noticeList
     this.getNoticeCount();
     this.getUnreadNotices(1);
  }

  
  /**
   * 获取未读私信user/user/myacceptedMessage
   */
  getUnreadMessages() {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|myacceptedmessage');
    var api = 'user/user/myacceptedMessage';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
    }
    this.http.doPost(api, body, (result) => {
      // console.log("messages:", result);
      if (result.code == "200") {
        this.unreadMessageNum = result.data.length;
        if (result.data != null) {
          this.unreadMessageList = result.data;
        }
      }
    })
  }
  /**
   * 获取未读消息通知user/notice/noticeList
   */
  getUnreadNotices(type) {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    this.noticeType = type;
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|noticelist');
    var api = 'user/notice/noticeList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'type': type,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        if (result.data != null) {
          // this.unreadNoticeList = result.data;
          this.unreadNoticeList = result.data.filter(notice => {
            return notice.status == 0;
          })
          // console.log("新的unreadNoticeList：", this.unreadNoticeList);
        }
      }
    })
  }
  /**
   * 获取未读消息数量user/notice/getNoticeCount
   */
  getNoticeCount() {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnoticecount');
    var api = 'user/notice/getNoticeCount';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
    }
    this.http.doPost(api, body, (result) => {
      // console.log("noticeNum:", result);
      if (result.code == "200") {
        this.unreadNoticeNum = result.data.count;
      }
    })
  }
  doOperate(notice, key) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|noticeoperate');
    var api = 'user/notice/noticeOperate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'op_type': notice.type,
      'notice_id': notice.id,
    }
    if (key == 0) {
      body.notice_id = 0;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        // this.unreadNoticeNum = result.data.count;
        this.unreadNoticeNum--;
        this.unreadNoticeList.splice(key, 1)
        if (key == -1) {
          this.getNoticeCount();
          this.unreadNoticeList = [];
        }
      }
    })
  }
  isShowMessageDetail = false;
  mDetail: any = {};
  mDetailUser = '';
  mDetailTitle = '';
  mDetailContent = '';
  doRead(message, key) {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|setmessagereaded');
    var api = 'user/user/setMessageReaded';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'message_id': message.id,
    }
    if (key == -1) {
      body.message_id = -1;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.unreadMessageNum--;
        this.unreadMessageList.splice(key, 1);
        this.isShowMessageDetail = true;
        this.mDetail = message;
        this.mDetailUser = message.user_login;
        this.mDetailTitle = message.title;
        this.mDetailContent = message.content;
        if (key == -1) {
          this.getUnreadMessages()
        }
      }
    })
  }

  sendTitle = '';
  sendContent = '';
  sendUser = 0;
  isShowSendMessage = false;    //是否显示发送私信对话框
  reply(mDetail) {
    this.sendTitle = mDetail.user_login;
    this.sendContent = '';
    this.sendUser = mDetail.user_id;
    this.isShowSendMessage = true;
  }
  send() {
    this.isShowSendMessage = false;
    this.doSend();
  }
  sendCancel() {
    this.isShowSendMessage = false;
    this.browseModal = false;
  }
  /**
   * 发送私信user/user/sendPrivateMessage
   */
  doSend() {
    if (this.local.get('userinfo') == null) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|sendprivatemessage');
    var api = 'user/user/sendPrivateMessage';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'title': '无标题',
      'content': this.sendContent,
      'accepted_user_id': this.sendUser
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.modalService.success({
          nzTitle: "系统提示",
          nzContent: "私信发送成功",
          nzOkText: "关闭",
          nzOnOk: () => {
            this.sendTitle = '';
            this.sendContent = '';
          },
        })
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: "发送私信失败，请联系管理员",
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  doOperateAll() {
    // if (this.unreadNoticeList != null && this.unreadNoticeList.length > 0) {
    //   this.doOperate(this.unreadNoticeList[0], 0);
    // }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|noticeoperate');
    var api = 'user/notice/noticeOperate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'op_type': 5,
      'notice_id': 0,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.getNoticeCount();
        this.getUnreadNotices(1);
      }
    })
  }
  doReadAll() {
    // if (this.unreadMessageList != null && this.unreadMessageList.length > 0) {
    //   this.doRead(this.unreadMessageList[0], -1);
    // }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|setmessagereaded');
    var api = 'user/user/setMessageReaded';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'message_id': -1,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.getUnreadMessages();
      }
    })
  }

   /**
   * 跳转到user页面，通过传值直接指向最终页面，默认home
   * @param val 页面默认展示：
   * 0=我的主页home；1=我的文章article；2=我的回答answer；3=我的关注focus；4=我的收藏collect；5=个人资料data
   */
  goUserPage(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'userId': this.local.get('userinfo').id, },
    }
    this.router.navigate(['/user/' + val], navigationExtras);
    // window.location.replace('/user/' + val + '?userId=' + this.local.get('userinfo').id);
  }

   /**登出 */
   doLogout() {
    // this.local.set('userinfo', '');
    localStorage.removeItem('userinfo');
    localStorage.removeItem('isSure');
    // window.location.replace('#/login');
    window.location.replace('');
  }

  /**
   * 建议反馈
   */
  feedback(){
    this.router.navigate(['/feedback']);
  }


}
