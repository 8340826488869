import { Component, OnInit } from '@angular/core';
import { Md5 } from 'ts-md5';
import { HttpService } from '../../../services/http.service';
import { Router, ActivatedRoute, NavigationExtras, RoutesRecognized } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from '../../../services/storage.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { saveAs } from 'file-saver';
import { defaultOptions, renderAsync } from "docx-preview";
@Component({
  selector: 'app-try-manual',
  templateUrl: './try-manual.component.html',
  styleUrls: ['./try-manual.component.less']
})
export class TryManualComponent implements OnInit {
  content:any = '';

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
    private message: NzMessageService,
  ) {
    route.queryParams.subscribe((data) => {
      var id = data.id;
      this. trySee(id);
    });
   }

  ngOnInit() {
  }
   //试看
  trySee(id){
    const msg = this.message.loading('正在打开文件...', { nzDuration: 0 }).messageId;
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getcontent');
    var api = 'user/pgdou/getContent';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'id': id,
      'type':1,
    }
    this.http.doPost(api, body,(result)=>{
      console.log('result:',result)
      return
      // const blob = new Blob([result],{type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});  
      const blob = new Blob([result], {type:'application/pdf'})
      console.log('blob:', blob)
      var test = setTimeout(() =>{
        if(blob!=null){
          console.log('获取到内容啦');
          this.message.remove(msg);
          clearTimeout(test);
          
        }
      }, 1000)
      let blobUrl = window.URL.createObjectURL(blob);
      console.log('blobUrl:', blobUrl)
      // window.location.href = blobUrl
      // $('#pdf').attr('src',blobUrl)
      renderAsync(blob, document.getElementById("pdf"), null,{
       className: "docx", // 默认和文档样式类的类名/前缀
       inWrapper: true, // 启用围绕文档内容渲染包装器
       ignoreWidth: false, // 禁止页面渲染宽度
       ignoreHeight: false, // 禁止页面渲染高度
       ignoreFonts: false, // 禁止字体渲染
       breakPages: true, // 在分页符上启用分页
       ignoreLastRenderedPageBreak: true,//禁用lastRenderedPageBreak元素的分页
       experimental: false, //启用实验性功能（制表符停止计算）
       trimXmlDeclaration: true, //如果为真，xml声明将在解析之前从xml文档中删除
       debug: false, // 启用额外的日志记录
   }
);

    })
  }

}
