import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { HttpService } from '../../services/http.service';
import { CartService } from '../../services/cart.service';
import { NzModalService } from 'ng-zorro-antd';
import { Router,ActivatedRoute } from '@angular/router';
import { Md5 } from "ts-md5";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.less']
})
export class OrderConfirmComponent implements OnInit {

  address_id = 0;
  address:any = []; // 收获地址详情
  addresslist:any = []; // 所有收货地址
  validateForm: FormGroup;
  add_address:any = []; // 新增收货地址信息
  isAdd = false; // 是否显示新增地址框
  pgdou = 0; // 用户可用pgdou
  t_pgdou = 0; // 总计需花费pg豆
  region:any = []; // 省市区

  isEdit = false; // 编辑弹框

  GoodsList:any = []; // 好物信息
  order_type = 2; //1:立即兑换；2：提交购物车
  addid = 0;
  stock = 1; // 库存

  constructor(
    private local: StorageService,
    private http: HttpService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cart: CartService,
  ) { 
    window.scroll(0,0);
    route.queryParams.subscribe((data) => {
      this.order_type = data.type;
      if(this.order_type == 1){
        this.GoodsList.push(data);
        if(this.GoodsList[0].stock == 0){
          this.modalService.error({
            nzTitle: "系统提示",
            nzContent: "该好物已兑完，您可以返回兑换其他好物！",
            nzOkText: "关闭",
            nzOnOk: () => { 
              window.history.back();
            },
          });
          return;
        }
        this.getPgdou();
      }

      // else{
      //   this.GoodsList = this.cart.getCheckedGoods();
      //   if(this.GoodsList.length == 0){
      //     this.modalService.error({
      //       nzTitle: "系统提示",
      //       nzContent: "订单失效，请返回重新结算！",
      //       nzOkText: "关闭",
      //       nzOnOk: () => { 
      //         this.router.navigate(['/cart'],{queryParams: {userId:local.get('userinfo').id}});
      //       },
      //     });
      //   }
      // }
    });
    
  }

  ngOnInit() {
    this.getAddress();  
    this.getRegion();
    this.validateForm = this.fb.group({
        consignee: [null, [Validators.required]],
        b_address: [null, [Validators.required]],
        d_address: [null, [Validators.required]],
        phone: [null, [Validators.required]],
        pc: [null, [Validators.required]],
        required: [false]
      });
    
  }

  //计算总计pg豆
  accTotal(){
    this.t_pgdou = 0;
    for(let i=0;i<this.GoodsList.length;i++){
      this.t_pgdou = this.t_pgdou + parseInt(this.GoodsList[i].total);
    }
    if(this.t_pgdou>this.pgdou){
      this.modalService.error({
        nzTitle: "系统提示",
        nzContent: "您的PG豆不足！",
        nzOkText: "关闭",
        nzOnOk: () => { 
          window.history.back();
          // this.router.navigate(['/cart'],{queryParams: {userId:this.local.get('userinfo').id}});
        },
      });
    }
  }

  //新增收获地址提交表单
  submitForm(add,edit){
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    // console.log(this.validateForm.value)
    if(this.validateForm.status == 'VALID'){
      //获取省市区编号
      this.add_address = this.validateForm.value;
      this.add_address.province = this.validateForm.value.b_address[0]; // 省
      this.add_address.city = this.validateForm.value.b_address[1]; // 市
      this.add_address.area = this.validateForm.value.b_address[2]; // 区
      let a,b;
      for(let i=0;i<this.region.length;i++){
        if(this.region[i].value == this.add_address.province){
          this.add_address.province_code = this.region[i].code;
          a = i;
        }
      }
      for(let j=0;j<this.region[a].children.length;j++){
        if(this.region[a].children[j].value == this.add_address.city ){
          this.add_address.city_code = this.region[a].children[j].code;
          b = j;
        }
      }
      for(let k=0;k<this.region[a].children[b].children.length;k++){
        if(this.region[a].children[b].children[k].value == this.add_address.area){
          this.add_address.area_code = this.region[a].children[b].children[k].code;
        }
      }
      if(add){
        this.doAddAddress();
      }
      if(edit){
        this.doEditAddress();
      }
     
    }
    
  }

  //获取省市区
  getRegion(){
    this.http.Get('assets/json/region.json',(result) =>{
      this.region = result;
      for(let i=0;i<result.length;i++){
        this.region[i].value = result[i].label;
        for(let j=0;j<result[i].children.length;j++){
          this.region[i].children[j].value = result[i].children[j].label;
          for(let k=0;k<result[i].children[j].children.length;k++){
            this.region[i].children[j].children[k].value = result[i].children[j].children[k].label;
          }
        }
      }
    })
  }

  /**
   * 获取收货地址goods/address/addressList
   */
  getAddress(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addresslist');
    var api = 'goods/address/addressList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
    };
    this.http.doPost(api, body, (result) => {
    // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.addresslist = result.data;
          setTimeout(() => {
            for(let i=0;i<this.addresslist.length;i++){
              if(this.addresslist[i].is_default == 1){
                this.addid = this.addresslist[i].id;
                $('#address'+i).css('border','1px solid #00AED9');
                $('#address'+i).find('.triangle').show();
              }
            }
          },100);
          var res = this.addresslist.some(item=>{
            if(item.is_default==1){
                return true;
            }
          })
          if(!res){
            setTimeout(() => {
              $('#address0').css('border','1px solid #00AED9');
              $('#address0').find('.triangle').show();
              this.addid = this.addresslist[0].id;
            },100)
          }
        }else{
          this.addresslist = [];
          this.addid = 0;
        }
        // console.log(this.addresslist)
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }

  checkAddress(key){
    $('.address').css('border','1px dashed #D2D2D2');
    $('#address'+key).css('border','1px solid #00AED9');
    $('.address').find('.triangle').hide();
    $('#address'+key).find('.triangle').show();
    this.addid = this.addresslist[key].id;
  }

  //新增收货地址的显示
  add(){
    this.isAdd = true;
    this.validateForm.setValue({
      consignee: null,
      b_address: null,
      d_address: null,
      phone: null,
      pc: null,
      required: false,
    })
  }

  //取消新增收货地址
  concel(){
    this.isAdd = false;
    this.isEdit = false;
  }

  /**
   * 提交添加收货地址goods/address/addAddress
   */
  doAddAddress(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addaddress');
    var api = 'goods/address/addAddress';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'name':this.add_address.consignee,
      'phone':this.add_address.phone,
      'province': this.add_address.province,
      'province_code': this.add_address.province_code,
      'city': this.add_address.city,
      'city_code':this.add_address.city_code,
      'area': this.add_address.area,
      'area_code':this.add_address.area_code,
      'detail_address':this.add_address.d_address,
      'code':this.add_address.pc,
    };
    this.http.doPost(api, body, (result) => {
    // console.log(result);
      if (result.code == "200") {
        this.isAdd = false;
        this.isEdit = false;
        this.modalService.success({
          nzTitle: "系统提示",
          nzContent: "新增收货地址成功！",
          nzOkText: "确定",
          nzOnOk: () => {
            this.getAddress();
           },
        });
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }

  /**
   * 编辑收货地址
   */
  editAddress(id){
    event.stopPropagation();
    this.isEdit = true;
    this.address_id = id;
    this.getAddressDetail(id);
    setTimeout(() => {
      this.validateForm.setValue({
        consignee: this.address.name,
        b_address: [this.address.province,this.address.city,this.address.area],
        d_address: this.address.detail_address,
        phone: this.address.phone,
        pc: this.address.code,
        required: false,
      })
    }, 100); 
  }

  /**
   * 提交修改收货地址goods/address/editAddress
   */
  doEditAddress(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|editaddress');
    var api = 'goods/address/editAddress';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'address_id': this.address_id,
      'user_id': this.local.get('userinfo').id,
      'name':this.add_address.consignee,
      'phone':this.add_address.phone,
      'province': this.add_address.province,
      'province_code': this.add_address.province_code,
      'city': this.add_address.city,
      'city_code':this.add_address.city_code,
      'area': this.add_address.area,
      'area_code':this.add_address.area_code,
      'detail_address':this.add_address.d_address,
      'code':this.add_address.pc,
    };
    this.http.doPost(api, body, (result) => {
    // console.log(result);
      if (result.code == "200") {
        this.isEdit = false;
        this.isAdd = false;
        this.modalService.success({
          nzTitle: "系统提示",
          nzContent: "修改收货地址成功！",
          nzOkText: "确定",
          nzOnOk: () => {
            this.getAddress();
           },
        });
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }
  /**
   * 删除收货地址goods/address/deleteAddress
   */
  del_address(){
    this.modalService.confirm({
      nzTitle: '删除地址',
      nzContent: '确认要删除该收货地址吗？',
      nzOnOk: () => {
        let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|deleteaddress');
        var api = 'goods/address/deleteAddress';
        var body = {
          'timestamp': new Date().getTime().toString().substr(0, 10),
          'signature': signature,
          'address_id': this.address_id,
          'user_id': this.local.get('userinfo').id,
        };
        this.http.doPost(api, body, (result) => {
        // console.log(result);
          if (result.code == "200") {
            this.isEdit = false;
            this.isAdd = false;
            this.modalService.success({
              nzTitle: "系统提示",
              nzContent: "删除收货地址成功！",
              nzOkText: "确定",
              nzOnOk: () => {
                this.getAddress();
              },
            });
          } else {
            this.modalService.error({
              nzTitle: "系统提示",
              nzContent: result.message,
              nzOkText: "关闭",
              nzOnOk: () => { },
            });
          }
        });
      }
    });  
    
  }

  /**
   * 获取收货地址详情/goods/address/getAddress
   */
  getAddressDetail(id){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getaddress');
    var api = 'goods/address/getAddress';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'address_id': id,
    };
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.address = result.data;
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }

  /**
   * 获取用户可用pg豆
   */
  getPgdou(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
    var api = 'user/user/getNewInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
    };
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.pgdou = result.data.pgdou;
        this.accTotal();
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }

  /**
   * 提交订单时判断库存/goods/goods/getGoods
   */
  getStock(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getgoods');
    var api = 'goods/goods/getGoods';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'goods_id': this.GoodsList[0].id,
    };
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.stock = result.data.stock;
        if(this.stock == 0){
          this.modalService.error({
            nzTitle: "系统提示",
            nzContent: '该好物已兑完，您可返回兑换其他好物！',
            nzOkText: "关闭",
            nzOnOk: () => {
              window.history.back();
            },
          });
        }else{
          this.submit_order();
        }
      } 
    });
  }

  /**
   * 立即兑换提交订单goods/order/addOrder
   */
  submit_order(){
    if(this.addid == 0){
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: '请选择收货地址！',
        nzOkText: "关闭",
        nzOnOk: () => { },
      });
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addorder');
    var api = 'goods/order/addOrder';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'address_id':this.addid,
      'goods_id': this.GoodsList[0].id,
      'num': 1,
      'type': 1,
      'size': this.GoodsList[0].size,
      'colour': this.GoodsList[0].colour,
    };
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.router.navigate(['/re-success'],{queryParams:{dou:result.data.pgdou}});
      } else {
        this.router.navigate(['/re-error']);
      }
    });
  }

}
