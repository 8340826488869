import { AppComponent } from './../app.component';
import { Md5 } from 'ts-md5';
import { HttpService } from './../services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { event } from 'jquery';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  // canBack = false;
  userName = '';      //用户名
  userPassword = '';  //登录密码
  from:any = [];

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
    private appPage: AppComponent,
  ) {
    route.queryParams.subscribe((data) => {
      if(data){
        this.from = data;
      }
    });
  }

  ngOnInit() {
  }

  register(){
    if(this.from.from == 'appoint'){
      this.router.navigate(['/register'],{queryParams:{id:this.from.a_id}});
    }else{
      this.router.navigate(['/register']);
    }
  }

  /**
   * 用户登录user/User/login
   */
  doLogin() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|login');
    var api = 'user/User/login';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'mobile': this.userName,
      'user_pass': this.userPassword,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.local.set('userinfo', result.data);
        this.appPage.userinfo = result.data; 
        // console.log("登录成功",this.local.get('userinfo')); 
          if(this.from.from == 'appoint'){
            this.router.navigate(['/a-detail'],{queryParams:{id:this.from.a_id,appoint:1}});
          }else if(this.from.bank == 1){
            this.router.navigate(['/database/q_bank']);
          }else{
            window.history.back();
          }
      } else {
        this.modalService.error({
          nzTitle: "登录提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }
  movein(e){
    $('.verify').css('transform','none');
    $('.verify').addClass('active');
   
  }
  moveleave(e){
    if(e.toElement.className=='versify-div'||e.toElement.className=='verify'||e.toElement.className=='text'){
      return;
    }
    $('.verify').removeClass('active');
    var eye = document.querySelector('.verify');
    let x = (eye.getBoundingClientRect().left + (eye.clientLeft / 2));
    // 同上
    let y = (eye.getBoundingClientRect().top + (eye.clientTop / 2));
    // pageX 鼠标指针的位置，相对于文档左边缘
    let radian = Math.atan2(e.pageX - x, e.pageY -y);
    let rot = (radian * (180 / Math.PI) * -1) + 90;
    $('.verify').css('transform','rotate(' + rot + 'deg)');
  }
}
