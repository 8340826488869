import { HttpService } from './services/http.service';
import { Md5 } from "ts-md5";
import { NzModalService } from 'ng-zorro-antd';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RoutesRecognized, ActivatedRoute, NavigationExtras } from '@angular/router';
import { StorageService } from './services/storage.service';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
// import { CommentStmt } from '@angular/compiler';
// import { getTestBed } from '@angular/core/testing';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'PG Fans';
  public thisUrl = '/';    //当前路由
  topTab = 0;

  public userinfo: any = {};     //本地保存的用户登录信息
  newuserinfo: any = {};
  searchContent = '';     //搜索内容
  unreadMessageNum = 0;   //未读私信数量，显示在顶部信封
  unreadNoticeNum = 0;    //未读通知数量，显示在顶部小铃铛

  unreadMessageList: any = [];    //未读私信列表，显示在顶部信封下拉列表
  unreadNoticeList: any = [];     //未读通知列表，显示在顶部小铃铛下拉列表
  noticeType = 1;
  pgdou = 0; //pg豆数量

  browseModal = false;
  isClause = true;   //是否同意用户条款

  showFocusedTag = false;     //是否显示关注标签
  showFocusedUser = false;    //是否显示关注用户

  hotColumns = [];            //热门专栏列表
  questionHotLabelList = [];  //热门问题标签列表
  allQuestionList = [];       //所有问题列表
  myFollowQuestion = [];      //我关注的问题列表
  questionList: any = [];     //用来中转的问题列表
  showList: any = [];         //用来显示的问题列表
  rankingList: any = [];      //排行榜TOP5
  rankingListYear:any = []; //年排行
  selectTab = 0;
  selectTabPM = 0;
  hot = 'article';            //首页热门：question:问答；article:文章；
  concern = 'labels';         //首页关注：labels:标签；users:用户；
  newQuestionList:any = [];   //最新问答
  hotBlogsList:any = [];      //近期热门文章


  page = 1;                   //列表显示第几页
  page_num = 10;              //每页显示的数量
  total: any = [];            //总共几页
  
  loadingText = '载入中';     //列表底部载入控件显示的文字
  loaddiv: any;
  flag = false;

  qList: any[] = [];   // 获取到的数据
  listNum = 1;         // 当前显示的数据编号
  tagList: any = [];          //技术标签列表
  userList: any = [];         //技术人列表

  re_labels: any = [];  // 推荐关注标签
  re_users: any = [];   //推荐关注用户

  ActivityList: any= [];  // 活动推荐列表
  weekDay: any = ['零', '一', '二', '三', '四', '五', '六', '七'];
  month: any = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Spt','Oct','Nov','Dec'];
  is_open = 1; // 0：立即报名；1：报名结束;2:报名未开始；

  advert = true;
  offsetTop = 0;

  userName = '';
  userPassword = '';
  currentYear = new Date().getFullYear()
  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
    private titleService: Title,

  ) {
    this.local.set('mainTab', this.selectTab);
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userinfo = this.local.get('userinfo');
    }
    this.titleService.setTitle('PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        this.thisUrl = '/'+ event.url.split('?')[0].split('/')[1];
        // console.log(this.thisUrl)
        this.getUserSession();
        this.getUnreadNotices(this.noticeType);
        this.getNoticeCount();
        this.getUnreadMessages();
        this.getUserInfo();
        if(this.local.get('userinfo') == null || this.local.get('userinfo').id < 0){
          var num = 0;  
          if(sessionStorage.getItem('browseTime')){
            num = parseInt(sessionStorage.getItem('browseTime'));
          };
          var Timer = setInterval(() => {
            if(this.thisUrl=='/login'||this.thisUrl=='/forget'||this.thisUrl=='/register'||this.thisUrl=='/clause'){
              sessionStorage.removeItem('browseTime')
              clearInterval(Timer) 
            }else{
              num++;
              sessionStorage.setItem('browseTime',String(num));
              if(num == 10){
                this.browseModal = true;
                sessionStorage.removeItem('browseTime')
                clearInterval(Timer)  
              }
            }
          },1000);
        }
        if (this.thisUrl == '/') {
          // this.titleService.setTitle('PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
          this.advert = true;
          this.topTab = 0;
          this.getHotColumns();
          this.getQuestionHotLabelList();
          if (this.selectTab == 0) {
            this.getNewQuestionList();
          } else if (this.selectTab == 1) {
            if (this.hot == 'article') {
              this.getHotArticleList();
            } else {
              this.getHotQuestionList();
            }
          } else if (this.selectTab == 2){
            if (this.concern == 'labels') {
              this.getMyConcern(1);
            } else {
              this.getMyConcern(2);
            }
          }
          this.getRankingList();
          if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
            this.userinfo = this.local.get('userinfo');
          }
          if (this.local.get('newuser') == '1') {
            this.local.set('newuser', '0');
            this.getTagList();
            this.showFocusedTag = true;
          }
        } else if (this.thisUrl == '/questions') {
          this.topTab = 1;
        } else if (this.thisUrl == '/q') {
          this.topTab = 1;
        } else if (this.thisUrl == '/blogs') {
          this.topTab = 2;
        } else if (this.thisUrl == '/blogs-tag') {
          this.topTab = 2;
        } else if (this.thisUrl == '/a') {
          this.topTab = 2;
        } else if (this.thisUrl == '/activity') {
          this.topTab = 3;
        } else if (this.thisUrl == '/a-detail') {
          this.topTab = 3;
        } else if (this.thisUrl == '/recruit') {
          this.topTab = 4;
        } else if (this.thisUrl == '/r-detail') {
          this.topTab = 4;
        }else {
          this.topTab = -1;
        }
      }


    });
    window.onscroll = () => {
      if (this.thisUrl != '/') {
        return;
      }
      if(this.selectTab!=2){
        return;
      }
      var a = document.documentElement.scrollTop == 0 ? document.body.clientHeight : document.documentElement.clientHeight;
      var b = document.documentElement.scrollTop == 0 ? document.body.scrollTop : document.documentElement.scrollTop;
      var c = document.documentElement.scrollTop == 0 ? document.body.scrollHeight : document.documentElement.scrollHeight;

      // sessionStorage.setItem('scroll', String(b));
      // console.log("lodd:", document.getElementById("loaddiv"));
      // console.log('abc',a,b,c)
      this.loaddiv = document.getElementById("loaddiv").offsetTop + 100;

      if (b != 0) {
        if (a + b >= this.loaddiv) {
          if (!this.flag) {
            this.flag = true;
            this.getNewData();
          }
          this.getNewData();
        } else {
          if (this.loadingText != '没有更多数据了') {
            this.loadingText = '载入中';
          }
        }
      }
    }

  }

  ngOnInit() {
    this.selectTab = this.local.get('mainTab');

    // this.route.queryParams.subscribe((data) => {
    //   if(data.sms){
    //     if(sessionStorage.getItem('isFirst')==null){
    //       sessionStorage.setItem('isFirst','true');
    //       var div = "<script type='text/javascript'> var ip = returnCitySN['cip'];$('#info').text(ip)</script>"
    //       $('#body').append(div);
    //       var ip = $('#info').text();
    //       this.addSms(ip);
    //     }
        
    //   }
    // });

    //获取活动推荐
    this.getActivityList();


    //获取未读私信user/user/myacceptedMessage
    this.getUnreadMessages();
    //获取未读消息通知user/notice/noticeList
    this.getNoticeCount();
    this.getUnreadNotices(1);

    //获取5个热门专栏列表column/columns/getHotColumns
    this.getHotColumns();

    //获取热门标签question/question_label/questionHotLabelList
    this.getQuestionHotLabelList();

    //获取排行榜top5:user/User/rankingList
    this.getRankingList();
    this.getRankingListByYear();

  }

  forgetPasswd(){
    this.browseModal = false;
    this.router.navigate(['/forget']);
  }
  goRegister(){
    this.browseModal = false;
    this.router.navigate(['/register']);
  }

  //页脚建议反馈
  gofeed(){
    if(this.local.get('userinfo') == null){
      this.router.navigate(['/login']);
    }else{
      this.router.navigate(['/feedback']);
    }
  }
  //短信进入的用户
  addSms(ip){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addsmsfeedback');
    var api = 'user/user/addSmsFeedback';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': '',
      'mobile': '',
      'name':'',
      'ip':ip,
    }
    if(this.local.get('userinfo') != null && this.local.get('userinfo').id > 0){
      body.user_id = this.local.get('userinfo').id;
      body.mobile = this.local.get('userinfo').mobile;
      body.name = this.local.get('userinfo').user_login;
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
    })
  }
  

  doLogin(){
    if(this.isClause == false){
      this.modalService.error({
        nzTitle: "登录提示",
        nzContent: '请先同意用户服务条款',
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|login');
    var api = 'user/User/login';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'mobile': this.userName,
      'user_pass': this.userPassword,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.browseModal = false;
        this.local.set('userinfo', result.data);
        this.userinfo = result.data; 
        // console.log("登录成功",this.local.get('userinfo')); 
        // if (document.referrer) {
        //   if(this.from.from == 'appoint'){
        //     this.router.navigate(['/a-detail'],{queryParams:{id:this.from.a_id,appoint:1}});
        //   }else{
        //     window.history.back();
        //   }
          
        // } else {
        //   this.router.navigate(['/']);
        // }
      } else {
        this.modalService.error({
          nzTitle: "登录提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  //进入题库
  goDatabase(){
    this.router.navigate(['/database']);
    //只有题库时，这样写
    /*
    if(this.local.get('userinfo') == null){
      this.router.navigate(['/login'],{queryParams:{bank:1}});
    }else{
      this.router.navigate(['/database']);
    }
    */
  }

  close(){
    this.advert = false;
  }
  clause(){
    this.isClause = !this.isClause;
  }
  //获取活动推荐列表
  getActivityList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|recactivitylist');
    var api = 'activity/activity/recactivityList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    };
    this.http.doPost(api, body, (result) => {
      // console.log(result.data);
      if (result.code == "200") {
        this.ActivityList = result.data;
        for (let i=0; i<this.ActivityList.length; i++) {
          // 月
          var Month = new Date(Number(this.ActivityList[i].start_time + '000')).getMonth();
          this.ActivityList[i].month = this.month[Month];

          // 周
          var startday = new Date(Number(this.ActivityList[i].start_time + '000')).getDay();
          this.ActivityList[i].startday = this.weekDay[startday];

          // 判断报名状态
          var startDate = Number(this.ActivityList[i].enroll_start_time + '000');
          var endDate = Number(this.ActivityList[i].enroll_end_time + '000');
          var nowTime = new Date().getTime();
          if (nowTime < startDate) {
            this.is_open = 2;
          } else if (nowTime > endDate) {
            this.is_open = 1;
          } else {
            this.is_open = 0;
          }
        }
      }
    });
  }



  /**
   * 通过用户id判断登录是否失效
   */
  getUserSession() {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|checklogin');
    var api = 'user/user/checkLogin';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'sessionid': this.userinfo.sessionid,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result)
      if (result.code == "200") {
        if (result.data.login_status == 0) {
          this.modalService.error({
            nzTitle: "系统提示",
            nzContent: "您的登录已过期，请重新登录！",
            nzOkText: "确定",
            nzOnOk: () => { 
              localStorage.removeItem('userinfo');
              localStorage.removeItem('isSure');
              this.router.navigate(['/']);
              location.reload();
            },
          })
        }
      }
    })
  }

  /**
   * 获取pgdou数量
   */
  getUserInfo() {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
    var api = 'user/user/getNewInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'sessionid':this.userinfo.sessionid,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.userinfo = result.data;
      }
    })
  }

  /**
   * 建议反馈
   */
  feedback(){
    this.router.navigate(['/feedback']);
  }

  /**
   * 获取未读私信user/user/myacceptedMessage
   */
  getUnreadMessages() {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|myacceptedmessage');
    var api = 'user/user/myacceptedMessage';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
    }
    this.http.doPost(api, body, (result) => {
      // console.log("messages:", result);
      if (result.code == "200") {
        this.unreadMessageNum = result.data.length;
        if (result.data != null) {
          this.unreadMessageList = result.data;
        }
      }
    })
  }
  /**
   * 获取未读消息通知user/notice/noticeList
   */
  getUnreadNotices(type) {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    this.noticeType = type;
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|noticelist');
    var api = 'user/notice/noticeList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'type': type,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        if (result.data != null) {
          // this.unreadNoticeList = result.data;
          this.unreadNoticeList = result.data.filter(notice => {
            return notice.status == 0;
          })
          // console.log("新的unreadNoticeList：", this.unreadNoticeList);
        }
      }
    })
  }
  /**
   * 获取未读消息数量user/notice/getNoticeCount
   */
  getNoticeCount() {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnoticecount');
    var api = 'user/notice/getNoticeCount';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
    }
    this.http.doPost(api, body, (result) => {
      // console.log("noticeNum:", result);
      if (result.code == "200") {
        this.unreadNoticeNum = result.data.count;
      }
    })
  }
  doOperate(notice, key) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|noticeoperate');
    var api = 'user/notice/noticeOperate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'op_type': notice.type,
      'notice_id': notice.id,
    }
    if (key == 0) {
      body.notice_id = 0;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        // this.unreadNoticeNum = result.data.count;
        this.unreadNoticeNum--;
        this.unreadNoticeList.splice(key, 1)
        if (key == -1) {
          this.getNoticeCount();
          this.unreadNoticeList = [];
        }
      }
    })
  }
  isShowMessageDetail = false;
  mDetail: any = {};
  mDetailUser = '';
  mDetailTitle = '';
  mDetailContent = '';
  doRead(message, key) {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|setmessagereaded');
    var api = 'user/user/setMessageReaded';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'message_id': message.id,
    }
    if (key == -1) {
      body.message_id = -1;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.unreadMessageNum--;
        this.unreadMessageList.splice(key, 1);
        this.isShowMessageDetail = true;
        this.mDetail = message;
        this.mDetailUser = message.user_login;
        this.mDetailTitle = message.title;
        this.mDetailContent = message.content;
        if (key == -1) {
          this.getUnreadMessages()
        }
      }
    })
  }

  sendTitle = '';
  sendContent = '';
  sendUser = 0;
  isShowSendMessage = false;    //是否显示发送私信对话框
  reply(mDetail) {
    this.sendTitle = mDetail.user_login;
    this.sendContent = '';
    this.sendUser = mDetail.user_id;
    this.isShowSendMessage = true;
  }
  send() {
    this.isShowSendMessage = false;
    this.doSend();
  }
  sendCancel() {
    this.isShowSendMessage = false;
    this.browseModal = false;
  }
  /**
   * 发送私信user/user/sendPrivateMessage
   */
  doSend() {
    if (this.local.get('userinfo') == null) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|sendprivatemessage');
    var api = 'user/user/sendPrivateMessage';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'title': '无标题',
      'content': this.sendContent,
      'accepted_user_id': this.sendUser
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.modalService.success({
          nzTitle: "系统提示",
          nzContent: "私信发送成功",
          nzOkText: "关闭",
          nzOnOk: () => {
            this.sendTitle = '';
            this.sendContent = '';
          },
        })
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: "发送私信失败，请联系管理员",
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  doOperateAll() {
    // if (this.unreadNoticeList != null && this.unreadNoticeList.length > 0) {
    //   this.doOperate(this.unreadNoticeList[0], 0);
    // }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|noticeoperate');
    var api = 'user/notice/noticeOperate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'op_type': 5,
      'notice_id': 0,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.getNoticeCount();
        this.getUnreadNotices(1);
      }
    })
  }
  doReadAll() {
    // if (this.unreadMessageList != null && this.unreadMessageList.length > 0) {
    //   this.doRead(this.unreadMessageList[0], -1);
    // }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|setmessagereaded');
    var api = 'user/user/setMessageReaded';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'message_id': -1,
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.getUnreadMessages();
      }
    })
  }

  /**
   * 获取5个热门专栏列表column/columns/getHotColumns
   * 无需参数
   */
  getHotColumns() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|gethotcolumns');
    var api = 'column/columns/getHotColumns';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'count': 10,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.hotColumns = result.data;
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
  /**
   * 获取热门标签question/question_label/questionHotLabelList
   */
  getQuestionHotLabelList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|questionhotlabellist');
    var api = 'question/question_label/questionHotLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'num': 8,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.questionHotLabelList = result.data;
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }

  /**
   * 近期热门筛选：文章/问答
   */
  changeHot(event) {
    this.hot = event;
    if (event == 'question') {
      this.getHotQuestionList();
    } else {
      this.getHotArticleList();
    }
  }

  /**
   * 我的关注筛选：标签/用户
   */
  changeConcern(e){
    this.concern = e;
    if (e == 'labels') {
      this.getMyConcern(1);
    } else {
      this.getMyConcern(2);
    }
  }

  /**
   * 获取近期热门文章列表column/columns/hotArticleList
   */
  getHotArticleList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|hotarticlelist');
    var api = 'column/columns/hotArticleList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': '',
    }
    if (this.userinfo.id) {
      body.user_id = this.userinfo.id;
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      // this.questionList = [];
      // this.listNum = 0;
      // this.showList = [];
      this.hotBlogsList = [];
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          for(let i = 0;i<result.data.length;i++){
            this.hotBlogsList[i] = result.data[i];
            this.local.fmDates(this.hotBlogsList);
          }
          // this.allQuestionList = result.data;
          // this.local.fmDates(this.allQuestionList);
          // this.questionList = this.allQuestionList;
          // // this.getNewData();
          // for (let i = 0; i < 12; i++) {
          //   if (this.questionList[this.listNum]) {
          //     this.showList.push(this.questionList[this.listNum]);
          //     this.listNum++;
          //   } else {
          //     i = 12;
          //     this.loadingText = "没有更多数据了";
          //   }
          // }
        }
      }
    })
  }

  /**
   * 获取近期热门问答列表question/question/hotQuestionList
   */
  getHotQuestionList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|hotquestionlist');
    var api = 'question/question/hotQuestionList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      // this.questionList = [];
      // this.listNum = 0;
      // this.showList = [];
      this.hotBlogsList = [];
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          if(result.data.length<=20){
            this.hotBlogsList = result.data;
            this.local.fmDates(this.hotBlogsList);
            console.log('111111111111111')
          }else{
            for(let i = 0;i<20;i++){
              this.hotBlogsList[i] = result.data[i];
              this.local.fmDates(this.hotBlogsList);
            }
          }
         
          // this.myFollowQuestion = result.data;
          // this.local.fmDates(this.myFollowQuestion);
          // this.questionList = this.myFollowQuestion;
          // // this.getNewData();
          // for (let i = 0; i < 12; i++) {
          //   if (this.questionList[this.listNum]) {
          //     this.showList.push(this.questionList[this.listNum]);
          //     this.listNum++;
          //   } else {
          //     i = 12;
          //     this.loadingText = "没有更多数据了";
          //   }
          // }
        }
      }
    })
  }

  /**
   * 获取首页最新问答question/question/newQuestionList
   */
  getNewQuestionList(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|newquestionlist');
    var api = 'question/question/newQuestionList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      // this.questionList = [];
      // this.listNum = 0;
      // this.showList = [];
      if (result.code == "200") {
        // console.log(result.data)
        if (result.data != null && result.data.length > 0) {
          for(let i = 0;i<20;i++){
            this.newQuestionList[i] = result.data[i];
            this.local.fmDates(this.newQuestionList);
          }
          // this.myFollowQuestion = result.data;
          // this.local.fmDates(this.myFollowQuestion);
          // this.questionList = this.myFollowQuestion;
          // // this.getNewData();
          // for (let i = 0; i < 12; i++) {
          //   if (this.questionList[this.listNum]) {
          //     this.showList.push(this.questionList[this.listNum]);
          //     this.listNum++;
          //   } else {
          //     i = 12;
          //     this.loadingText = "没有更多数据了";
          //   }
          // }
        }
      }
    })
  }

  /**
   * 首页我的关注：标签/用户user/dynamic/myConcerns
   */
  getMyConcern(type){
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|myconcerns');
    var api = 'user/dynamic/myConcerns';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'type': type,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      this.questionList = [];
      this.listNum = 0;
      this.showList = [];
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.myFollowQuestion = result.data;
          this.local.fmDates(this.myFollowQuestion);
          this.questionList = this.myFollowQuestion;
          for (let i = 0; i < 12; i++) {
            if (this.questionList[this.listNum]) {
              this.showList.push(this.questionList[this.listNum]);
              this.listNum++;
            } else {
              i = 12;
              this.loadingText = "没有更多数据了";
            }
          }
          if (result.data.length < 6) {
            if (type == 1) {
              this.getRelabels();
            } else {
              this.getReUsers();
            }
          }
        } else {
          this.loadingText = "暂无数据";
          if (type == 1) {
            this.getRelabels();
          } else {
            this.getReUsers();
          }
        }
      }
    })
  }

  /**
   * 获取推荐标签question/question_label/regLabelList
   */
    getRelabels() {
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|reglabellist');
      var api = 'question/question_label/regLabelList';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'user_id': this.userinfo.id,
      }
      this.http.doPost(api, body, (result) => {
        // console.log(result);
        if (result.code == "200") {
          if (result.data != null && result.data.length > 0) {
            this.re_labels = result.data;
          }
        }
      });
    }
    /**
     * 获取推荐用户user/user/followFiveUsers
     */
    getReUsers() {
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|followfiveusers');
      var api = 'user/user/followFiveUsers';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'user_id': this.userinfo.id,
      }
      this.http.doPost(api, body, (result) => {
        // console.log(result);
        if (result.code == "200") {
          if (result.data != null && result.data.length > 0) {
            this.re_users = result.data;
          }
        }
      });
    }

  // 换一批推荐标签/用户
  exchange(index) {
    if(index == 1){
      this.getRelabels();
    } else {
      this.getReUsers();
    }
  }

  // 关注标签question/label_follow/addFollowLabel
  doFollowLabel(label) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addfollowlabel');
    var api = 'question/label_follow/addFollowLabel';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id':this.userinfo.id,
      'label_id': label.id,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        label.isFollow = true;
      }
    })
  }

  //关注用户user/user_follow/operate
  doFollowUser(users) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'user/user_follow/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id':this.userinfo.id,
      'about_user_id': users.id,
      'type': 1,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        users.isFollow = true;
      }
    })
  }

  //浏览更多
  goMore(type){
    if(type==1||type==3){
      this.router.navigate(['/questions']);
    }else if(type==2){
      this.router.navigate(['/blogs'])
    }
  }
  /**
   * 获取排行榜top5:/user/rank/rankList
   */
  getRankingList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|ranklist');
    var api = '/user/rank/rankList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'type':1,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        if (result.data != null) {
          this.rankingList = result.data.userList;
          // console.log(this.rankingList)
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
    /**
   * 获取年度排行榜top5:/user/rank/rankList
   */
    getRankingListByYear() {
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|ranklist');
      var api = '/user/rank/rankList';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'type':5,
      }
      // console.log("body", body);
      this.http.doPost(api, body, (result) => {
        if (result.code == "200") {
          if (result.data != null) {
            this.rankingListYear = result.data.userList;
          }
        }
      })
    }
  /**跳转到路由页面 */
  goPage(page) {
    this.selectTab = 0;
    this.local.set('mainTab', 0);
    this.router.navigate([page]);
  }
  /**
   * 跳转到用户主页
   * @param user 用户信息，主要是要用到主键id
   */
  goUserHome(user) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'userId': user.user_id, },
    }
    this.router.navigate(['/user/home'], navigationExtras);
  }

  goSearch(event) {
    // console.log("搜索：" + event);
    let navigationExtras: NavigationExtras = {
      queryParams: { 'content': event, },
    }
    this.router.navigate(['/search'], navigationExtras);
    this.searchContent = '';
  }

  /**
   * 获取活动推荐列表
   */
  // get......(){

  // }

  /**
   * 加载center数据
   * @param e 
   */
  loadingData(e) {
    this.selectTab = e[0].index;
    this.local.set('mainTab', this.selectTab);
    if (e[0].index == 0) {
      //最新问答
      this.getNewQuestionList();
      
    } else if (e[0].index == 1) {
      //近期热门
      if (this.hot == 'article') {
        this.getHotArticleList();
      } else {
        this.getHotQuestionList();
      }
    } else if (e[0].index == 2){
      //我的关注
      if (this.concern == 'labels') {
        this.getMyConcern(1);
      } else {
        this.getMyConcern(2);
      }
    }
  }
  loadingDataPM(e) {
    this.selectTabPM = e[0].index;
    if (e[0].index == 0) {
      //最新问答
      // this.getNewQuestionList();
      
    } else if (e[0].index == 1) {

    }
  }

  /**跳转到消息列表页面 */
  goMessages(message) {
    // this.router.navigate(['/messages']);
  }
  /**
   * 获取推荐关注的标签列表question/question_label/regLabelList
   */
  getTagList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|reglabellist');
    var api = 'question/question_label/regLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.tagList = result.data;
        }
      }
    })
  }

  /**
   * 获取推荐关注的人user/user/followFiveUsers
   */
  getFollowUsers() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|followfiveusers');
    var api = 'user/user/followFiveUsers';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.userList = result.data;
        }
      }
    })
  }



  /**
   * 跳转到user页面，通过传值直接指向最终页面，默认home
   * @param val 页面默认展示：
   * 0=我的主页home；1=我的文章article；2=我的回答answer；3=我的关注focus；4=我的收藏collect；5=个人资料data
   */
  goUserPage(val) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'userId': this.local.get('userinfo').id, },
    }
    this.router.navigate(['/user/' + val], navigationExtras);
    // window.location.replace('/user/' + val + '?userId=' + this.local.get('userinfo').id);
  }

  getNewData() {
    for (let i = 0; i < 8; i++) {
      if (this.questionList[this.listNum]) {
        this.showList.push(this.questionList[this.listNum]);
        this.listNum++;
        if (i == 7) {
          this.flag = false;
        }
      } else {
        i = 8;
        this.loadingText = "没有更多数据了";
      }
    }
    // if (this.listNum < 8) {
    //   // this.data.push(this.qList[0]);
    //   for(let i=0;i<8;i++){
    //     this.datalist.push([listNum+i]);
    //   }
    //   this.listNum++;
    // } else {
    //   this.loadingText = "没有更多数据了";
    // }
  }

  /**登出 */
  doLogout() {
    // this.local.set('userinfo', '');
    localStorage.removeItem('userinfo');
    localStorage.removeItem('isSure');
    // window.location.replace('#/login');
    window.location.replace('');
  }

  checkedLabelNum = 0;
  checkLabel(event) {
    if (event) {
      this.checkedLabelNum++;
    } else {
      this.checkedLabelNum--;
    }
    // console.log(this.checkedLabelNum);
  }
  handleOk(): void {
    this.showFocusedTag = false;
    //添加关注标签question/label_follow/addFollowLabel
    var checkedLabels: any = [];
    for (let i = 0; i < this.tagList.length; i++) {
      if (this.tagList[i].checked) {
        checkedLabels.push(this.tagList[i].id)
        this.doAddLabel(this.tagList[i].id)
      }
    }

    //获取推荐关注的人user/user/followFiveUsers
    this.getFollowUsers();
    this.showFocusedUser = true;
  }
  handleCancel(): void {
    this.showFocusedTag = false;
    this.showFocusedUser = false;
    this.isShowMessageDetail = false;
  }
  checkedUserNum = 0;
  checkUser(event) {
    if (event) {
      this.checkedUserNum++;
    } else {
      this.checkedUserNum--;
    }
  }
  handleFinish(): void {
    //关注用户user/user_follow/operate
    for (let i = 0; i < this.userList.length; i++) {
      if (this.userList[i].checked) {
        this.doAddUser(this.userList[i].id)
      }
    }
    this.showFocusedTag = false;
    this.showFocusedUser = false;
  }

  /**
   * 添加关注标签question/label_follow/addFollowLabel
   */
  doAddLabel(labelId) {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addfollowlabel');
    var api = 'question/label_follow/addFollowLabel';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'label_id': labelId,
      'user_id': this.local.get('userinfo').id,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {

      }
    })
  }

  /**
   * 关注用户user/user_follow/operate
   */
  doAddUser(userId) {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'user/user_follow/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'about_user_id': userId,
      'user_id': this.local.get('userinfo').id,
      'type': 1,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {

      }
    })
  }

  // /**跳转到页面 */
  // getPageData(num) {
  //   this.page = num;
  //   this.updatepage();
  //   // console.log(this.page);
  //   this.getMyFollowQuestion();
  // }
  // /**到上一页 */
  // doPrevious() {
  //   this.page--;
  //   this.updatepage();
  //   // console.log(this.page);
  //   this.getMyFollowQuestion();
  // }
  // /**到下一页 */
  // doNext() {
  //   this.page++;
  //   this.updatepage();
  //   // console.log(this.page);
  //   this.getMyFollowQuestion();
  // }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
  }


  /**
   * 点赞操作question/question_operate/operate
   * @param art 文章json
   * @param key 
   */
  doAgree(art) {
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      this.router.navigate(['/login']);
    }
    if (art.is_thumb_up == 1) {
      var type = 6;
    } else {
      var type = 5;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'question/question_operate/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'question_id': art.id,
      'operate_type': type,
      'type': 1,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (art.is_thumb_up) {
          art.is_thumb_up = 0;
          art.praise_num--;
          art.answer_num--;
        } else {
          art.is_thumb_up = 1;
          art.praise_num++;
          art.answer_num++;
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: '您尚未登录，请先登录！',
          nzOkText: "关闭",
          nzOnOk: () => { 
            this.router.navigate(['/login']);
          },
        })
      }
    });
  }

  change(type){
    if(type==1){
      this.getTagList();
    }else{
      this.getFollowUsers();
    }
   
  }
}
