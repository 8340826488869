import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8' })
  };
  constructor(
    private modalService: NzModalService,
    public http: HttpClient,
    public config: ConfigService,
    private router: Router,
  ) { }

  //get请求数据
  doGet(apiUrl, json, callback) {
    // var body = JSON.stringify(data);
    let api = this.config.apiUrl + apiUrl + '?' + json;
    this.http.get(api).subscribe((response) => {
      this.checkToken(response);
      callback(response);
    });
  }

  Get(json,callback){
    this.http.get(json).subscribe((response) => {
      this.checkToken(response);
      callback(response);
    });
  }


  //post提交数据
  doPost(apiUrl, json, callback){
    var api = this.config.apiUrl + apiUrl;
    this.http.post(api, json, this.httpOptions).subscribe((response) => {
      this.checkToken(response);
      callback(response);
    });
  }

  
  postBlob(apiUrl, json,callback){
    var api = this.config.apiUrl + apiUrl;
    this.http.post(api, json, {
      responseType: "blob",
      headers: new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8' })
    }).subscribe((response) => {
      callback(response);
    });
  }

  //**下载文件 */
  doDownLoad(apiUrl, json, callback) {
    let api = this.config.apiUrl + apiUrl + '?' + json;
    window.open(api);
  }

  //**【废弃】后端已经做好处理，将token中的+专为%2B */
  gainToken(json) {
  // console.log(json);
    // console.log(JSON.parse(json));
    if (json.token != null && json.token != '') {
      var data: any = {};
      var token: any = '';
      data = json;
      token = json.token.replace('%2B', '+');
      data.token = token;
      return data;
    }
    return json;
  }

  // isLogin = true;
  //**检查返回的数据是否鉴权失败 */
  checkToken(response) {
    // console.log("鉴权", response.msg);
    var str = "鉴权";
    if (response.code =='2020') {

      localStorage.setItem('isLogin', '0');
      // this.router.navigate(['/login']);
      // alert(str.indexOf(response.msg));
      // if (response.msg.indexOf(str) > -1) {
      //   // alert(response.msg.indexOf(str));
      //   this.modalService.error({
      //     nzTitle: '登录提示！',
      //     nzContent: response.message,
      //     nzOkText: '确定',
      //     nzOnOk: () => { },
      //   });
      //   localStorage.setItem('isLogin', '0');
      //   this.router.navigate(['/login']);
      // } else {
      // }
    }
  }
}
