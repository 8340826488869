import { HttpService } from './../../services/http.service';
// import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Md5 } from 'ts-md5';
import { Component, OnInit } from '@angular/core';
// import { addQuillTitle } from '../../../assets/quill/quill-title.js';
import { EditorComponent } from './../../editormd/editor/editor.component';
import * as $ from 'jquery';

declare var editormd: any;

@Component({
  selector: 'app-ask',
  templateUrl: './ask.component.html',
  styleUrls: ['./ask.component.less']
})
export class AskComponent implements OnInit {

  userinfo: any = {};   //本地保存的用户登录信息

  tagList: any = [];    //获取到的所有标签
  labelList: any = [];  //动态二级标签
  thisbtn = 0;          //选中的以及标签

  isShowTags = false;   //是否显示下拉菜单
  isShowAddTag = false; //是否显示创建标签对话框
  isAdd = false;

  askTitle = '';        //提问的标题
  askTag: any = [];     //提问的标签

  newTag = '';          //新标签名
  describe = '';        //新标签名的描述
  tagType;         //新标签类别
  options:any = [];    //所有标签一级列表  

  filterTags:any = [];  
  allTags:any = [];
  tag;

  loading = false;      //发布按钮是否可用
  g_num = 0;

  editorType = '2';  //1.富文本编辑器;2.markdown编辑器


  quilleditor: any;
  quillContent: any;    //富文本数据
  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['link', 'blockquote', 'code-block', 'image'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'header': 1 }, { 'header': 2 }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
    ]
  };

  editor: any;
  conf = new EditorComponent();
  showInfo: any; //编辑器内容

  imgUrl = '';

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    // private cookieService: CookieService,
    public http: HttpService,
  ) {
    // console.log("本地userinfo", this.local.get('userinfo'));
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      this.userinfo = this.local.get('userinfo');
    }else{
      router.navigate(['/login']);
    }
  
  }

  ngOnInit() {
    //获取所有标签列表（分一二级）
    this.getTagList();
    this.geAllTags();
    this.getUserInfo();
    // window.onbeforeunload = ()=>{
    //   // 这里写关闭时需要处理的时间，刷新也会执行这里的方法
    //   return;
    // };
  }
  ngAfterViewInit(): void {
    this.EditorCreated();
   
  }


  // 减一
  subtract(){
    this.g_num--;
  }
  // 加一
  add(){
    this.g_num++;
  }

  changePgdou(val){
    if(val>=this.userinfo.pgdou){
      this.g_num = this.userinfo.pgdou;
    }
    if(val<0){
      this.g_num = 0;
    }
  }
  /**
   * 通过用户id获取用户信息user/user/getNewInfo
   */
  getUserInfo() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
    var api = 'user/user/getNewInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'my_user_id': 0,
      'sessionid': 0,
    }
    if (this.local.get('userinfo') == null) {
      body.my_user_id = 0;
    } else {
      body.my_user_id = this.local.get('userinfo').id;
      body.sessionid = this.local.get('userinfo').sessionid;
    }
    // console.log("body:", body);
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.userinfo.pgdou = result.data.pgdou;
      }
    })
  }

  /**
   * 获取一级标签列表question/question_label/questionOneLabelList
   */
  getOneTags(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|questiononelabellist');
    var api = 'question/question_label/questionOneLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        var temp;
        for(let i=0;i < result.data.length/2;i++){
          temp=result.data[i];
          result.data[i]=result.data[result.data.length-1-i];
          result.data[result.data.length-1-i]=temp;
        }
        this.options = result.data;
      }
    })
  }

  addTag(){
    for(let i=0;i<this.allTags.length;i++){
      if(this.tag == this.allTags[i].id){
        if(this.askTag.length == 0){
          this.askTag.push({id:this.allTags[i].id,label:this.allTags[i].label,checked:true})
        }else{
          var is = 0;
          this.askTag.forEach(val => {
            if(val.id==this.tag){
              is = 1;
            }
          });
          if(is == 0){
            this.askTag.push({id:this.allTags[i].id,label:this.allTags[i].label,checked:true})
          }
        } 
      }
    }
  }

  onChange(value) {
    var isN = isNaN(value);
    if(isN){
      this.filterTags = this.allTags.filter(option => option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }else{
      this.filterTags = this.allTags;
    }
  }
  test(){
    this.addTag();
  }

  keyupChanged(e){
    if(e.keyCode == 13){
      this.addTag();
    }
  }

  /**切换标签 */
  selectChange(num) {
    this.thisbtn = num;
    this.labelList = this.tagList[num].labelList;
    for(let i=0;i<this.labelList.length;i++){
      if(this.userinfo.id!='1'){
        if(this.labelList[i].id == 118){
          this.labelList.splice(i,1);
        } else if(this.labelList[i].id == 132){
          this.labelList.splice(i,1);
        } 
      }
    }
  }
  checkChange(e, key): void {
    // console.log(e);
    this.tagList[this.thisbtn].labelList[key].checked = !this.tagList[this.thisbtn].labelList[key].checked;
    this.askTag = this.askTag.filter(value => value.id != e.id);
    if (this.tagList[this.thisbtn].labelList[key].checked) {
      this.askTag.push(e);
    }
    // console.log("this.askTag=", this.askTag.toString());
    // console.log("this.askTag.length=", this.askTag.length);
  }
  delTag(e, key): void {
    this.askTag.splice(key, 1);
    this.tagList.forEach(tag1 => {
      tag1.labelList.forEach(tag => {
        if (tag.id == e.id) tag.checked = false;
      });
    });
  }

  //切换编辑器
  changeEditor(type){
    this.editorType = type;
  }
  /**
   * 提交问题question/question/addQuestion
   */
  doSubmit(type) {
    if(!this.g_num){
      this.g_num = 0;
    }
    this.loading = true;
    var label_ids: any = [];
    for (let tag of this.askTag) {
      label_ids.push(tag.id);
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addquestion');
    var api = 'question/question/addQuestion';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'title': this.askTitle,
      'label_ids': label_ids.toString(),
      // 'content': this.quillContent,
      'content': this.showInfo,
      'content_type': type,
      'pgdou': this.g_num,
      // 'is_anonymous': 0,    //匿名提问：0=否；1=是
    }
    if(type == 1){
      body.content = this.quillContent;
    }
    // console.log(body)
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        // this.tagList = result.data;
        this.modalService.success({
          nzTitle: "提交问题成功",
          nzContent: "问题正在审核中，请稍后...",
          nzOkText: "关闭",
          nzOnOk: () => {
            let navigationExtras: NavigationExtras = {
              queryParams: { 'userId': this.userinfo.id, },
            }
            this.router.navigate(['/user/ask'], navigationExtras);
          },
        })

      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
      this.loading = false;
    })
  }

  /**显示创建标签对话框 */
  showAddTag() {
    this.isShowTags = false;
    this.isShowAddTag = true;
    this.isAdd = false;
    this.getOneTags();
  }
  handleOk(): void {
    // console.log('Button ok clicked!');
    this.isShowAddTag = false;
  }
  handleCancel(): void {
    // console.log('Button cancel clicked!');
    this.isShowAddTag = false;
  }

  /**
   * 获取所有标签列表question/question_label/allLabelList
   */
  getTagList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|alllabellist');
    var api = 'question/question_label/allLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.tagList = result.data;
        this.labelList = this.tagList[0].labelList;
      }
    })
  }

  /**
   * 获取所有二级标签
   */
  geAllTags(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|questiontwolabellist');
    var api = 'question/question_label/questionTwoLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'parent_id': 0,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.allTags = result.data;
        for(let i=0;i<this.allTags.length;i++){
          if(this.userinfo.id!='1'){
            if(this.allTags[i].id == 118){
              this.allTags.splice(i,1);
            } else if(this.allTags[i].id == 132){
              this.allTags.splice(i,1);
            } 
          }
        }
        this.filterTags = this.allTags;
      }
    })
  }

  /**
   * 创建标签question/question_label/addQuestionLabel
   */
  doAddTag() {
    if(this.newTag == ''){
      this.modalService.error({
        nzTitle: "提示",
        nzContent: "请输入标签名！",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }
    if(!this.tagType){
      this.modalService.error({
        nzTitle: "提示",
        nzContent: "请选择标签分类！",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }
    if (!this.userinfo) {
      this.userinfo.id = '';
    }
    // return;
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addquestionlabel');
    var api = 'question/question_label/addQuestionLabel';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'label': this.newTag,
      'remarks': this.describe,
      'parent_id':this.tagType,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      console.log(result);
      if (result.code == "200") {
        this.getTagList();
        this.isShowAddTag = false;
        this.modalService.success({
          nzTitle: "提示",
          nzContent: "添加标签" + this.newTag + "成功",
          nzOkText: "关闭",
          nzOnOk: () => {
            this.askTag.push({id:result.data.id,label:result.data.label,checked:true})
          },
        })
        this.newTag = '';
        this.describe = '';
        this.tagType = '';
      } else {
        this.modalService.error({
          nzTitle: "提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  //创建编辑器
  EditorCreated() {
    this.conf.placeholder = '1. 描述你的问题\n2. 贴上相关代码（请勿用图片代替代码)\n3. 贴上报错信息\n4. 已经尝试过哪些方法仍然没有解决（附上相关链接）';
    this.editor = editormd('mdBox', this.conf);
  //   editormd.loadPlugin("../../../assets/editor.md/plugins/image-handle-paste/image-handle-paste", function(){
  //     this.editor.imagePaste();
  // });

    this.editor.on('change', () => {
      this.showInfo = this.editor.getMarkdown();
      // console.log(this.showInfo);
    });
 }


 quillEditorCreated(quill) {
    // console.log("quill:", quill)
    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler.bind(this));
    this.quilleditor = quill;
  }
  imageHandler() {
    const Imageinput = document.createElement('input');
    Imageinput.setAttribute('type', 'file');
    Imageinput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp');
    Imageinput.classList.add('ql-image');
    Imageinput.addEventListener('change', () => {
      if (Imageinput.files != null && Imageinput.files[0] != null) {
        const file = Imageinput.files[0];
        if(file.size>5*1024*1024){
          this.modalService.error({
            nzTitle: "提示",
            nzContent: "图片尺寸不能超过5MB！",
            nzOkText: "关闭",
            nzOnOk: () => {},
          })
        }else{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          var dataURL = reader.result;
          let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnlogo');
          var api = 'column/columns/addColumnLogo';
          var body = {
            'timestamp': new Date().getTime().toString().substr(0, 10),
            'signature': signature,
            'columnlogo': dataURL,
            'type': 2
          }
          this.http.doPost(api, body, (result) => {
            if (result.code == "200") {
              let imgUrl = 'https://admin.pgfans.cn' + result.data.url;
              var range = this.quilleditor.getSelection(true);
              var length = range.index;
              var isok = this.quilleditor.insertEmbed(length, 'image', imgUrl);
              // console.log("isok:", isok);
              // this.editor.insertEmbed(this.editor.getSelection(true).index, 'image', imgUrl);
              // console.log("editor:", this.editor);
              // console.log("quill:", this.quillContent);
            }
          })
        };
        }
      }
    });
    Imageinput.click();
  }
  contentChanged(event) {
    var that = this;
    if(event.html.indexOf("img") != -1 ){
      $('.ql-editor img').each(function(index){
        var ht = $(this).attr('src');
        if(ht.split(':')[0] == 'http'){
          that.imgUpload(ht,index);
        }
      })
    }
    this.quillContent = event.html;  // 内容编辑
  }

  //图片链接转base64
  getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var ext = img.src.substring(img.src.lastIndexOf(".")+1).toLowerCase();
    var dataURL = canvas.toDataURL("image/"+ext);
    return dataURL;
  }

  //图片链接是http,上传到服务器
  imgUpload(img,index){
    var that = this;
    var tempImage = new Image();
    tempImage.src = img;
    tempImage.crossOrigin = "*";
    tempImage.onload = function(){
      var base64 = that.getBase64Image(tempImage);
      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnlogo');
      var api = 'column/columns/addColumnLogo';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'columnlogo': base64,
        'type': 2,
      }
      that.http.doPost(api, body, (result) => {
        if (result.code == "200") {
          var imgUrl = 'https://admin.pgfans.cn' + result.data.url;
          $('.ql-editor img').eq(index).attr('src',imgUrl)
        }
      })
    }
  }

}