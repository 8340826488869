import { HttpService } from './../../services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Md5 } from 'ts-md5';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-myask',
  templateUrl: './myask.component.html',
  styleUrls: ['./myask.component.less']
})
export class MyaskComponent implements OnInit {
  userId = 0;
  isMe = false;
  userinfo: any = {};   //本地保存的用户登录信息

  myaskList: any = [];       //我的回答列表

  page = 1;                   //列表显示第几页
  page_num = 10;              //每页显示的数量
  total: any = [];            //总共几页
  page_prev = 1;
  page_next = 5;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((data) => {
      this.userId = data.userId;
    });
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      if (this.userId == this.local.get('userinfo').id) {
        this.isMe = true;
        this.userinfo = this.local.get('userinfo');
      } else {
        this.isMe = false;
      }
    } else {
      this.isMe = false;
    }
  }

  ngOnInit() {
    this.getMyasks();
  }

  /**
   * 获取我的提问列表question/question/myQuestionList
   */
  getMyasks() {
    let self = 1;   //1=是；2=否
    if (this.isMe) {
      self = 1;
    } else {
      self = 2;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|myquestionlist');
    var api = 'question/question/myQuestionList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'is_self': self,
      'page': this.page,
      'page_num': this.page_num,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.myaskList = result.data;
          this.local.fmDates(this.myaskList);
          this.total = [];
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }


  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getMyasks();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getMyasks();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getMyasks();
  }
   /**更新页面选择器 */
   updatepage() {
    window.scrollTo(0, 0);
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = 'true';
      } else {
        this.total[i].checked = 'false';
      }
    }
    if (this.page == 1) {
      this.page_prev = this.page;
      this.page_next = this.page + 4;
    } else if (this.page == 2) {
      this.page_prev = this.page - 1;
      this.page_next = this.page + 3;
    } else if (this.page == this.total.length - 1) {
      this.page_prev = this.page - 3;
      this.page_next = this.page + 1;
    } else if (this.page == this.total.length) {
      this.page_prev = this.page - 4;
      this.page_next = this.page;
    } else {
      this.page_prev = this.page - 2;
      this.page_next = this.page + 2;
    }
  }
   /**向前5页 */
   prev(num) {
    if (num - 5 < 1) {
      this.page = 1;
    } else {
      this.page = num - 5;
    }
    this.updatepage();
    this.getMyasks();
  }
  /**向后5页 */
  next(num) {
    if (num + 5 > this.total.length) {
      this.page = this.total.length;
    } else {
      this.page = num + 5;
    }
    this.updatepage();
    this.getMyasks();
  }

}
