import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Md5 } from "ts-md5";
import { NzModalService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-question-bank',
  templateUrl: './question-bank.component.html',
  styleUrls: ['./question-bank.component.less']
})
export class QuestionBankComponent implements OnInit {
  password = '';

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
  ) { 
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      router.navigate(['/login']);
    }
    if(this.local.get('isSure')){
      this.router.navigate(['/database/banklist']);
    }
    
  }

  ngOnInit() {

  }

  goBank(){
    if(this.password!='qKGc9r'){
      this.modalService.error({
        nzTitle: "系统提示",
        nzContent: '题库密码错误，请重试！',
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }
    this.router.navigate(['/database/banklist']);
  }

}
