import { Component, OnInit } from '@angular/core';
import { HttpService } from './../services/http.service';
import { Md5 } from "ts-md5";
import { NzModalService } from 'ng-zorro-antd';
import { Router,ActivatedRoute, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RoutesRecognized, NavigationExtras } from '@angular/router';
import { StorageService } from './../services/storage.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less']
})
export class RegisterComponent implements OnInit {

  mobile: string = '';      //手机号
  user_pass: string = '';   //用户密码
  code: string = '';        //验证码
  user_repass: string = ''; //确认密码
  user_email: string = '';  //邮箱
  policy: boolean = false;  //是否选中阅读并同意协议

  codeLoading = false;      //验证码等待中
  loadingTime = 60;         //等待时间
  a_id = 0;

  // checkedAgreement = false; //是否选中阅读并同意协议
  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService, 
    ) { 
      window.scrollTo(0,0);
      route.queryParams.subscribe((data) => {
        if(data){
          this.a_id = data.id;
        }
        
      });
    }

  ngOnInit() {
  }

  /**获取手机验证码 */
  getCode() {
    // console.log('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getcode');
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getcode');
    var api = 'user/User/getCode';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'regstate': '1',
      'mobile': this.mobile,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "2011") {
        this.codeLoading = true;
        this.timesOut();
      } else {
        this.modalService.error({
          nzTitle: "验证码获取失败",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })

  }

  /**倒计时控制验证码按钮 */
  timesOut() {
    if (this.loadingTime == 0) {
      this.codeLoading = false;
      this.loadingTime = 60;
      return;
    }
    this.loadingTime--;
    setTimeout(() => {
      this.timesOut();
    }, 1000);
  }

  /**执行注册 */
  doRegister() {
    if(this.user_email){
      var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      if(!myreg.test(this.user_email)){
        this.modalService.error({
          nzTitle: "注册提示",
          nzContent: "邮箱格式不正确！",
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
        return;
      }
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|register');
    var api = 'user/User/register';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'mobile': this.mobile,
      'user_pass': this.user_pass,
      'code': this.code,
      'user_repass': this.user_repass,
      'user_email': this.user_email,
      'policy': this.policy,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.local.set('newuser', '1');
        this.local.set('userinfo', result.data);
        if(this.a_id==0){
          this.router.navigate(['/']);     
        }else{
          this.router.navigate(['/a-detail'],{queryParams:{id:this.a_id,appoint:1}});
        }
        
      } else {
        this.modalService.error({
          nzTitle: "注册提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });

    // let navigationExtras: NavigationExtras = {
    //   queryParams: { 'register': '1', },
    // }
    // this.router.navigate(['/'], navigationExtras);
    // this.local.set('newuser','1');
    // this.router.navigate(['/']);
  }

}
