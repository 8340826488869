import { HttpService } from './../../services/http.service';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Md5 } from 'ts-md5';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mycollect',
  templateUrl: './mycollect.component.html',
  styleUrls: ['./mycollect.component.less']
})
export class MycollectComponent implements OnInit {

  subscription: Subscription;

  userId = 0;
  isMe = false;
  userinfo: any = {};   //本地保存的用户登录信息

  myCollectList: any = [];    //我的文章列表

  page = 1;                   //列表显示第几页
  page_num = 10;              //每页显示的数量
  total: any = [];            //总共几页

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((data) => {
      this.userId = data.userId;
    });
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      if (this.userId == this.local.get('userinfo').id) {
        this.isMe = true;
        this.userinfo = this.local.get('userinfo');
      } else {
        this.isMe = false;
      }
    } else {
      this.isMe = false;
    }

    this.subscription = router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        if (event.url.indexOf('user') > -1) {
          // console.log("event:", event.url.indexOf('user'));
          let idId = 0;
          idId = Number(event.url.substr(event.url.indexOf('userId=') + 7));
          if (event.url.indexOf('&') > 0) {
            idId = Number(event.url.substr(
              event.url.indexOf('userId=') + 7,
              event.url.indexOf('&') - event.url.indexOf('userId=') - 7
            ));
          }
          // console.log("useridId:", idId)
          this.userId = idId;

          this.getMyCollect();

          if (this.local.get('userinfo') && idId == this.local.get('userinfo').id) {
            this.userId = this.local.get('userinfo').id;
            this.isMe = true;
          } else {
            this.isMe = false;
            this.userId = idId;
          }
          // }
        }

      }
    });
  }

  ngOnInit() {
    this.getMyCollect();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * 获取我的收藏列表user/user/myArticles
   */
  getMyCollect() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|mycollect');
    var api = 'question/question_operate/myCollect';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'page': this.page,
      'page_num': this.page_num,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.myCollectList = [];
        this.total = [];
        if (result.data != null && result.data.length > 0) {
          this.myCollectList = result.data;
          this.local.fmDates(this.myCollectList);
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }


  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getMyCollect();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getMyCollect();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getMyCollect();
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
  }

  goColumn(column) {

  }


}
