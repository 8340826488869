import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartService {

goodslist:any = [];
constructor() { }

setCheckedGoods(goods){
  this.goodslist = goods;
}

getCheckedGoods(){
  return this.goodslist;
  
}

}
