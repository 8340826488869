import { UserComponent } from './../user.component';
import { HttpService } from './../../services/http.service';
import { Router, ActivatedRoute, NavigationExtras, RoutesRecognized } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Md5 } from 'ts-md5';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { QRCodeModule } from 'angular2-qrcode';


@Component({
  selector: 'app-myhome',
  templateUrl: './myhome.component.html',
  styleUrls: ['./myhome.component.less']
})
export class MyhomeComponent implements OnInit {

  subscription: Subscription;

  userId = 0;
  isMe = false;
  userinfo: any = {};   //本地保存的用户登录信息

  userlink = '';          //个人网站

  dynamicList: any = [];  //获取到的个人动态列表

  tagList: any = [];      //获取到的所有技能标签
  mySkills: any = [];     //获取我的技能列表

  isShowAddSkills = false;      //是否显示立即添加技能

  page = 1;                   //列表显示第几页
  page_num = 10;              //每页显示的数量
  total: any = [];            //总共几页


  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
    private userPage: UserComponent,
  ) {
    route.queryParams.subscribe((data) => {
      this.userId = data.userId;
    });

    window.scrollTo(0, 0);

    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      if (this.userId == this.local.get('userinfo').id) {
        this.isMe = true;
        this.userinfo = this.local.get('userinfo');
      } else {
        this.isMe = false;
        this.getUserInfo(this.userId);
      }
    } else {
      this.isMe = false;
      this.getUserInfo(this.userId);
    }

    this.subscription = router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        window.scrollTo(0, 0);
        if (event.url.indexOf('user') > -1) {
          // console.log("event:", event.url.indexOf('user'));
          let idId = 0;
          idId = Number(event.url.substr(event.url.indexOf('userId=') + 7));
          if (event.url.indexOf('&') > 0) {
            idId = Number(event.url.substr(
              event.url.indexOf('userId=') + 7,
              event.url.indexOf('&') - event.url.indexOf('userId=') - 7
            ));
          }
          // console.log("useridId:", idId)
          this.userId = idId;
          this.getUserInfo(this.userId);

          this.getDynamicList();
          this.getNumberInfo();
          this.getMySkills();

          if (this.local.get('userinfo') && idId == this.local.get('userinfo').id) {
            this.userId = this.local.get('userinfo').id;
            this.isMe = true;
            this.getNumberInfo();
          } else {
            this.isMe = false;
            this.userId = idId;
            this.getNumberInfo();
          }
          // }
        }

      }
    });
  }

  ngOnInit() {
    //获取个人动态列表（只要5个）
    this.getDynamicList();

    //获取个人认证与成就user/user/getNumberInfo
    this.getNumberInfo();

    //获取所有技能标签列表（二级标签）

    //获取我的技能列表user/user/getSkills
    this.getMySkills();

    //获取我的注册时间、个人主页被浏览数

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * 通过用户id获取用户信息user/user/getNewInfo
   */
  getUserInfo(id) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
    var api = 'user/user/getNewInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': id,
      'my_user_id': 0,
      'sessionid': 0,
    }
    if (this.local.get('userinfo') == null) {
      body.my_user_id = 0;
    } else {
      body.my_user_id = this.local.get('userinfo').id;
      body.sessionid = this.local.get('userinfo').sessionid;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.userinfo = result.data;
      }
    })
  }
  /**获取个人动态列表 */
  getDynamicList() {
    // console.log("获取5个最新个人动态");
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|alldynamiclist');
    var api = 'user/dynamic/allDynamicList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'page': this.page,
      'page_num': 5,
    }
    // console.log("body", body);
    // return;
    this.http.doPost(api, body, (result) => {
      //  console.log(result);
      this.dynamicList = [];
      if (result.code == "200") {
        this.dynamicList = result.data;
        this.local.fmDates(this.dynamicList);
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })

  }

  goUserHome(id) {
    this.userId = id;
    let navigationExtras: NavigationExtras = {
      queryParams: { 'userId': id, },
    }
    this.router.navigate(['/user/home'], navigationExtras);

    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      if (this.userId == this.local.get('userinfo').id) {
        this.isMe = true;
        this.userinfo = this.local.get('userinfo');
      } else {
        this.isMe = false;
        this.getUserInfo(this.userId);
      }
    } else {
      this.isMe = false;
      this.getUserInfo(this.userId);
    }
    this.getDynamicList();
    this.getNumberInfo();
    this.getMySkills();
  }

  numberInfo: any = {};
  /**
   * 获取个人认证与成就user/user/getNumberInfo
   */
  getNumberInfo() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnumberinfo');
    var api = 'user/user/getNumberInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'sessionid': this.userinfo.sessionid
    }
    this.http.doPost(api, body, (result) => {
      this.numberInfo = {};
      if (result.code == "200") {
        this.numberInfo = result.data;
        this.numberInfo.create_time = this.numberInfo.create_time + '000';
      }
    })
  }

  /**
   * 获取所有标签列表question/question_label/allLabelList
   */
  getTagList() {
    // let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|alllabellist');
    // var api = 'question/question_label/allLabelList';
    // var body = {
    //   'timestamp': new Date().getTime().toString().substr(0, 10),
    //   'signature': signature,
    // }
    // // console.log("body", body);
    // this.http.doPost(api, body, (result) => {
    //   // console.log(result);
    //   this.tagList = [];
    //   if (result.code == "200") {
    //     result.data.forEach(tag1 => {
    //       tag1.labelList.forEach(tag => {
    //         this.tagList.push(tag);
    //       });
    //     });
    //     this.mySkills.forEach(skills => {
    //       this.tagList.forEach(tag => {
    //         if (skills.id == tag.id) tag.checked = true;
    //       });
    //     });
    //   }
    // })

    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|alllabellist');
    var api = 'question/question_label/allLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.tagList = result.data;
        for (let x = 0; x < this.mySkills.length; x++) {
          for (let y = 0; y < this.tagList.length; y++) {
            for (let z = 0; z < this.tagList[y].labelList.length; z++) {
              if (this.mySkills[x].id == this.tagList[y].labelList[z].id) {
                this.tagList[y].labelList[z].checked = true;
              }
            }
          }
        }
        this.labelList = this.tagList[0].labelList;
      }
    })
  }


  /**
   * 获取用户的技能列表user/user/getSkills
   */
  getMySkills() {
    // console.log("获取我拥有的技能");
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getskills');
    var api = 'user/user/getSkills';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
    }
    // console.log("body", body);
    // return;
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      this.mySkills = [];
      if (result.code == "200") {
        this.mySkills = result.data;
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
      this.getTagList();
    })
  }

  // checkChange(e, key): void {
  //   // console.log(e);
  //   this.tagList[key].checked = !this.tagList[key].checked;
  //   if (this.mySkills != null && this.mySkills.length > 0) {
  //     this.mySkills =getMySkillskey].checked) {
  //     this.mySkills.push(e);
  //   }
  //   // console.log("this.mySkills=", this.mySkills.toString());
  //   // console.log("this.mySkills.length=", this.mySkills.length);
  // }
  // delTag(e, key): void {
  //   this.mySkills.splice(key, 1);
  //   this.tagList.forEach(tag => {
  //     if (tag.id == e.id) tag.checked = false;
  //   });
  // }

  thisbtn = 0;
  labelList: any = [];
  visible = false;
  /**切换标签 */
  selectChange(num) {
    this.thisbtn = num;
    this.labelList = this.tagList[num].labelList;
  }
  checkChange(e, key): void {
    // console.log(e);
    this.tagList[this.thisbtn].labelList[key].checked = !this.tagList[this.thisbtn].labelList[key].checked;
    this.mySkills = this.mySkills.filter(value => value.id != e.id);
    if (this.tagList[this.thisbtn].labelList[key].checked) {
      this.mySkills.push(e);
    }
    this.visible = false;
    setTimeout(() => {
      this.visible = true;
    }, 10);
    // console.log("this.mySkills=", this.mySkills.toString());
    // console.log("this.mySkills.length=", this.mySkills.length);
  }
  delTag(e, key): void {
    this.mySkills.splice(key, 1);
    this.tagList.forEach(tag1 => {
      tag1.labelList.forEach(tag => {
        if (tag.id == e.id) tag.checked = false;
      });
    });
  }

  /**显示添加技能对话框 */
  showEditSkills() {
    if (this.isShowAddSkills) {
      // console.log("保存技能修改", this.mySkills);
      this.editSkills();
    }
    this.thisbtn = 0;
    this.isShowAddSkills = !this.isShowAddSkills;
  }

  /**
   * 保存技能修改
   */
  editSkills() {
    // console.log("修改个人资料");
    var skills = '';
    for (let i = 0; i < this.mySkills.length; i++) {
      // skills.push(this.mySkills[i].id);
      if (skills == '') {
        skills = this.mySkills[i].id;
      } else {
        skills = skills + ',' + this.mySkills[i].id;
      }
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|changeinfo');
    var api = 'user/User/changeInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'id': this.userinfo.id,
      'sex': this.userinfo.sex,
      'birthday': this.userinfo.birthday,
      'work': this.userinfo.work,
      'user_login': this.userinfo.user_login,
      'old_user_pass': this.userinfo.old_user_pass,
      'user_pass': this.userinfo.user_pass,
      'user_repass': this.userinfo.user_repass,
      'user_email': this.userinfo.user_email,
      'user_url': this.userinfo.user_url,
      'avatar': this.userinfo.avatar,
      'company': this.userinfo.company,
      'address': this.userinfo.address,
      'skills': skills,
      'sessionid': this.userinfo.sessionid
    }
    // console.log("body", body);
    // return;
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.userinfo.user_login = body.user_login;
        this.getMySkills();
        this.local.set('userinfo', this.userinfo);
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**点击提交修改user/User/changeInfo */
  submitEdit() {
    if (!this.userlink) {
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: "网址不得为空",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }
    // console.log("user修改个人资料");
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|changeinfo');
    var api = 'user/User/changeInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'id': this.userinfo.id,
      'sex': this.userinfo.sex,
      'birthday': this.userinfo.birthday,
      'work': this.userinfo.work,
      'user_login': this.userinfo.user_login,
      'old_user_pass': this.userinfo.old_user_pass,
      'user_pass': this.userinfo.user_pass,
      'user_repass': this.userinfo.user_repass,
      'user_email': this.userinfo.user_email,
      'user_url': this.userlink,
      'avatar': this.userinfo.avatar,
      'company': this.userinfo.company,
      'address': this.userinfo.address,
      'skills': this.userinfo.skills,
      'signatureword': this.userinfo.signatureword,
      'sessionid': this.userinfo.sessionid
    }
    // console.log("body", body);
    // return;
    this.http.doPost(api, body, (result) => {
      // console.log("user修改个人资料", result);
      if (result.code == "200") {
        this.local.set('userinfo', this.userinfo);
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }


  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getDynamicList();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getDynamicList();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getDynamicList();
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
  }

  setShare(val) {
    // let linshi = 'http://service.weibo.com/share/share.php?title=Mob&url=http%3A%2F%2Fwww.baidu.com%2Fcode%2Fdemo%2F%23&pic=#_loginLayer_1574669916123'
    var linshi;
    if (val == 'weibo') {
      linshi = 'http://service.weibo.com/share/share.php?title=推荐【'
        + this.userinfo.user_login
        + '】的个人主页挺不错的。传送门&url=https%3A%2F%2Fpgfans.cn%2Fuser%2Fhome%3FuserId='
        + this.userinfo.id
    } else if (val == 'qq') {
      //https://connect.qq.com/widget/shareqq/index.html?url=https%3A%2F%2Fwww.uisdc.com%2Ftencent-animation-8-0&title=%E5%A5%BD%E6%96%87%EF%BC%81%E3%80%90%E7%82%B9%E5%87%BB%E7%8E%87%E6%8F%90%E5%8D%87115%25%EF%BC%81%E8%85%BE%E8%AE%AF%E5%8A%A8%E6%BC%AB8.0%E6%94%B9%E7%89%88%E5%AE%9E%E6%88%98%E5%AE%8C%E6%95%B4%E5%A4%8D%E7%9B%98%E3%80%91%E6%8E%A8%E8%8D%90%E7%BB%99%E5%A4%A7%E5%AE%B6%EF%BC%81%E5%8E%9F%E6%96%87%E6%88%B3%E2%86%92&summary=&site=baidu&pics=https%3A%2F%2Fimage.uisdc.com%2Fwp-content%2Fuploads%2F2020%2F01%2Fuisdc-banner-20200106-2.jpg
      linshi = 'https://connect.qq.com/widget/shareqq/index.html?title=推荐【'
        + this.userinfo.user_login
        + '】的个人主页挺不错的。传送门&url=https%3A%2F%2Fpgfans.cn%2Fuser%2Fhome%3FuserId='
        + this.userinfo.id
    }
    // yijianshengcai%3Futm_source%3DWeibo%26utm_medium%3DshareLink%26utm_campaign%3DsocialShare&pic=https%3A%2F%2Fcdn.segmentfault.com%2Fv-5dcd2518%2Fglobal%2Fimg%2Fuser-256.png&appkey=1742025894&searchPic=false#_loginLayer_1576841667829'

    window.open(linshi, '_blank')
  }

  isShow2code = false;
  qrcode = ''
  show2code() {
    this.qrcode = 'https://m.pgfans.cn/user/home?userId=' + this.userinfo.id;
    this.isShow2code = true;
  }
  codeCancel() {
    this.isShow2code = false;
  }
}