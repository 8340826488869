import { HttpService } from './../../services/http.service'; import { Md5 } from "ts-md5";
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.less']
})
export class TagComponent implements OnInit {

  userinfo: any = {};     //本地保存的登录用户信息

  columnId = 0;           //本页所属专栏id
  columnDetail: any = {}; //专栏详情
  tagList: any = [];      //获取到的本专栏标签列表

  article_type_number = 1;//文章类型：1=最新发布；2=热门文章；3=推荐文章
  articleList: any = [];  //专栏文章列表分页

  userList: any = [];     //本专栏作者列表

  page = 1;                   //列表显示第几页
  page_num = 10;              //每页显示的数量
  total: any = [];            //总共几页
  page_prev = 1;
  page_next = 5;


  isMore = false;       //是否显示更多顶部标签
  searchArticle = '';   //搜索内容

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
    private meta:Meta,
    private titleServce:Title,
  ) {
    route.params.subscribe((data) => {
      this.columnId = data.id;
    });
    this.userinfo = this.local.get('userinfo');
  }

  ngOnInit() {
    //获取专栏下的标签column/columns/getArticlesLabels
    this.getArticlesLabels();

    //获取专栏的详细信息column/columns/getColumnDetail
    this.getColumnDetail();  

    //获取专栏下文章列表（分页）column/columns/getAllArticles
    this.getAllArticles();

    //获取本专栏的作者column/columns/getColumnAuthor
    this.getUserList(); 
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
    this.titleServce.setTitle('PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
    this.meta.updateTag({ name: 'description', content: 'PGFans问答社区由中国开源软件联盟PostgreSQL分会发起，是全球唯一的PostgreSQL中文技术交流社区，PGFans问答社区主要服务于与PostgreSQL相关的DBA、应用开发、内核开发人员，作为世界上最先进的开源数据库，PostgreSQL发展趋势不断向上，并荣获2017、2018、2020年年度数据库。'});
    this.meta.updateTag({ name: 'keywords', content: 'PG社区,问答社区,技术社区'});
  }

  /**
   * 获取专栏的详细信息column/columns/getColumnDetail
   */
  getColumnDetail() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getcolumndetail');
    var api = 'column/columns/getColumnDetail';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'column_id': this.columnId,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    // console.log("body", body);is_follow
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.columnDetail = result.data;
        // console.log(this.columnDetail);
        this.setMeta();
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**
   * 获取专栏下的标签column/columns/getArticlesLabels
   */
  getArticlesLabels() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getarticleslabels');
    var api = 'column/columns/getArticlesLabels';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'column_id': this.columnId,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.tagList = result.data;
          
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }

  /**
   * 获取专栏下文章列表（分页）column/columns/getNewArticles
   */
  getAllArticles() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getallarticles');
    var api = 'column/columns/getAllArticles';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'page': this.page,
      'page_num': this.page_num,
      'label_id': 0,
      'column_id': this.columnId,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.articleList = result.data;
          for(let i=0;i<this.articleList.length;i++){
            var index1 = this.articleList[i].introduce.indexOf('提取码');
            var index2 = this.articleList[i].introduce.indexOf('密码');
            if(index1>-1){
              this.articleList[i].introduce = this.articleList[i].introduce.slice(0,index1)
            }
            if(index2>-1){
              this.articleList[i].introduce = this.articleList[i].introduce.slice(0,index2)
            }
            
          }
          this.local.fmDates(this.articleList);
          this.total = [];
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }

  /**
   * 获取本专栏的作者column/columns/getColumnAuthor
   */
  getUserList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getcolumnauthor');
    var api = 'column/columns/getColumnAuthor';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'column_id': this.columnId,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null) {
          this.userList = result.data;
        }
      }
    })
  }

  /**
   * 关注操作question/question_operate/operate
   */
  doFouce() {
    if (!this.checkUser()) {
      return;
    }
    // if (!this.userinfo.id) {
    //   this.modalService.warning({
    //     nzTitle: "系统提示",
    //     nzContent: "请先登录",
    //     nzOkText: "关闭",
    //     nzOnOk: () => {
    //       this.router.navigate(['/login']);
    //     },
    //   })
    //   return;
    // }
    var operate_type = 1;
    if (this.columnDetail.is_follow) {
      operate_type = 3;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'question/question_operate/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'question_id': this.columnId,
      'operate_type': operate_type,
      'type': 3,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.getColumnDetail();
      }
    })
  }

  /**
   * 点赞操作question/question_operate/operate
   * @param article 文章json
   * @param key 
   */
  doAgree(article, key) {
    if (!this.checkUser()) {
      return;
    }
    if (article.is_thumb_up) {
      var type = 6;
    } else {
      var type = 5;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'question/question_operate/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'question_id': article.id,
      'operate_type': type,
      'type': 1,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.getAllArticles();
      }
    })
  }

  /**
   * 搜索专栏下的文章column/columns/searchArticles
   * @param event 
   */
  doSearch(event) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|searcharticles');
    var api = 'column/columns/searchArticles';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'column_id': this.columnId,
      'key': event,
      // 'user_id': this.userinfo.id,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.articleList = result.data;
        this.local.fmDates(this.articleList);
        this.total = [];
        // for (let i = 0; i < result.page_info.total; i++) {
        //   this.total.push({ num: i + 1, checked: this.page == i + 1 });
        // }
      }
    })
  }

  setMeta(){
    this.titleServce.setTitle(this.columnDetail.title+'-PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
    var words = this.columnDetail.title;
    for(let i=0;i<this.tagList.length;i++){
      words = words+','+this.tagList[i].label;
    }
    this.meta.updateTag({ name: 'description', content: this.columnDetail.introduce});
    this.meta.updateTag({ name: 'keywords', content: words })
  }


  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getAllArticles();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getAllArticles();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getAllArticles();
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
    if (this.page == 1) {
      this.page_prev = this.page;
      this.page_next = this.page + 4;
    } else if (this.page == 2) {
      this.page_prev = this.page - 1;
      this.page_next = this.page + 3;
    } else if (this.page == this.total.length - 1) {
      this.page_prev = this.page - 3;
      this.page_next = this.page + 1;
    } else if (this.page == this.total.length) {
      this.page_prev = this.page - 4;
      this.page_next = this.page;
    } else {
      this.page_prev = this.page - 2;
      this.page_next = this.page + 2;
    }
  }
   /**向前5页 */
   prev(num) {
    if (num - 5 < 1) {
      this.page = 1;
    } else {
      this.page = num - 5;
    }
    this.updatepage();
    this.getAllArticles();
  }
  /**向后5页 */
  next(num) {
    if (num + 5 > this.total.length) {
      this.page = this.total.length;
    } else {
      this.page = num + 5;
    }
    this.updatepage();
    this.getAllArticles();
  }


  // 静态页面设置数据
  // loadQList(pi: number): void {
  // this.data = new Array(5).fill({}).map((_, index) => {
  //   return {
  //     href: 'http://ant.design',
  //     title: `ant design part ${index} (page: ${pi})`,
  //     avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
  //     description: 'Ant Design, a design language for background applications, is refined by Ant UED Team.',
  //     content:
  //       'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.'
  //   };
  // });
  // }

  showMore() {
    this.isMore = !this.isMore;
  }

  /**
   * 检查是否已经登录
   */
  checkUser() {
    if (!this.userinfo) {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'from': 'adetail', },
      }
      this.router.navigate(['/login'], navigationExtras)
      return false;
    }
    return true;
  }
}
