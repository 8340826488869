import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-banklist',
  templateUrl: './banklist.component.html',
  styleUrls: ['./banklist.component.less']
})
export class BanklistComponent implements OnInit {

  constructor(
    private router: Router,
    private local: StorageService,
  ) { }

  ngOnInit() {
    this.local.set('isSure',true);
  }


}
