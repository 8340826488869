import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { NzModalService } from 'ng-zorro-antd';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.less']
})
export class DatabaseComponent implements OnInit {

  leftTab = 0;
  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
  ) { 
    if(sessionStorage.getItem('leftTab')!=null){
      var tab = sessionStorage.getItem('leftTab');
      if(tab == '4'&&this.local.get('userinfo') == null){
        this.leftTab = 0; 
        // router.navigate(['/']);
      }else{
        this.leftTab = parseInt(tab);
      }
    }
    this.goTab(this.leftTab);
    
   
  }

  ngOnInit() {
  }

  goTab(index){
    this.leftTab = index;
    sessionStorage.setItem('leftTab',index);
    switch(index) {
      case 0:
        this.router.navigate(['/database/manual']);
         break;
      case 1:
        this.router.navigate(['/database/means']);
         break;
      case 2:
        this.router.navigate(['/database/books'])
        break;
      case 3:
        this.router.navigate(['/database/course']);
        break;
      case 4:
        if(this.local.get('userinfo') == null){
          this.router.navigate(['/login'],{queryParams:{bank:1}});
        }else{   
          if(this.local.get('isSure')){
            this.router.navigate(['/database/banklist']);
          }else{
            this.router.navigate(['/database/q_bank']);  
          }
         
        }  
    } 
   
  }



}
