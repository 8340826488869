import { UserComponent } from '../user.component';
import { HttpService } from '../../services/http.service';
import { Router, ActivatedRoute, RoutesRecognized, NavigationExtras } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from '../../services/storage.service';
import { Md5 } from 'ts-md5';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-myfocus',
  templateUrl: './myfocus.component.html',
  styleUrls: ['./myfocus.component.less']
})
export class MyfocusComponent implements OnInit {

  subscription: Subscription;
  isLoadingOne: boolean = false;
  isLoadingTwo: boolean = false;
  userId = 0;
  isMe = false;
  userinfo: any = {};   //本地保存的用户登录信息
  fouceTab = 0;
  value: any;             //临时数据，防止报错

  data: any = [];         //临时数据，放列表用的
  recommends: any = [];   //右侧推荐关注的列表

  tags: any = [];         //二级标签
  followLabelList: any = [];      //关注的标签列表
  followUserList: any = [];       //关注的人列表
  followedColumnList: any = [];   //关注的专栏列表
  followQuestionList: any = [];   //关注的问题列表

  thisTab = 0;            //当前选中的tab，0=标签；1=人；2=专栏；3=问题。默认0关注的标签

  page = 1;                   //列表显示第几页
  page_num = 10;              //每页显示的数量
  total: any = [];            //总共几页

  nzFilterOption = () => true;
  tagList: any = [];
  userList: any = [];
  collectList: any = [];
  questionList: any = [];
  fouceTagId = 0;
  fouceUserId = 0;
  fouceCollectId = 0;
  fouceQuestionId = 0;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
    private userPage: UserComponent,
  ) {
    route.queryParams.subscribe((data) => {
      this.userId = data.userId;
      if (data.fouceTab > 0) {
        this.thisTab = data.fouceTab;
        // console.log("thisTab:", this.thisTab);
      }
    });
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      if (this.userId == this.local.get('userinfo').id) {
        this.isMe = true;
        this.userinfo = this.local.get('userinfo');
      } else {
        this.isMe = false;
        this.getUserInfo(this.userId);
      }
    } else {
      this.isMe = false;
      this.getUserInfo(this.userId);
    }

    this.subscription = router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        if (event.url.indexOf('user') > -1) {
          // console.log("event:", event.url.indexOf('user'));
          let idId = 0;
          idId = Number(event.url.substr(event.url.indexOf('userId=') + 7));
          if (event.url.indexOf('&') > 0) {
            idId = Number(event.url.substr(
              event.url.indexOf('userId=') + 7,
              event.url.indexOf('&') - event.url.indexOf('userId=') - 7
            ));
          }
          // console.log("useridId:", idId)
          this.userId = idId;
          this.getUserInfo(this.userId);

          this.getMyFocus(this.thisTab);
          this.getRecommends();
          this.getAllTags();

          if (this.local.get('userinfo') && idId == this.local.get('userinfo').id) {
            this.userId = this.local.get('userinfo').id;
            this.isMe = true;
          } else {
            this.isMe = false;
            this.userId = idId;
          }
          // }
        }

      }
    });
  }

  ngOnInit() {
    //获取关注的某列表
    this.getMyFocus(this.thisTab);

    //获取推荐关注的列表question/question_label/recLabelList
    this.getRecommends();

    this.getAllTags();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getUserInfo(id) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
    var api = 'user/user/getNewInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': id,
      'my_user_id': 0,
      'sessionid': 0,
    }
    if (this.local.get('userinfo') == null) {
      body.my_user_id = 0;
    } else {
      body.my_user_id = this.local.get('userinfo').id;
      body.sessionid = this.local.get('userinfo').sessionid;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.userinfo = result.data;
        this.userPage.userinfo = this.userinfo;
      }
    })
  }
  /**
   * 切换tab触发事件
   * @param e 点击的信息
   */
  changeTab(e) {
    // console.log(e.index);
    this.thisTab = e.index;
    this.page = 1;
    this.getMyFocus(this.thisTab);
  }

  /**获取用户的关注标签 */
  getMyFocus(tab) {
    switch (Number(tab)) {
      //0=标签；1=人；2=专栏；3=问题
      case 0:
        var signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|followlabellists');
        var api = 'question/label_follow/followLabelLists';
        break;
      case 1:
        var signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|userfollowlist');
        var api = 'user/user_follow/userFollowList';
        break;
      case 2:
        var signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|myfollowedcolumns');
        var api = 'user/user/myFollowedColumns';
        break;
      case 3:
        var signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|myfollowquestion');
        var api = 'question/question_operate/myFollowQuestion';
        break;
      default:
        var signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|mycollect');
        var api = 'question/question_operate/myCollect';
        break;
    }
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'page': this.page,
      'page_num': this.page_num,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      this.followLabelList = [];
      this.followUserList = [];
      this.followedColumnList = [];
      this.followQuestionList = [];
      this.followLabelList = [];
      if (result.code == "200") {
        switch (Number(tab)) {
          //0=标签；1=人；2=专栏；3=问题
          case 0:
            this.followLabelList = result.data;
            break;
          case 1:
            this.followUserList = result.data;
            this.local.fmDates(this.followUserList);
            break;
          case 2:
            this.followedColumnList = result.data;
            break;
          case 3:
            this.followQuestionList = result.data;
            break;
          default:
            this.followLabelList = result.data;
            break;
        }
        this.total = [];
        for (let i = 0; i < result.page_info.total; i++) {
          this.total.push({ num: i + 1, checked: this.page == i + 1 });
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
  timer: any;
  //**输入字母或文字动态显示列表 */
  search(event: string): void {
    if (!event) {
      this.tagList = [];
      this.userList = [];
      this.collectList = [];
      this.questionList = [];
      return;
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.doSearch(event);
    }, 400)
  }
  doSearch(event) {
    // console.log(event);
    this.initializeItems();
    var val = event;//.target.value;
    if (val == null || val == undefined || val.trim() == '') {
      return;
    }
    var Temp: any = null;
    Temp = [];
    switch (this.thisTab) {
      case 1:
        this.tagList.filter((v) => {
          var py = v.pinYinName.replace(' ', '').toLowerCase().indexOf(val.replace(' ', '').toLowerCase());
          var cname = v.city.replace(' ', '').toLowerCase().indexOf(val.replace(' ', '').toLowerCase());
          if (py > -1 || cname > -1) {
            Temp.push(v);
          }
        })
        this.tagList = Temp;
        break;
      case 2:
        this.userList.filter((v) => {
          var py = v.pinYinName.replace(' ', '').toLowerCase().indexOf(val.replace(' ', '').toLowerCase());
          var cname = v.city.replace(' ', '').toLowerCase().indexOf(val.replace(' ', '').toLowerCase());
          if (py > -1 || cname > -1) {
            Temp.push(v);
          }
        })
        this.userList = Temp;
        break;
      case 3:
        this.collectList.filter((v) => {
          var py = v.pinYinName.replace(' ', '').toLowerCase().indexOf(val.replace(' ', '').toLowerCase());
          var cname = v.city.replace(' ', '').toLowerCase().indexOf(val.replace(' ', '').toLowerCase());
          if (py > -1 || cname > -1) {
            Temp.push(v);
          }
        })
        this.collectList = Temp;
        break;
      case 4:
        this.questionList.filter((v) => {
          var py = v.pinYinName.replace(' ', '').toLowerCase().indexOf(val.replace(' ', '').toLowerCase());
          var cname = v.city.replace(' ', '').toLowerCase().indexOf(val.replace(' ', '').toLowerCase());
          if (py > -1 || cname > -1) {
            Temp.push(v);
          }
        })
        this.questionList = Temp;
        break;

      default:
        break;
    }
  }
  //**优化用户列表数据结构，便于搜索 */
  initializeItems() {
    // this.cityList = this.citys;
    // for (let i = 0; i < this.cityList.length; i++) {
    //   this.cityList[i].pinYinName = tr(this.cityList[i].city).toLowerCase().split(" ").join("");
    //   // console.log(this.cityList[i].pinYinName);
    // }
  }

  userChangeTime: any;
  users: any = [];
  /**
   * 输入关注的人时，输入框变化触发事件
   * @param event 输入框的数据
   */
  userChange(event) {
    if (this.userChangeTime) {
      clearTimeout(this.userChangeTime);
    }
    this.userChangeTime = setTimeout(() => {
      // console.log(event);
      this.doSearchUsers(event);
    }, 500)
  }
  doSearchUsers(event) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getsearchusers');
    var api = 'user/user/getSearchUsers';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'key': event,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.users = result.data;
      }
    })
  }

  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getMyFocus(this.thisTab);
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getMyFocus(this.thisTab);
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getMyFocus(this.thisTab);
  }
  /**更新页面选择器 */
  updatepage() {
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
  }

  /**
   * 获取推荐关注的列表question/question_label/recLabelList
   */
  getRecommends() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|reclabellist');
    var api = 'question/question_label/recLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      this.recommends = [];
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.recommends = result.data;
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }


  /**
   * 获取所有标签列表（分一二级）question/question_label/allLabelList
   */
  getAllTags() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|alllabellist');
    var api = 'question/question_label/allLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.tagList = result.data;

          result.data.forEach(tag1 => {
            tag1.labelList.forEach(tag => {
              this.tags.push(tag);
            });
          });
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }
  /**
   * 关注标签question/label_follow/addFollowLabel
   */
  followLabel(id) {
    this.fouceTagId = id;
    this.isLoadingOne = true;
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addfollowlabel');
    var api = 'question/label_follow/addFollowLabel';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'label_id': this.fouceTagId,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      this.isLoadingOne = false;
      if (result.code == "200") {
        this.getMyFocus(this.thisTab);
        this.getRecommends();
        this.modalService.success({
          nzTitle: "关注标签",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      } else {
        this.modalService.warning({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**
   * 取消关注标签question/label_follow/deleteFollowLabel
   */
  delFollowLabel(id, key) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|deletefollowlabel');
    var api = 'question/label_follow/deleteFollowLabel';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'label_id': id,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.followLabelList.splice(key, 1);
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
  /**
   * 关注用户操作user/user_follow/operate
   */
  operateUser(id, key, type) {
    this.isLoadingTwo = true
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'user/user_follow/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'about_user_id': id,
      'type': type,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      this.isLoadingTwo = false
      // console.log(result);
      if (result.code == "200") {
        if (key > -1) {
          this.followUserList.splice(key, 1);
          return;
        }
        this.getMyFocus(1);
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }
  /**
   * 关注操作question/question_operate/operate问题、文章和专栏
   */
  operate(id, key, oType, type) {
    if (!this.checkUser()) {
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'question/question_operate/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'question_id': id,
      'operate_type': oType,
      'type': type,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (type == 2) {
          this.followQuestionList.splice(key, 1);
        } else if (type == 3) {
          this.followedColumnList.splice(key, 1);
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }

  /**跳转到用户页面 */
  goUserPage(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'userId': id, },
    }
    this.router.navigate(['user/home'], navigationExtras)
    this.userPage.getUserInfo(id)
  }

  /**
   * 检查是否已经登录
   */
  checkUser() {
    if (!this.userinfo.id) {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'from': 'adetail', },
      }
      this.router.navigate(['/login'], navigationExtras)
      return false;
    }
    return true;
  }
}
