import { HttpService } from '../../services/http.service';
import { Md5 } from "ts-md5";
import { NzModalService } from 'ng-zorro-antd';
import { Router,  ActivatedRoute } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.less']
})
export class CourseComponent implements OnInit {
  courseList:any = [];
  type = 1;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
  ) { 
    this.getCourseList()
  }

  ngOnInit() {
  }

  /**
  *   获取教程列表
   */
  getCourseList(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|courselist');
    var api = 'activitydata/activity/courseList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'type': this.type,
    }
    console.log(body)
    this.http.doPost(api, body, (result) => {
      console.log(result)
      if (result.code == "200") {
        this.courseList = result.data;
      }
    })
  }

  changeType(index){
    this.type = index;
    this.getCourseList();
  }

}
