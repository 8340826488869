import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-myjw',
  templateUrl: './myjw.component.html',
  styleUrls: ['./myjw.component.less']
})
export class MyjwComponent implements OnInit {
  userId = 0;
  myJwList: any = [];       //我的回答列表

  page = 1;                   //列表显示第几页
  page_num = 10;              //每页显示的数量
  total: any = [];            //总共几页


  constructor(
    private route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((data) => {
      this.userId = data.userId;
    });
  }

  ngOnInit() {
    this.getMyanswers();
  }

  /**获取我的回答列表 */
  getMyanswers() {
    this.myJwList = [{
      title: '资深开发工程师，现寻找新机会',
      name: '李东',           //姓名
      sex: 0,                //性别
      salary: '',            //薪资
      place: '北京',          //工作地点
      position: '开发工程师', //职位
      years: 2,              //工作年限
      education: 3,          //学历：0=高中；1=专科；2=本科；3=研究生；4=博士生
      skills: [              //必备技能
        { name: 'PostgresSQL' },
        { name: 'Modeling' },
      ],
      date: new Date(),
    },]
  }


  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
  // console.log(this.page);
    this.getMyanswers();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
  // console.log(this.page);
    this.getMyanswers();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
  // console.log(this.page);
    this.getMyanswers();
  }
  /**更新页面选择器 */
  updatepage() {
window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
  }

}
