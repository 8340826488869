import { Component, OnInit } from '@angular/core';
import { Md5 } from 'ts-md5';
import { HttpService } from './../../services/http.service';
import { Router, ActivatedRoute, NavigationExtras, RoutesRecognized } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { NzIconService } from 'ng-zorro-antd/icon';

@Component({
  selector: 'app-shopdetail',
  templateUrl: './shopdetail.component.html',
  styleUrls: ['./shopdetail.component.less']
})
export class ShopdetailComponent implements OnInit {

  cart_num = 0; // 购物车数量

  goods_id = 0; // 商品id
  gDetail: any = []; // 商品详情
  isConvert = true;
  g_num = 1; // 商品购买数量
  color = ''; // 商品颜色
  size = ''; // 商品尺码
  img_url = ''; //图片url

  regoods: any = []; // 推荐商品

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
    private iconService: NzIconService,
  ) { 
    route.queryParams.subscribe((data) => {
      this.goods_id = data.id;
      this.getGoodsDetail();
      this.recGoods();
    });
    this.iconService.fetchFromIconfont({
      scriptUrl: '//at.alicdn.com/t/font_2272955_7w83kj7dh1r.js',
    });
  }

  ngOnInit() {
    this.getShoppingCart();
    this.recGoods();
  }

  //获取商品详情goods/goods/getGoods
  getGoodsDetail(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getgoods');
    var api = 'goods/goods/getGoods';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'goods_id': this.goods_id,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.gDetail = result.data;
        if(this.gDetail.pgdou > this.local.get('userinfo').pgdou){
          this.isConvert = false;
        }
        this.img_url = this.gDetail.img_url;
        if(this.gDetail.colour){
          this.gDetail.colour = this.gDetail.colour.split(',');
        }
        if(this.gDetail.size){
          this.gDetail.size = this.gDetail.size.split(',');
        }
        document.getElementById('g_html').innerHTML = this.gDetail.content;
      }
    });
  }

  /**
   * 好物推荐goods/goods/recGoodsList
   */
  recGoods(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|recgoodslist');
    var api = 'goods/goods/recGoodsList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'goods_id': this.goods_id,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.regoods = result.data;
      }
    });
  }

  /**
   * 推荐好物详情
   */
  goDetail(id){
    this.router.navigate(['/g-detail'],{queryParams: {id: id}});
  }

  // 减一
  subtract(){
    this.g_num--;
  }
  // 加一
  add(){
    this.g_num++;
  }

  /**
   *我的购物车goods/cart/cartList
   */
  getShoppingCart(){
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      return;
    } 
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|cartlist');
    var api = 'goods/cart/cartList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
    };
    this.http.doPost(api, body, (result) => {
    //  console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.cart_num = result.data.length;
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }

 /**
   * 加入购物车goods/cart/addCart
   */
  addToCart(id){
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      this.router.navigate(['/login']);
      return;
    } 
    if(this.gDetail.colour){
      if(!this.color){
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: '请先选择您要的好物信息',
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
        return;
      }
    }
    if(this.gDetail.size){
      if(!this.size){
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: '请先选择您要的好物信息',
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
        return;
      }
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcart');
    var api = 'goods/cart/addCart';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'goods_id': id,
      'num': this.g_num,
      'size': this.size,
      'colour': this.color,
    };
    this.http.doPost(api, body, (result) => {
    //  console.log(result);
      if (result.code == "200") {
        // this.cart_num = this.cart_num + 1;
        this.getShoppingCart();
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }

  //立即兑换
  covert(){
    if(this.local.get('userinfo') == null || this.local.get('userinfo').id < 0){
      this.router.navigate(['/login']);
      return;
    }
    if(this.gDetail.colour){
      if(!this.color){
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: '请先选择您要的好物信息',
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
        return;
      }
    }
    if(this.gDetail.size){
      if(!this.size){
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: '请先选择您要的好物信息',
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
        return;
      }
    }
    
    var goodslist = {
      type: 1,
      goods_id: this.goods_id,
      num: this.g_num,
      color: this.color,
      size: this.size,
      img_url: this.gDetail.img_url,
      title: this.gDetail.title,
      pgdou: this.gDetail.pgdou,
      total: this.gDetail.pgdou * this.g_num,
    };
    

    this.router.navigate(['/or-confirm'],{queryParams: goodslist })
  }
  /**
   * 购物车
   */
  goCart(){
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      this.router.navigate(['/login']);
      return;
    }
    this.router.navigate(['/cart'],{queryParams: {userId: this.local.get('userinfo').id}})
  }

  // 切换图片
  changeImg(i){
    this.img_url = i;
  }


}
