import { Component, OnInit } from '@angular/core';
import { Md5 } from "ts-md5";
import { HttpService } from '../services/http.service';
import { StorageService } from '../services/storage.service';
import { NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.less']
})
export class FeedbackComponent implements OnInit {

  feedback_content = '';

  constructor(
    public http: HttpService,
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
  ) { 
    window.scrollTo(0,0);
    if(local.get('userinfo')==null){
      router.navigate(['/login']);
    }
  }

  ngOnInit() {
  }

  /**
   * 提交反馈user/user/addFeedback
   */
  doFeedback(){
    if(this.feedback_content == ''){
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: "请先输入反馈内容！",
        nzOkText: "关闭",
        nzOnOk: () => { },
      })
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addfeedback');
    var api = 'user/user/addFeedback';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'content':this.feedback_content,
    };
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.modalService.success({
          nzTitle: "反馈成功",
          nzContent: "感谢您对PGFans的关注与支持，我们会第一时间评估处理您的反馈。",
          nzOkText: "确定",
          nzOnOk: () => { 
            window.history.back();
          },
        })
      }
    });
  }

}
