import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-exambank',
  templateUrl: './exambank.component.html',
  styleUrls: ['./exambank.component.less']
})
export class ExambankComponent implements OnInit {

  index;
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private local: StorageService,
    private router: Router,
  ) { 
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      router.navigate(['/login']);
    }else{
      if(this.local.get('isSure')){
        route.queryParams.subscribe((data) => {
          var url = '../../../assets/tiku/tiku'+data.index+'.html';
          this.index = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
      }else{
        this.router.navigate(['/database/q_bank']);
      }    
    }
    
  }

  ngOnInit() {
  }

  getBank(i){

  }

}
