import { Observable, Observer, Subscription } from 'rxjs';
import { AppComponent } from './../../app.component';
import { HttpService } from './../../services/http.service';
import { Router, ActivatedRoute, NavigationExtras, RoutesRecognized } from '@angular/router';
import { NzModalService, NzMessageService, UploadFile } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Md5 } from 'ts-md5';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-myarticle',
  templateUrl: './myarticle.component.html',
  styleUrls: ['./myarticle.component.less']
})
export class MyarticleComponent implements OnInit {

  subscription: Subscription;

  userId = 0;
  isMe = false;
  userinfo: any = {};   //本地保存的用户登录信息

  myColumns: any = [];        //我的专栏列表
  myArticleList: any = [];    //我的文章列表

  page = 1;                   //列表显示第几页
  page_num = 10;              //每页显示的数量
  total: any = [];            //总共几页
  page_prev = 1;
  page_next = 5;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
    private appPage: AppComponent,
    private msg: NzMessageService,
  ) {
    route.queryParams.subscribe((data) => {
      this.userId = data.userId;
    });
    if (this.local.get('userinfo') != null && this.local.get('userinfo').id > 0) {
      if (this.userId == this.local.get('userinfo').id) {
        this.isMe = true;
        this.userinfo = this.local.get('userinfo');
      } else {
        this.isMe = false;
      }
    } else {
      this.isMe = false;
    }

    this.subscription = router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        if (event.url.indexOf('user') > -1) {
          // console.log("event:", event.url.indexOf('user'));
          let idId = 0;
          idId = Number(event.url.substr(event.url.indexOf('userId=') + 7));
          if (event.url.indexOf('&') > 0) {
            idId = Number(event.url.substr(
              event.url.indexOf('userId=') + 7,
              event.url.indexOf('&') - event.url.indexOf('userId=') - 7
            ));
          }
          // console.log("useridId:", idId)
          this.userId = idId;

          this.getMyColumns();
          this.getMyArticles();

          if (this.local.get('userinfo') && idId == this.local.get('userinfo').id) {
            this.userId = this.local.get('userinfo').id;
            this.isMe = true;
          } else {
            this.isMe = false;
            this.userId = idId;
          }
          // }
        }

      }
    });
  }

  ngOnInit() {
    this.getMyColumns();
    this.getMyArticles();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * 获取用户的专栏列表user/user/myArticles
   */
  getMyColumns() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|mycolumns');
    var api = 'user/user/myColumns';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'is_self': 1,
    }
    if (this.isMe) {
      body.is_self = 1;
    } else {
      body.is_self = 2;
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      this.myColumns = [];
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.myColumns = result.data;
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
  /**
   * 获取用户的文章列表user/user/myArticles
   */
  getMyArticles() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|myarticles');
    var api = 'user/user/myArticles';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userId,
      'sessionid': this.userinfo.sessionid,
      'page': this.page,
      'page_num': this.page_num,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      this.myArticleList = [];
      this.total = [];
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.myArticleList = result.data;
          this.local.fmDates(this.myArticleList);
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }

  goArticle(data) {
   
    this.router.navigate(['/a/'+data.id]);
  }


  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getMyArticles();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getMyArticles();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getMyArticles();
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0);
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = 'true';
      } else {
        this.total[i].checked = 'false';
      }
    }
    if (this.page == 1) {
      this.page_prev = this.page;
      this.page_next = this.page + 4;
    } else if (this.page == 2) {
      this.page_prev = this.page - 1;
      this.page_next = this.page + 3;
    } else if (this.page == this.total.length - 1) {
      this.page_prev = this.page - 3;
      this.page_next = this.page + 1;
    } else if (this.page == this.total.length) {
      this.page_prev = this.page - 4;
      this.page_next = this.page;
    } else {
      this.page_prev = this.page - 2;
      this.page_next = this.page + 2;
    }
  }
   /**向前5页 */
   prev(num) {
    if (num - 5 < 1) {
      this.page = 1;
    } else {
      this.page = num - 5;
    }
    this.updatepage();
    this.getMyArticles();
  }
  /**向后5页 */
  next(num) {
    if (num + 5 > this.total.length) {
      this.page = this.total.length;
    } else {
      this.page = num + 5;
    }
    this.updatepage();
    this.getMyArticles();
  }

  goColumn(column) {
    if (column.diyurl) {
      this.router.navigate(['blog/' + column.diyurl]);
    } else {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'id': column.id },
      }
      this.router.navigate(['/blogs-tag'], navigationExtras);
    }
  }
  isShowAddColumn = false;
  newBlog: any = {};
  // avatarUrl = "";
  /**显示修改专栏对话框 */
  showAddColumn(column) {
    this.isShowAddColumn = true;
    //
    this.getColumnDetail(column.id);
  }
  /**
   * 获取专栏信息column/columns/getColumnDetail
   */
  getColumnDetail(id) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getcolumndetail');
    var api = 'column/columns/getColumnDetail';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'column_id': id,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.newBlog = result.data;
      }
    })
  }

  handleOk(): void {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|changecolumn');
    var api = 'column/columns/changeColumn';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'column_id': this.newBlog.id,
      'sessionid': this.userinfo.sessionid,
      'title': this.newBlog.title,
      'diyurl': this.newBlog.diyurl,
      'company': this.newBlog.company,
      'position': this.newBlog.position,
      'introduce': this.newBlog.introduce,
      'columnlogo': this.newBlog.columnlogo,
    }
    // console.log("body", body);
    // return;
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.modalService.success({
          nzTitle: "申请新专栏成功",
          nzContent: "申请专栏成功，等待审核",
          nzOkText: "确定",
          nzOnOk: () => {
            this.isShowAddColumn = false;
          },
        })
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }
  timer: any;
  trimUrlOk = true;
  diyUrlChange(event) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.doTrimdiyurl(event);
    }, 500)
  }
  doTrimdiyurl(url) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|trimdiyurl');
    var api = 'column/columns/trimdiyurl';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'diyurl': url,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.trimUrlOk = true;
      } else {
        this.trimUrlOk = false;
      }
    })
  }
  handleCancel(): void {
    this.isShowAddColumn = false;
  }

  beforeUpload = (file: File) => {
    // console.log('file:', file)
    return new Observable((observer: Observer<boolean>) => {
      const isJPG = file.type === 'image/jpeg';//image/png,image/jpeg,image/gif,image/bmp
      const isPNG = file.type === 'image/png';
      const isBMP = file.type === 'image/bmp';
      var isjpb: boolean = isJPG || isPNG || isBMP;
      if (!isjpb) {
        this.msg.error('请上传JPG、PNG、BMP文件!');
        observer.complete();
        return;
      }
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.msg.error('图片请不要超过1MB!');
        observer.complete();
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        var dataURL = reader.result;
        this.addColumnLogo(dataURL)
      }
      this.checkImageDimension(file).then(dimensionRes => {
        observer.next(false);
        observer.complete();
      });
    });
  };
  loading: any;
  handleChange(info: { file: UploadFile }): void {
    // console.log(info.file.status)
    // console.log('info', info);
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          this.newBlog.columnlogo = img;
          // console.log('base64:', img);
          // this.addColumnLogo();
        });
        break;
      default:
        break;
    }
  }
  /**
   * 上传图片column/columns/addColumnLogo
   */
  addColumnLogo(dataURL) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnlogo');
    var api = 'column/columns/addColumnLogo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'columnlogo': dataURL,
      'type': 2
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.newBlog.columnlogo = 'https://admin.pgfans.cn' + result.data.url;
        // this.submitEdit(5);
      }
    })
  }

  getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  private checkImageDimension(file: File): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src!);
        resolve(width === height && width >= 300);
      };
    });
  }




}
