import { HttpService } from './../services/http.service'; import { Md5 } from "ts-md5";
import { Router, NavigationExtras } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../services/storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.less']
})
export class BlogsComponent implements OnInit {

  userinfo: any = {};   //本地保存的用户登录信息

  isMore = false;   //是否显示更多顶部标签

  article_type_number = 1;   //问答类型：1=最新发布；2=热门文章；3=推荐文章

  columnList: any = [];       //所有专栏列表
  articleList: any = [];      //所有文章列表
  hotColumns: any = [];       //排行榜TOP5
  hotLabelList: any = [];     //热门标签列表
  rankingList: any = [];

  page = 1;                   //列表显示第几页
  page_num = 15;              //每页显示的数量
  total: any = [];            //总共几页
  page_prev = 1;
  page_next = 5;

  loadingText = '载入中';   //列表底部载入控件显示的文字

  advert = true;
  offsetTop = 0;

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,

  ) {
    if (local.get('userinfo') != null && local.get('userinfo').id > 0) {
      this.userinfo = local.get('userinfo');
    }
    this.advert = true;
  }
  ngOnInit() {
    //获取所有专栏列表column/columns/getAllColumns
    this.getAllColumns();
    //获取文章列表column/columns/getNewArticles
    this.getNewArticles();
    //获取排行榜top5
    this.getRankingList();
    //获取热门专栏column/columns/getHotColumns
    this.getHotColumns();
    //获取热门标签
    this.getHotLabelList();

  }

  close(){
    this.advert = false;
  }
  /**
   * 获取所有专栏列表column/columns/getAllColumns
   */
  getAllColumns() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getallcolumns');
    var api = 'column/columns/getAllColumns';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.columnList = result.data;
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
  /**
   * 获取文章列表column/columns/getNewArticles
   */
  getNewArticles() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewarticles');
    var api = 'column/columns/getNewArticles';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'page': this.page,
      'page_num': this.page_num,
      'article_type_number': this.article_type_number,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.articleList = [];
        this.total = [];
        if (result.data != null && result.data.length > 0) {
          this.articleList = result.data;
          // console.log(this.articleList)
          for(let i=0;i<this.articleList.length;i++){
            var index1 = this.articleList[i].introduce.indexOf('提取码');
            var index2 = this.articleList[i].introduce.indexOf('密码');
            if(index1>-1){
              this.articleList[i].introduce = this.articleList[i].introduce.slice(0,index1)
            }
            if(index2>-1){
              this.articleList[i].introduce = this.articleList[i].introduce.slice(0,index2)
            }
            
          }
          this.local.fmDates(this.articleList);
          for (let i = 0; i < result.page_info.total; i++) {
            this.total.push({ num: i + 1, checked: this.page == i + 1 });
          }
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
   /**
   * 获取排行榜top5:user/User/rankingList
   */
  getRankingList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|articleauthorlist');
    var api = 'column/columns/articleAuthorList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
    };
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.rankingList = result.data;
          
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        });
      }
    });
  }
  /**
   * 获取热门专栏column/columns/getHotColumns
   */
  getHotColumns() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|gethotcolumns');
    var api = 'column/columns/getHotColumns';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'count': 5,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.hotColumns = result.data;
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
  /**
   * 获取热门标签:question/question_label/questionHotLabelList
   */
  getHotLabelList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|articlehotlabellist');
    var api = 'question/question_label/articleHotLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'num': 5
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.hotLabelList = result.data;
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }

  /**
   * 加载数据
   * @param e 
   */
  changeTab(e) {
    this.page = 1;
    this.page_prev = 1;
    this.page_next = 5;
    // console.log("e", e[0].index);
    this.article_type_number = e[0].index + 1;
    this.getNewArticles();
  }


  goWrite() {
    console.log(this.userinfo);
    if (!this.userinfo.id) {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'from': 'adetail', },
      }
      this.router.navigate(['/login'], navigationExtras)
      return;
    }
    this.router.navigate(['/blogs-write']);
  }
  /**跳转到页面 */
  getPageData(num) {
    this.page = num;
    this.updatepage();
    // console.log(this.page);
    this.getNewArticles();
  }
  /**到上一页 */
  doPrevious() {
    this.page--;
    this.updatepage();
    // console.log(this.page);
    this.getNewArticles();
  }
  /**到下一页 */
  doNext() {
    this.page++;
    this.updatepage();
    // console.log(this.page);
    this.getNewArticles();
  }
  /**更新页面选择器 */
  updatepage() {
    window.scrollTo(0, 0)
    for (let i = 0; i < this.total.length; i++) {
      if (this.total[i].num == this.page) {
        this.total[i].checked = true;
      } else {
        this.total[i].checked = false;
      }
    }
    if (this.page == 1) {
      this.page_prev = this.page;
      this.page_next = this.page + 4;
    } else if (this.page == 2) {
      this.page_prev = this.page - 1;
      this.page_next = this.page + 3;
    } else if (this.page == this.total.length - 1) {
      this.page_prev = this.page - 3;
      this.page_next = this.page + 1;
    } else if (this.page == this.total.length) {
      this.page_prev = this.page - 4;
      this.page_next = this.page;
    } else {
      this.page_prev = this.page - 2;
      this.page_next = this.page + 2;
    }
  }
   /**向前5页 */
   prev(num) {
    if (num - 5 < 1) {
      this.page = 1;
    } else {
      this.page = num - 5;
    }
    this.updatepage();
    this.getNewArticles();
  }
  /**向后5页 */
  next(num) {
    if (num + 5 > this.total.length) {
      this.page = this.total.length;
    } else {
      this.page = num + 5;
    }
    this.updatepage();
    this.getNewArticles();
  }

  /**点击显示更多标签触发事件 */
  showMore() {
    // this.isMore = !this.isMore;
    this.router.navigate(['/allblogs'])
  }
  /**点击显示所有标签出发时间 */
  showAll() {

  }

  /**
   * 点赞操作question/question_operate/operate
   * @param article 文章json
   * @param key 
   */
  doAgree(article, key) {
    if (!this.checkUser()) {
      return;
    }
    if (article.is_thumb_up) {
      var type = 6;
    } else {
      var type = 5;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'question/question_operate/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'question_id': article.id,
      'operate_type': type,
      'type': 1,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (article.is_thumb_up) {
          this.articleList[key].is_thumb_up = 0;
          this.articleList[key].praise_num--;
        } else {
          this.articleList[key].is_thumb_up = 1;
          this.articleList[key].praise_num++;
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }

  /**
   * 检查是否已经登录
   */
  checkUser() {
    if (!this.userinfo.id) {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'from': 'adetail', },
      }
      this.router.navigate(['/login'], navigationExtras)
      return false;
    }
    return true;
  }

   /**
   * 跳转到用户主页
   * @param user 用户信息，主要是要用到主键id
   */
  goUserHome(user) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'userId': user.user_id, },
    }
    this.router.navigate(['/user/home'], navigationExtras);
  }
}
