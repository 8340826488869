import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bean-rule',
  templateUrl: './bean-rule.component.html',
  styleUrls: ['./bean-rule.component.less']
})
export class BeanRuleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
